import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    interview_types: [],
    interview_typesCount: 0,
    interview_type: {},
    interview_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getInterview_types(state) {
      return state.interview_types
    },
    getInterview_typesCount(state) {
      return state.interview_typesCount
    },
    getInterview_type(state) {
      return state.interview_type
    },
    getInterview_typeSaveStatus(state) {
      return state.interview_typeSave
    },
  },
  mutations: {
    INTERVIEW_TYPES_LIST(state, data) {
      state.interview_types = data
    },
    INTERVIEW_TYPE_VIEW(state, data) {
      state.interview_type = data
    },
    INTERVIEW_TYPES_COUNT(state, data) {
      state.interview_typesCount = data
    },
    INTERVIEW_TYPE_SAVE(state, data) {
      state.interview_typeSave = data
    },
  },
  actions: {
    interview_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/defines/interview_types', data)
        .then(response => {
          commit('INTERVIEW_TYPES_LIST', response.data.data)
          commit('INTERVIEW_TYPES_COUNT', response.data.count)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    interview_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_interview_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/interview_types', data)
        .then(response => {
          commit('INTERVIEW_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    interview_typeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/interview_types/saveData', data)
        .then(response => {
          commit('INTERVIEW_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    interview_typeDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/interview_types/deleteData', data)
        .then(response => {
          commit('INTERVIEW_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
