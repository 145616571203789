import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    product_units: [],
    product_unit: {},
    product_units_count: 0,
    product_unit_save: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getProductUnits(state) {
      return state.product_units
    },
    getProductUnit(state) {
      return state.product_unit
    },
    getProductUnitsCount(state) {
      return state.product_units_count
    },
    getProductUnitSave(state) {
      return state.product_unit_save
    },
  },
  mutations: {
    PRODUCT_UNITS_LIST(state, data) {
      state.product_units = data
    },
    PRODUCT_UNITS_COUNT(state, data) {
      state.product_units_count = data
    },
    PRODUCT_UNIT_VIEW(state, data) {
      state.product_unit = data
    },
    PRODUCT_UNIT_SAVE(state, data) {
      state.product_unit_save = data
    },
  },
  actions: {
    productUnits({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/product_units', data)
        .then(response => {
          commit('PRODUCT_UNITS_LIST', response.data.data)
          commit('PRODUCT_UNITS_COUNT', response.data.count)
        })
    },
    productUnitView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_product_units.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/product_units', data)
        .then(response => {
          commit('PRODUCT_UNIT_VIEW', response.data.data)
        })
    },
    productUnitSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/product_units/saveData', data)
        .then(response => {
          commit('PRODUCT_UNIT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
