import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    suppliers: [],
    supplier: {},
    suppliers_count: 0,
    supplier_save: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getSuppliers(state) {
      return state.suppliers
    },
    getSupplier(state) {
      return state.supplier
    },
    getSuppliersCount(state) {
      return state.suppliers_count
    },
    getSupplierSave(state) {
      return state.supplier_save
    },
  },
  mutations: {
    SUPPLIERS_LIST(state, data) {
      state.suppliers = data
    },
    SUPPLIERS_COUNT(state, data) {
      state.suppliers_count = data
    },
    SUPPLIER_VIEW(state, data) {
      state.supplier = data
    },
    SUPPLIER_SAVE(state, data) {
      state.supplier_save = data
    },
  },
  actions: {
    suppliers({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/suppliers', data)
        .then(response => {
          commit('SUPPLIERS_LIST', response.data.data)
          commit('SUPPLIERS_COUNT', response.data.count)
        })
    },
    supplierView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_suppliers.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/suppliers', data)
        .then(response => {
          commit('SUPPLIER_VIEW', response.data.data)
        })
    },
    supplierSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/suppliers/saveData', data)
        .then(response => {
          commit('SUPPLIER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
