import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: {
      data: [],
    },
    dataRates: {
      sales: {
        rate: 0,
        total: 0,
      },
      services: {
        rate: 0,
        total: 0,
      },
      realtySales: {
        rate: 0,
        total: 0,
      },
    },
    dataCount: 0,
    filterData: {
      sdate: null,
      edate: null,
      or_like: {},
      limit: 25,
      start: 0,
    },
    loadingReport: null,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getDataRates(state) {
      return state.dataRates
    },
    getDataCount(state) {
      return state.dataCount
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_RATES_DATA(state, data) {
      state.dataRates = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/crm/loyalty_program', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_RATES_DATA', response.data.ratesData)
          commit('SET_DATA_COUNT', response.data.count)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
