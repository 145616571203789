import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    colors: [],
    colorsCount: 0,
    color: {},
    colorSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getColors(state) {
      return state.colors
    },
    getColorsCount(state) {
      return state.colorsCount
    },
    getColor(state) {
      return state.color
    },
    getColorSaveStatus(state) {
      return state.colorSave
    },
  },
  mutations: {
    COLORS_LIST(state, data) {
      state.colors = data
    },
    COLOR_VIEW(state, data) {
      state.color = data
    },
    COLORS_COUNT(state, data) {
      state.colorsCount = data
    },
    COLOR_SAVE(state, data) {
      state.colorSave = data
    },
  },
  actions: {
    colorsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/colors', data)
        .then(response => {
          commit('COLORS_LIST', response.data.data)
          commit('COLORS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    colorView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_color.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/colors', data)
        .then(response => {
          commit('COLOR_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    colorSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/colors/saveData', data)
        .then(response => {
          commit('COLOR_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    colorDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/colors/deleteData', data)
        .then(response => {
          commit('COLOR_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
