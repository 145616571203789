import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    kdvRates: [],
    kdvRatesCount: 0,
    kdvRate: {},
    kdvRateSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getKdvRates(state) {
      return state.kdvRates
    },
    getKdvRatesCount(state) {
      return state.kdvRatesCount
    },
    getKdvRate(state) {
      return state.kdvRate
    },
    getKdvRateSaveStatus(state) {
      return state.kdvRateSave
    },
  },
  mutations: {
    KDV_RATES_LIST(state, data) {
      state.kdvRates = data
    },
    KDV_RATE_VIEW(state, data) {
      state.kdvRate = data
    },
    KDV_RATES_COUNT(state, data) {
      state.kdvRatesCount = data
    },
    KDV_RATE_SAVE(state, data) {
      state.kdvRateSave = data
    },
  },
  actions: {
    kdvRatesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/kdv_rates', data)
        .then(response => {
          commit('KDV_RATES_LIST', response.data.data)
          commit('KDV_RATES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    kdvRateView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_kdvrate.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/kdv_rates', data)
        .then(response => {
          commit('KDV_RATE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    kdvRateSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/kdv_rates/saveData', data)
        .then(response => {
          commit('KDV_RATE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    kdvRateDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/kdv_rates/deleteData', data)
        .then(response => {
          commit('KDV_RATE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
