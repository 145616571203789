export default [
  {
    path: '/crmapplysources',
    name: 'CrmApplySources',
    component: () => import('@/views/Defines/Crm_apply_sources/Index.vue'),
    meta: {
      pageTitle: 'Başvuru Kaynakları',
      breadcrumb: [
        {
          text: 'Başvuru Kaynakları',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_APPLY_SOURCES',
      action: 'read',
    },
  },
  {
    path: '/crmapplysources/add',
    name: 'CrmApplySourcesAdd',
    component: () => import('@/views/Defines/Crm_apply_sources/Add.vue'),
    meta: {
      pageTitle: 'Başvuru Kaynağı Ekle',
      breadcrumb: [
        {
          text: 'Başvuru Kaynakları',
          to: '/crmapplysources',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_APPLY_SOURCES',
      action: 'read',
    },
  },
  {
    path: '/crmapplysources/edit/:id',
    name: 'CrmApplySourcesEdit',
    component: () => import('@/views/Defines/Crm_apply_sources/Edit.vue'),
    meta: {
      pageTitle: 'Başvuru Kaynağı Güncelle',
      breadcrumb: [
        {
          text: 'Başvuru Kaynakları',
          to: '/crmapplysources',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_APPLY_SOURCES',
      action: 'read',
    },
  },
]
