export default [
  {
    path: '/reports/csi/brands',
    name: 'ReportsCsiBrands',
    component: () => import('@/views/Reports/Csi/Brands/Index.vue'),
    meta: {
      pageTitle: 'CSI Raporları',
      breadcrumb: [
        {
          text: 'Marka',
          active: true,
        },
      ],
      resource: 'REPORTS_CSI_BRANDS',
      action: 'read',
    },
  },
  {
    path: '/reports/csi/users',
    name: 'ReportsCsiUsers',
    component: () => import('@/views/Reports/Csi/Users/Index.vue'),
    meta: {
      pageTitle: 'CSI Raporları',
      breadcrumb: [
        {
          text: 'Danışman',
          active: true,
        },
      ],
      resource: 'REPORTS_CSI_USERS',
      action: 'read',
    },
  },
]
