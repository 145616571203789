import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: [],
    charts: [],
    counters: [],
    dataCount: 0,
    openCount: 0,
    totalRequest: 0,
    totalAvarage: 0,
    pendingRequest: 0,
    filterData: {
      id_com_brand: [],
      id_com_meet: [],
      id_com_user: [],
      status: [0, 1],
      statuses: [
        {
          id: 1,
          title: 'Kapalı Olanlar',
        },
        {
          id: 0,
          title: 'Açık Olanlar',
        },
      ],
      sdate: null,
      edate: null,
    },
    loadingReport: false,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getReportDataCount(state) {
      return state.dataCount
    },
    getReportOpenCount(state) {
      return state.openCount
    },
    getReportCounters(state) {
      return state.counters
    },
    getReportTotalRequestCount(state) {
      return state.totalRequest
    },
    getReportPendingRequestCount(state) {
      return state.pendingRequest
    },
    getReportTotalAvarage(state) {
      return state.totalAvarage
    },
    getChartData(state) {
      return state.charts
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_REPORT_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SET_REPORT_OPEN_COUNT(state, data) {
      state.openCount = data
    },
    SET_REPORT_COUNTERS(state, data) {
      state.counters = data
    },
    SET_REPORT_TOTAL_REQUEST_COUNT(state, data) {
      state.totalRequest = data
    },
    SET_REPORT_PENDING_REQUEST_COUNT(state, data) {
      state.pendingRequest = data
    },
    SET_REPORT_TOTAL_AVARAGE(state, data) {
      state.totalAvarage = data
    },
    SET_REPORT_CHART_DATA(state, data) {
      state.charts = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
    SET_RESET_FILTER_DATA(state, data) {
      state.filterData = {
        id_com_brand: [],
        id_com_meet: [],
        id_com_user: [],
        status: [0, 1],
        sdate: null,
        edate: null,
      }
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/sales/digital_response_new', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_REPORT_DATA_COUNT', response.data.count)
          commit('SET_REPORT_OPEN_COUNT', response.data.openCount)
          commit('SET_REPORT_TOTAL_REQUEST_COUNT', response.data.totalRequest)
          commit('SET_REPORT_PENDING_REQUEST_COUNT', response.data.pendingRequest)
          commit('SET_REPORT_TOTAL_AVARAGE', response.data.totalAvarage)
          commit('SET_REPORT_CHART_DATA', response.data.charts)
          commit('SET_REPORT_COUNTERS', response.data.counters)

          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
