import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    product_groups: [],
    product_group: {},
    product_groups_count: 0,
    product_group_save: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getProductGroups(state) {
      return state.product_groups
    },
    getProductGroup(state) {
      return state.product_group
    },
    getProductGroupsCount(state) {
      return state.product_groups_count
    },
    getProductGroupSave(state) {
      return state.product_group_save
    },
  },
  mutations: {
    PRODUCT_GROUPS_LIST(state, data) {
      state.product_groups = data
    },
    PRODUCT_GROUPS_COUNT(state, data) {
      state.product_groups_count = data
    },
    PRODUCT_GROUP_VIEW(state, data) {
      state.product_group = data
    },
    PRODUCT_GROUP_SAVE(state, data) {
      state.product_group_save = data
    },
  },
  actions: {
    productGroups({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/product_groups', data)
        .then(response => {
          commit('PRODUCT_GROUPS_LIST', response.data.data)
          commit('PRODUCT_GROUPS_COUNT', response.data.count)
        })
    },
    productGroupView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_product_groups.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/product_groups', data)
        .then(response => {
          commit('PRODUCT_GROUP_VIEW', response.data.data)
        })
    },
    productGroupsSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/product_groups/saveData', data)
        .then(response => {
          commit('PRODUCT_GROUP_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
