import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    product_types: [],
    product_type: {},
    product_types_count: 0,
    product_type_save: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getProductTypes(state) {
      return state.product_types
    },
    getProductType(state) {
      return state.product_type
    },
    getProductTypesCount(state) {
      return state.product_types_count
    },
    getProductTypeSave(state) {
      return state.product_type_save
    },
  },
  mutations: {
    PRODUCT_TYPES_LIST(state, data) {
      state.product_types = data
    },
    PRODUCT_TYPES_COUNT(state, data) {
      state.product_types_count = data
    },
    PRODUCT_TYPE_VIEW(state, data) {
      state.product_type = data
    },
    PRODUCT_TYPE_SAVE(state, data) {
      state.product_type_save = data
    },
  },
  actions: {
    productTypes({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/product_types', data)
        .then(response => {
          commit('PRODUCT_TYPES_LIST', response.data.data)
          commit('PRODUCT_TYPES_COUNT', response.data.count)
        })
    },
    productTypesView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_product_types.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/product_types', data)
        .then(response => {
          commit('PRODUCT_TYPE_VIEW', response.data.data)
        })
    },
    productTypesSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/product_types/saveData', data)
        .then(response => {
          commit('PRODUCT_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
