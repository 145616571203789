import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    realty_sale_types: [],
    realty_sale_typesCount: 0,
    realty_sale_type: {
      id: null,
      title: null,
      status: null,
      id_com_realty_project: null,
    },
    realty_sale_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getRealty_sale_types(state) {
      return state.realty_sale_types
    },
    getRealty_sale_typesCount(state) {
      return state.realty_sale_typesCount
    },
    getRealty_sale_type(state) {
      return state.realty_sale_type
    },
    getRealty_sale_typeSaveStatus(state) {
      return state.realty_sale_typeSave
    },
  },
  mutations: {
    SET_REALTY_SALE_TYPES(state, data) {
      state.realty_sale_types = data
    },
    SET_REALTY_SALE_TYPE(state, data) {
      state.realty_sale_type = data
    },
    SET_REALTY_SALE_TYPES_COUNT(state, data) {
      state.realty_sale_typesCount = data
    },
    SET_REALTY_SALE_TYPE_SAVE(state, data) {
      state.realty_sale_typeSave = data
    },
  },
  actions: {
    realty_sale_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/realty_types', data)
        .then(response => {
          commit('SET_REALTY_SALE_TYPES', response.data.data)
          commit('SET_REALTY_SALE_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    realty_sale_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_realty_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/realty_types', data)
        .then(response => {
          commit('SET_REALTY_SALE_TYPE', response.data.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_typeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/realty_types/saveData', data)
        .then(response => {
          commit('SET_REALTY_SALE_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_typeDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/realty_types/deleteData', data)
        .then(response => {
          commit('SET_REALTY_SALE_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
