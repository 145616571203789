export default [
  {
    path: '/portal/gallery',
    name: 'PortalGallery',
    component: () => import('@/views/Portal/Gallery/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Fotoğraf Galerisi',
          active: true,
        },
      ],
      resource: 'PORTAL_GALLERY_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/gallery/add',
    name: 'PortalGalleryAdd',
    component: () => import('@/views/Portal/Gallery/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Fotoğraf Galerisi',
          to: '/portal/gallery',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_GALLERY_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/gallery/edit/:id',
    name: 'PortalGalleryEdit',
    component: () => import('@/views/Portal/Gallery/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Fotoğraf Galerisi',
          to: '/portal/gallery',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_GALLERY_EDIT',
      action: 'read',
    },
  },
]
