import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tireTypes: [],
    tireTypesCount: 0,
    tireType: {},
    tireTypeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getTireTypes(state) {
      return state.tireTypes
    },
    getTireTypesCount(state) {
      return state.tireTypesCount
    },
    getTireType(state) {
      return state.tireType
    },
    getTireTypeSaveStatus(state) {
      return state.tireTypeSave
    },
  },
  mutations: {
    TIRE_TYPES_LIST(state, data) {
      state.tireTypes = data
    },
    TIRE_TYPE_VIEW(state, data) {
      state.tireType = data
    },
    TIRE_TYPES_COUNT(state, data) {
      state.tireTypesCount = data
    },
    TIRE_TYPE_SAVE(state, data) {
      state.tireTypeSave = data
    },
  },
  actions: {
    tireTypesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/tire_types', data)
        .then(response => {
          commit('TIRE_TYPES_LIST', response.data.data)
          commit('TIRE_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    tireTypeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_tire_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/tire_types', data)
        .then(response => {
          commit('TIRE_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    tireTypeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/tire_types/saveData', data)
        .then(response => {
          commit('TIRE_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    tireTypeDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/tire_types/deleteData', data)
        .then(response => {
          commit('TIRE_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
