export default [
  {
    path: '/marketing/ad_types',
    name: 'MarketingAdTypes',
    component: () => import('@/views/Marketing/Ad_types/Index.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Tipleri',
          active: true,
        },
      ],
      resource: 'MARKETING_ADTYPES_LIST',
      action: 'read',
    },
  },
  {
    path: '/marketing/ad_types/add',
    name: 'MarketingAdTypeAdd',
    component: () => import('@/views/Marketing/Ad_types/Add.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Tipleri',
          to: '/marketing/ad_types',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'MARKETING_ADTYPES_ADD',
      action: 'read',
    },
  },
  {
    path: '/marketing/ad_types/edit/:id',
    name: 'MarketingAdTypeEdit',
    component: () => import('@/views/Marketing/Ad_types/Edit.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Tipleri',
          to: '/marketing/ad_types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MARKETING_ADTYPES_EDIT',
      action: 'read',
    },
  },
]
