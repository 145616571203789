import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    smsResult: {
      status: null,
      error: null,
    },
    referenceData: {},
    referenceUse: null,
  },
  getters: {
    smsResult(state) {
      return state.smsResult
    },
    referenceData(state) {
      return state.referenceData
    },
    referenceUse(state) {
      return state.referenceUse
    },
  },
  mutations: {
    SET_SMS_RESULT(state, data) {
      state.smsResult = data
    },
    SET_REFERENCE_DATA(state, data) {
      state.referenceData = data
    },
    SET_REFERENCE_USE(state, data) {
      state.referenceUse = data
    },
  },
  actions: {
    bcsSMSControl({ commit }, postData) {
      axiosIns
        .post('/data/bcs_sms/smsSendControl', postData)
        .then(response => {
          commit('SET_SMS_RESULT', response.data)
        })
        .catch(error => console.error(error))
    },
    bcsReferenceControl({ commit }, postData) {
      axiosIns
        .post('/data/bcs_sms/referenceControl', postData)
        .then(response => {
          commit('SET_REFERENCE_DATA', response.data.data)
        })
        .catch(error => console.error(error))
    },
    bcsReferenceSend({ commit }, postData) {
      axiosIns
        .post('/data/bcs_sms/send', postData)
        .then(response => {
          commit('SET_SMS_RESULT', response.data)
        })
        .catch(error => console.error(error))
    },
    bcsUseReference({ commit }, postData) {
      return axiosIns
        .post('/data/bcs_sms/bcsUseReference', postData)
        .then(response => {
          commit('SET_REFERENCE_USE', response.data.status)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
