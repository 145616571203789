export default [
  {
    path: '/product_types',
    name: 'product_types',
    component: () => import('@/views/Defines/Product_types/Index.vue'),
    meta: {
      pageTitle: 'Ürün Tipleri',
      breadcrumb: [
        {
          text: 'Ürün Tipleri',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/product_types/add',
    name: 'ProductTypesAdd',
    component: () => import('@/views/Defines/Product_types/Add.vue'),
    meta: {
      pageTitle: 'Ürün Tipleri',
      breadcrumb: [
        {
          text: 'Ürün Tipleri',
          to: '/product_types',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/product_types/edit/:id',
    name: 'ProductTypesEdit',
    component: () => import('@/views/Defines/Product_types/Edit.vue'),
    meta: {
      pageTitle: 'Ürün Tipleri',
      breadcrumb: [
        {
          text: 'Ürün Tipleri',
          to: '/product_types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/product_groups',
    name: 'product_groups',
    component: () => import('@/views/Defines/Product_groups/Index.vue'),
    meta: {
      pageTitle: 'Ürün Grupları',
      breadcrumb: [
        {
          text: 'Ürün Grupları',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/product_groups/add',
    name: 'ProductGroupsAdd',
    component: () => import('@/views/Defines/Product_groups/Add.vue'),
    meta: {
      pageTitle: 'Ürün Grupları',
      breadcrumb: [
        {
          text: 'Ürün Grupları',
          to: '/product_groups',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/product_groups/edit/:id',
    name: 'ProductGroupsEdit',
    component: () => import('@/views/Defines/Product_groups/Edit.vue'),
    meta: {
      pageTitle: 'Ürün Grupları',
      breadcrumb: [
        {
          text: 'Ürün Grupları',
          to: '/product_groups',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/product_units',
    name: 'product_units',
    component: () => import('@/views/Defines/Product_units/Index.vue'),
    meta: {
      pageTitle: 'Miktar Tipleri',
      breadcrumb: [
        {
          text: 'Miktar Tipleri',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/product_units/add',
    name: 'ProductUnitsAdd',
    component: () => import('@/views/Defines/Product_units/Add.vue'),
    meta: {
      pageTitle: 'Miktar Tipleri',
      breadcrumb: [
        {
          text: 'Miktar Tipleri',
          to: '/product_units',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/product_units/edit/:id',
    name: 'ProductUnitsEdit',
    component: () => import('@/views/Defines/Product_units/Edit.vue'),
    meta: {
      pageTitle: 'Miktar Tipleri',
      breadcrumb: [
        {
          text: 'Miktar Tipleri',
          to: '/product_units',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('@/views/Defines/Suppliers/Index.vue'),
    meta: {
      pageTitle: 'Tedarikçiler',
      breadcrumb: [
        {
          text: 'Tedarikçiler',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/suppliers/add',
    name: 'SuppliersAdd',
    component: () => import('@/views/Defines/Suppliers/Add.vue'),
    meta: {
      pageTitle: 'Tedarikçiler',
      breadcrumb: [
        {
          text: 'Tedarikçiler',
          to: '/suppliers',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/suppliers/edit/:id',
    name: 'SuppliersEdit',
    component: () => import('@/views/Defines/Suppliers/Edit.vue'),
    meta: {
      pageTitle: 'Tedarikçiler',
      breadcrumb: [
        {
          text: 'Tedarikçiler',
          to: '/suppliers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/purchasing_statuses',
    name: 'purchasing_statuses',
    component: () => import('@/views/Defines/Purchasing_statuses/Index.vue'),
    meta: {
      pageTitle: 'Satın Alma Durumları',
      breadcrumb: [
        {
          text: 'Satın Alma Durumları',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/purchasing_statuses/add',
    name: 'PurchasingStatusesAdd',
    component: () => import('@/views/Defines/Purchasing_statuses/Add.vue'),
    meta: {
      pageTitle: 'Satın Alma Durumları',
      breadcrumb: [
        {
          text: 'Satın Alma Durumları',
          to: '/purchasing_statuses',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
  {
    path: '/purchasing_statuses/edit/:id',
    name: 'PurchasingStatusesEdit',
    component: () => import('@/views/Defines/Purchasing_statuses/Edit.vue'),
    meta: {
      pageTitle: 'Satın Alma Durumları',
      breadcrumb: [
        {
          text: 'Satın Alma Durumları',
          to: '/purchasing_statuses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PURCHASING_DEFINES',
      action: 'read',
    },
  },
]
