export default [
  {
    path: '/permit-request',
    name: 'PermitRequest',
    component: () => import('@/views/Permit_request/index.vue'),
    meta: {
      pageTitle: 'İzin Talepleri',
      breadcrumb: [
        {
          text: 'İzin Talepleri',
          active: true,
        },
      ],
      resource: 'HR_PERMIT_REQUEST_LIST',
      action: 'read',
    },
  },
  {
    path: '/permit-request/success-list',
    name: 'PermitRequestSuccess',
    component: () => import('@/views/Permit_request/SuccessList.vue'),
    meta: {
      pageTitle: 'Onaylanan İzin Talepleri',
      breadcrumb: [
        {
          text: 'Onaylanan İzin Talepleri',
          active: true,
        },
      ],
      resource: 'HR_PERMIT_SUCCESS_LIST',
      action: 'read',
    },
  },
  {
    path: '/permit-request/edit/:id',
    name: 'PermitRequestEdit',
    component: () => import('@/views/Permit_request/Edit.vue'),
    meta: {
      pageTitle: 'İzin Talebi İşlemleri',
      breadcrumb: [
        {
          text: 'İzin Talepleri',
          to: '/permit-request',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'HR_PERMIT_REQUEST_EDIT',
      action: 'read',
    },
  },
  {
    path: '/permit-request/success/:id',
    name: 'PermitRequestEdit',
    component: () => import('@/views/Permit_request/Edit.vue'),
    meta: {
      pageTitle: 'İzin Talebi İşlemleri',
      breadcrumb: [
        {
          text: 'Onaylanacak İzin Talepleri',
          to: '/permit-request/success-list',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'HR_PERMIT_REQUEST_EDIT',
      action: 'read',
    },
  },
  {
    path: '/permit-request/view/:id',
    name: 'PermitRequestView',
    component: () => import('@/views/Permit_request/View.vue'),
    meta: {
      pageTitle: 'İzin Talebim',
      breadcrumb: [
        {
          text: 'İzin Taleplerim',
          to: '/permit-request/permit-list',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'HR_PERMIT_REQUEST_ADD',
      action: 'read',
    },
  },
  {
    path: '/permit-request/add',
    name: 'PermitRequestAdd',
    component: () => import('@/views/Permit_request/Add.vue'),
    meta: {
      pageTitle: 'İzin Talebi Oluştur',
      breadcrumb: [
        {
          text: 'İzin Taleplerim',
          to: '/permit-request/permit-list',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'HR_PERMIT_REQUEST_ADD',
      action: 'read',
    },
  },
  {
    path: '/permit-request/permit-list',
    name: 'PermitRequestList',
    component: () => import('@/views/Permit_request/List.vue'),
    meta: {
      pageTitle: 'İzin Taleplerim',
      breadcrumb: [
        {
          text: 'İzin Taleplerim',
          active: true,
        },
      ],
      resource: 'HR_PERMIT_IMREQUEST_LIST',
      action: 'read',
    },
  },
]
