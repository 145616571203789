export default [
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/Service/Index.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Servis',
          active: true,
        },
      ],
      resource: 'SERVICES_LIST',
      action: 'read',
    },
  },
  {
    path: '/service/search',
    name: 'ServiceSearch',
    component: () => import('@/views/Service/Search.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Servis',
          to: '/service',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          active: true,
        },
      ],
      resource: 'SERVICES_ADD',
      action: 'read',
    },
  },
  {
    path: '/service/add/:id',
    name: 'ServiceSearch',
    component: () => import('@/views/Service/Add.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Servis',
          to: '/service',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'SERVICES_ADD',
      action: 'read',
    },
  },
  {
    path: '/service/view/:id',
    name: 'ServiceView',
    component: () => import('@/views/Service/View.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Servis',
          to: '/service',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'SERVICES_VIEW',
      action: 'read',
    },
  },
  {
    path: '/service/edit/:id',
    name: 'ServiceEdit',
    component: () => import('@/views/Service/Edit.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Servis',
          to: '/service',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SERVICES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/service/coupon',
    name: 'ServiceCoupon',
    component: () => import('@/views/Service/Coupon/index.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Servis',
          to: '/service',
          active: false,
        },
        {
          text: 'İndirim Kodu Doğrulama',
          active: true,
        },
      ],
      resource: 'SERVICES_LIST',
      action: 'read',
    },
  },
  {
    path: '/service/sms',
    name: 'ServiceSMS',
    component: () => import('@/views/Service/Sms/Index.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Müşteri SMS Onay',
          active: true,
        },
      ],
      resource: 'SERVICES_SMS_LIST',
      action: 'read',
    },
  },
  {
    path: '/service/sms/search',
    name: 'ServiceSMSSearch',
    component: () => import('@/views/Service/Sms/Search.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Müşteri SMS Onay',
          to: '/service/sms',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          active: true,
        },
      ],
      resource: 'SERVICES_SMS_ADD',
      action: 'read',
    },
  },
  {
    path: '/service/sms/add/:id',
    name: 'ServiceSMSAdd',
    component: () => import('@/views/Service/Sms/Add.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Müşteri SMS Onay',
          to: '/service/sms',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SERVICES_SMS_ADD',
      action: 'read',
    },
  },
  {
    path: '/service/sms/view/:id',
    name: 'ServiceSMSEdit',
    component: () => import('@/views/Service/Sms/View.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Müşteri SMS Onay',
          to: '/service/sms',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'SERVICES_SMS_VIEW',
      action: 'read',
    },
  },
]
