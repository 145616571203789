export default [
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('@/views/Defines/Payments/Index.vue'),
    meta: {
      pageTitle: 'Ödeme Tipleri',
      breadcrumb: [
        {
          text: 'Ödeme Tipleri',
          active: true,
        },
      ],
      resource: 'DEFINES_PAYMENT_TYPES',
      action: 'read',
    },
  },
  {
    path: '/payments/add',
    name: 'PaymentsAdd',
    component: () => import('@/views/Defines/Payments/Add.vue'),
    meta: {
      pageTitle: 'Ödeme Tipi Ekle',
      breadcrumb: [
        {
          text: 'Ödeme Tipleri',
          to: '/payments',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_PAYMENT_TYPES',
      action: 'read',
    },
  },
  {
    path: '/payments/edit/:id',
    name: 'PaymentsEdit',
    component: () => import('@/views/Defines/Payments/Edit.vue'),
    meta: {
      pageTitle: 'Ödeme Tipi Güncelle',
      breadcrumb: [
        {
          text: 'Ödeme Tipleri',
          to: '/payments',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_PAYMENT_TYPES',
      action: 'read',
    },
  },
]
