import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    notices: [],
    noticesCount: 0,
    notice: {
      id: null,
      title: null,
      content: null,
      status: null,
      edate: null,
      image: null,
      upload_image: null,
      submitStatus: false,
    },
    noticeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getNotices(state) {
      return state.notices
    },
    getNoticesCount(state) {
      return state.noticesCount
    },
    getNotice(state) {
      return state.notice
    },
    getNoticeSaveStatus(state) {
      return state.noticeSave
    },
  },
  mutations: {
    NOTICES_LIST(state, data) {
      state.notices = data
    },
    NOTICE_VIEW(state, data) {
      state.notice = data
    },
    NOTICES_COUNT(state, data) {
      state.noticesCount = data
    },
    NOTICE_SAVE(state, data) {
      state.noticeSave = data
    },
    NOTICE_RESET(state, data) {
      if (data.status) {
        state.notice = {
          id: null,
          title: null,
          content: null,
          status: null,
          edate: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    noticesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/notices', data)
        .then(response => {
          commit('NOTICES_LIST', response.data.data)
          commit('NOTICES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    noticeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_notices.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/notices', data)
        .then(response => {
          commit('NOTICE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    noticeSave({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('title', postData.title)
      formData.append('content', postData.content)
      formData.append('edate', postData.edate)
      formData.append('old_image', postData.image)
      formData.append('image', postData.upload_image)
      axiosIns
        .post('/portal/notices/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('NOTICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    noticeReset({ commit }) {
      commit('NOTICE_RESET', { status: true })
    },
    noticeDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/notices/deleteData', data)
        .then(response => {
          commit('NOTICE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
