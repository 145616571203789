export default [
  {
    path: '/hr/career-form',
    name: 'HrCareerForm',
    component: () => import('@/views/Hr/CareerForm/Index.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'İş Başvuruları',
          active: true,
        },
      ],
      resource: 'HR_CAREER_FORM_LIST',
      action: 'read',
    },
  },
  {
    path: '/hr/career-form/view/:id',
    name: 'HrCareerFormView',
    component: () => import('@/views/Hr/CareerForm/View.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'İş Başvuruları',
          to: '/hr/career-form',
          active: false,
        },
        {
          text: 'İş Başvuruları',
          active: true,
        },
      ],
      resource: 'HR_CAREER_FORM_LIST',
      action: 'read',
    },
  },
]
