import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    itemData: {
      code: '',
      sms_id: null,
      status: null,
      phone: null,
      text: null,
      id_com_customer: null,
    },
    smsData: [],
    smsCount: 0,
    smsSendResult: {
      status: null,
      error: null,
    },
    smsConfirmResult: {
      status: null,
    },
  },
  getters: {
    itemData(state) {
      return state.itemData
    },
    getServiceSms(state) {
      return state.smsData
    },
    getServiceSmsCount(state) {
      return state.smsCount
    },
    smsSendResult(state) {
      return state.smsSendResult
    },
    smsConfirmResult(state) {
      return state.smsConfirmResult
    },
  },
  mutations: {
    SET_SMS_LIST(state, data) {
      state.smsData = data
    },
    SET_SMS_ITEM(state, data) {
      state.itemData = data
    },
    SET_SMS_LIST_COUNT(state, data) {
      state.smsCount = data
    },
    SET_SMS_SEND_RESULT(state, data) {
      state.smsSendResult = data
    },
    SET_SMS_CONFIRM_RESULT(state, data) {
      state.smsConfirmResult = data
    },
  },
  actions: {
    smsList({ commit }, params) {
      axiosIns
        .post('/data/service_sms', params)
        .then(response => {
          commit('SET_SMS_LIST', response.data.data)
          commit('SET_SMS_LIST_COUNT', response.data.count)
        })
        .catch(error => console.log(error))
    },
    smsItem({ commit }, id) {
      axiosIns
        .post('/data/service_sms', {
          where: {
            'com_service_sms.id': id,
          },
          array: false,
        })
        .then(response => {
          commit('SET_SMS_ITEM', response.data.data)
        })
        .catch(error => console.log(error))
    },
    sendSms({ commit }, params) {
      return axiosIns
        .post('/data/service_sms/sendSms', params)
        .then(response => {
          commit('SET_SMS_SEND_RESULT', response.data)
          return response.data.status
        })
        .catch(error => console.log(error))
    },
    smsConfirm({ commit }, params) {
      return axiosIns
        .post('/data/service_sms/smsConfirm', params)
        .then(response => {
          commit('SET_SMS_CONFIRM_RESULT', response.data)
          return response.data.status
        })
        .catch(error => console.log(error))
    },
  },
}
