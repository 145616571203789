import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    customer_types: [],
    customer_typesCount: 0,
    customer_type: {},
    customer_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCustomer_types(state) {
      return state.customer_types
    },
    getCustomer_typesCount(state) {
      return state.customer_typesCount
    },
    getCustomer_type(state) {
      return state.customer_type
    },
    getCustomer_typeSaveStatus(state) {
      return state.customer_typeSave
    },
  },
  mutations: {
    CUSTOMER_TYPES_LIST(state, data) {
      state.customer_types = data
    },
    CUSTOMER_TYPE_VIEW(state, data) {
      state.customer_type = data
    },
    CUSTOMER_TYPES_COUNT(state, data) {
      state.customer_typesCount = data
    },
    CUSTOMER_TYPE_SAVE(state, data) {
      state.customer_typeSave = data
    },
  },
  actions: {
    customer_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/customer_types', data)
        .then(response => {
          commit('CUSTOMER_TYPES_LIST', response.data.data)
          commit('CUSTOMER_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    customer_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_customer_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/customer_types', data)
        .then(response => {
          commit('CUSTOMER_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    customer_typeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/customer_types/saveData', data)
        .then(response => {
          commit('CUSTOMER_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
