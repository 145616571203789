import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    insuranceCompanies: [],
    insuranceCompaniesCount: 0,
    insuranceCompany: {},
    insuranceCompanySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getInsuranceCompanies(state) {
      return state.insuranceCompanies
    },
    getInsuranceCompaniesCount(state) {
      return state.insuranceCompaniesCount
    },
    getInsuranceCompany(state) {
      return state.insuranceCompany
    },
    getInsuranceCompanySave(state) {
      return state.insuranceCompanySave
    },
  },
  mutations: {
    INSURANCE_COMPANIES_LIST(state, data) {
      state.insuranceCompanies = data
    },
    INSURANCE_COMPANY_VIEW(state, data) {
      state.insuranceCompany = data
    },
    INSURANCE_COMPANIES_COUNT(state, data) {
      state.insuranceCompaniesCount = data
    },
    INSURANCE_COMPANY_SAVE(state, data) {
      state.insuranceCompanySave = data
    },
  },
  actions: {
    insuranceCompaniesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/insurance_companies', data)
        .then(response => {
          commit('INSURANCE_COMPANIES_LIST', response.data.data)
          commit('INSURANCE_COMPANIES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    insuranceCompanyView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_insurance_company.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/insurance_companies', data)
        .then(response => {
          commit('INSURANCE_COMPANY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    insuranceCompanySave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/insurance_companies/saveData', data)
        .then(response => {
          commit('INSURANCE_COMPANY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    insuranceCompanyDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/insurance_companies/deleteData', data)
        .then(response => {
          commit('INSURANCE_COMPANY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
