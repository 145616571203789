import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    sales: {
      summary: [],
      users: [],
      pools: [],
      summaryAll: [],
      interviews: [],
      usersTotal: {},
      summaryTotal: {},
      poolsTotal: {},
      summaryAllTotal: {},
      interviewsTotal: {},
    },
  },
  getters: {
    getSales(state) {
      return state.sales
    },
  },
  mutations: {
    SALES(state, data) {
      state.sales = data
    },
  },
  actions: {
    sales({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/sales/sale_users', data)
        .then(response => {
          commit('SALES', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
