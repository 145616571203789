export default [
  {
    path: '/marketing/ads',
    name: 'MarketingAds',
    component: () => import('@/views/Marketing/Ads/Index.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklamlar',
          active: true,
        },
      ],
      resource: 'MARKETING_ADS_LIST',
      action: 'read',
    },
  },
  {
    path: '/marketing/ads/add',
    name: 'MarketingAdAdd',
    component: () => import('@/views/Marketing/Ads/Add.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklamlar',
          to: '/marketing/ads',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'MARKETING_ADS_ADD',
      action: 'read',
    },
  },
  {
    path: '/marketing/ads/edit/:id',
    name: 'MarketingAdEdit',
    component: () => import('@/views/Marketing/Ads/Edit.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklamlar',
          to: '/marketing/ads',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MARKETING_ADS_EDIT',
      action: 'read',
    },
  },
]
