import moment from 'moment'

export default {
  namespaced: true,
  state: {
    params: {
      sdate: moment().startOf('month').format('YYYY-MM-DD'),
      edate: moment().endOf('month').format('YYYY-MM-DD'),
      id_com_user: null,
      id_com_brand: null,
      id_com_interview_type: null,
      id_com_user_manager: null,
      id_com_location: null,
      id_com_department: null,
    },
  },
  getters: {
    getParams(state) {
      return state.params
    },
  },
  mutations: {
    SET_PARAMS(state, data) {
      state.params = data
    },
  },
}
