import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketing_ads: [],
    marketing_adsCount: 0,
    marketing_ad: {},
    marketing_adSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMarketing_ads(state) {
      return state.marketing_ads
    },
    getMarketing_adsCount(state) {
      return state.marketing_adsCount
    },
    getMarketing_ad(state) {
      return state.marketing_ad
    },
    getMarketing_adSaveStatus(state) {
      return state.marketing_adSave
    },
  },
  mutations: {
    MARKETING_ADS_LIST(state, data) {
      state.marketing_ads = data
    },
    MARKETING_AD_VIEW(state, data) {
      state.marketing_ad = data
    },
    MARKETING_ADS_COUNT(state, data) {
      state.marketing_adsCount = data
    },
    MARKETING_AD_SAVE(state, data) {
      state.marketing_adSave = data
    },
  },
  actions: {
    marketing_adsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/marketing/marketing_ads', data)
        .then(response => {
          commit('MARKETING_ADS_LIST', response.data.data)
          commit('MARKETING_ADS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    marketing_adView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_marketing_ads.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/marketing/marketing_ads', data)
        .then(response => {
          commit('MARKETING_AD_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    marketing_adSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/marketing/marketing_ads/saveData', data)
        .then(response => {
          if (postData.media) {
            if (response.data.status) {
              const mediaData = new FormData()
              mediaData.append('id_com_marketing_ads', response.data.id)
              postData.media.forEach(item => {
                mediaData.append('media[]', item)
              })
              axiosIns
                .post('/marketing/marketing_ad_media/saveData', mediaData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(responseMedia => {
                  commit('MARKETING_AD_SAVE', responseMedia.data)
                })
                .catch(error => console.error(error))
            }
          } else {
            commit('MARKETING_AD_SAVE', response.data)
          }
        })
        .catch(error => console.error(error))
    },
  },
}
