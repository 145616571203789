export default [
  {
    path: '/external/service_counters',
    name: 'ServiceCounters',
    component: () => import('@/views/External/Service_counters/Index.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'Servis Giriş Adetleri',
          active: true,
        },
      ],
      resource: 'EXTERNAL_SERVICE_COUNTERS',
      action: 'read',
    },
  },
  {
    path: '/external/service_counters/add',
    name: 'ServiceCountersAdd',
    component: () => import('@/views/External/Service_counters/Add.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'Servis Giriş Adetleri',
          to: '/external/service_counters',
          active: false,
        },
        {
          text: 'Veri Girişi',
          active: true,
        },
      ],
      resource: 'EXTERNAL_SERVICE_COUNTERS',
      action: 'read',
    },
  },
  {
    path: '/external/service_counters/edit/:id',
    name: 'ServiceCountersEdit',
    component: () => import('@/views/External/Service_counters/Edit.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'Servis Giriş Adetleri',
          to: '/external/service_counters',
          active: false,
        },
        {
          text: 'Veri Güncelle',
          active: true,
        },
      ],
      resource: 'EXTERNAL_SERVICE_COUNTERS',
      action: 'read',
    },
  },
]
