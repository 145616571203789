import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    educations: [],
    educationsCount: 0,
    education: {
      id: null,
      title: null,
      content: null,
      file: null,
      edate: null,
      upload_doc: null,
      upload_images: [],
      submitStatus: false,
    },
    educationSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getEducations(state) {
      return state.educations
    },
    getEducationsCount(state) {
      return state.educationsCount
    },
    getEducation(state) {
      return state.education
    },
    getEducationSaveStatus(state) {
      return state.educationSave
    },
  },
  mutations: {
    EDUCATIONS_LIST(state, data) {
      state.educations = data
    },
    EDUCATION_VIEW(state, data) {
      state.education = data
    },
    EDUCATIONS_COUNT(state, data) {
      state.educationsCount = data
    },
    EDUCATION_SAVE(state, data) {
      state.educationSave = data
    },
    EDUCATION_RESET(state, data) {
      if (data.status) {
        state.education = {
          id: null,
          title: null,
          content: null,
          file: null,
          edate: null,
          upload_doc: null,
          upload_images: [],
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    educationsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/educations', data)
        .then(response => {
          commit('EDUCATIONS_LIST', response.data.data)
          commit('EDUCATIONS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    educationView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_educations.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/educations', data)
        .then(response => {
          commit('EDUCATION_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    educationSave({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('title', postData.title)
      formData.append('edate', postData.edate)
      formData.append('content', postData.content)
      formData.append('old_file', postData.file)
      formData.append('doc', postData.upload_doc)
      if (postData.upload_images) {
        postData.upload_images.forEach(e => {
          formData.append('images[]', e)
        })
      }
      axiosIns
        .post('/portal/educations/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('EDUCATION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    educationReset({ commit }) {
      commit('EDUCATION_RESET', { status: true })
    },
    educationsDeleteImage({ commit }, id) {
      const data = JSON.stringify({ id })
      axiosIns
        .post('/portal/educations/deleteImage', data)
        .then(response => {
          commit('EDUCATION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    educationDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/educations/deleteData', data)
        .then(response => {
          commit('EDUCATION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
