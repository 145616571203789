export default [
  {
    path: '/insurance/interview-batch',
    name: 'InterviewBatch',
    component: () => import('@/views/Insurance/InterviewBatch/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Toplu Görüşme Kapatma',
          active: true,
        },
      ],
      resource: 'INSURANCE_INTERVIEW_BATCH',
      action: 'read',
    },
  },
  {
    path: '/insurance/interview-batch/add/:id',
    name: 'InterviewBatchAdd',
    component: () => import('@/views/Insurance/InterviewBatch/Add.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Toplu Görüşme Kapatma',
          to: '/insurance/interview-batch',
          active: false,
        },
        {
          text: 'Görüşme Notu',
          active: true,
        },
      ],
      resource: 'INSURANCE_INTERVIEW_BATCH',
      action: 'read',
    },
  },
]
