import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    swapbrands: [],
    swapbrandsCount: 0,
    swapbrand: {},
    swapbrandSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getSwapbrands(state) {
      return state.swapbrands
    },
    getSwapbrandsCount(state) {
      return state.swapbrandsCount
    },
    getSwapbrand(state) {
      return state.swapbrand
    },
    getSwapbrandSaveStatus(state) {
      return state.swapbrandSave
    },
  },
  mutations: {
    SWAPBRANDS_LIST(state, data) {
      state.swapbrands = data
    },
    SWAPBRAND_VIEW(state, data) {
      state.swapbrand = data
    },
    SWAPBRANDS_COUNT(state, data) {
      state.swapbrandsCount = data
    },
    SWAPBRAND_SAVE(state, data) {
      state.swapbrandSave = data
    },
  },
  actions: {
    swapbrandsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/swapbrands', data)
        .then(response => {
          commit('SWAPBRANDS_LIST', response.data.data)
          commit('SWAPBRANDS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    swapbrandView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_swap_brand.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/swapbrands', data)
        .then(response => {
          commit('SWAPBRAND_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    swapbrandSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/swapbrands/saveData', data)
        .then(response => {
          commit('SWAPBRAND_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    swapbrandDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/swapbrands/deleteData', data)
        .then(response => {
          commit('SWAPBRAND_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
