import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportages: [],
    reportagesCount: 0,
    reportage: {
      id: null,
      title: null,
      summary: null,
      content: null,
      image: null,
      month: null,
      year: null,
      upload_image: null,
      submitStatus: false,
    },
    reportageSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getReportages(state) {
      return state.reportages
    },
    getReportagesCount(state) {
      return state.reportagesCount
    },
    getReportage(state) {
      return state.reportage
    },
    getReportageSaveStatus(state) {
      return state.reportageSave
    },
  },
  mutations: {
    REPORTAGES_LIST(state, data) {
      state.reportages = data
    },
    REPORTAGE_VIEW(state, data) {
      state.reportage = data
    },
    REPORTAGES_COUNT(state, data) {
      state.reportagesCount = data
    },
    REPORTAGE_SAVE(state, data) {
      state.reportageSave = data
    },
    REPORTAGE_RESET(state, data) {
      if (data.status) {
        state.reportage = {
          id: null,
          title: null,
          summary: null,
          content: null,
          image: null,
          month: null,
          year: null,
          upload_image: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    reportagesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/reportages/getData', data)
        .then(response => {
          commit('REPORTAGES_LIST', response.data.data)
          commit('REPORTAGES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    reportageView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_reportage.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/reportages/getData', data)
        .then(response => {
          commit('REPORTAGE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    reportageSave({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('title', postData.title)
      formData.append('summary', postData.summary)
      formData.append('content', postData.content)
      formData.append('old_image', postData.image)
      formData.append('image', postData.upload_image)
      formData.append('month', postData.month)
      formData.append('year', postData.year)
      axiosIns
        .post('/portal/reportages/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('REPORTAGE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    reportageReset({ commit }) {
      commit('REPORTAGE_RESET', { status: true })
    },
  },
}
