import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    service_counters: [],
    service_countersCount: 0,
    service_counter: {},
    service_counterSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getService_counters(state) {
      return state.service_counters
    },
    getService_countersCount(state) {
      return state.service_countersCount
    },
    getService_counter(state) {
      return state.service_counter
    },
    getService_counterSaveStatus(state) {
      return state.service_counterSave
    },
  },
  mutations: {
    SERVICE_COUNTERS_LIST(state, data) {
      state.service_counters = data
    },
    SERVICE_COUNTER_VIEW(state, data) {
      state.service_counter = data
    },
    SERVICE_COUNTERS_COUNT(state, data) {
      state.service_countersCount = data
    },
    SERVICE_COUNTER_SAVE(state, data) {
      state.service_counterSave = data
    },
  },
  actions: {
    service_countersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/external_data/service_counters', data)
        .then(response => {
          commit('SERVICE_COUNTERS_LIST', response.data.data)
          commit('SERVICE_COUNTERS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    service_counterView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_service_counters.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/external_data/service_counters', data)
        .then(response => {
          commit('SERVICE_COUNTER_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    service_counterSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/external_data/service_counters/saveData', data)
        .then(response => {
          commit('SERVICE_COUNTER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
