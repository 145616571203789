export default [
  {
    path: '/interviews',
    name: 'Interviews',
    component: () => import('@/views/Interviews/Index.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/create',
    name: 'InterviewCreate',
    component: () => import('@/views/Interviews/Search.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_ADD',
      action: 'read',
    },
  },
  {
    path: '/interviews/add/:id',
    name: 'InterviewAdd',
    component: () => import('@/views/Interviews/Add.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          to: '/interviews/create',
          active: false,
        },
        {
          text: 'Yeni Görüşme',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_ADD',
      action: 'read',
    },
  },
  {
    path: '/interviews/view/:interview_number',
    name: 'InterviewView',
    component: () => import('@/views/Interviews/View.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          to: '/interviews/create',
          active: false,
        },
        {
          text: 'Görüşme Detayı',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_VIEW',
      action: 'read',
    },
  },
]
