import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    offers: [],
    offerTerm: [],
    customersList: [],
    exterior_colors: [],
    priceList: [],
    offersCount: 0,
    offer: {
      id: null,
      title: null,
      onumber: null,
      odate: moment().format('YYYY-MM-DD'),
      vdate: null,
      content: null,
      terms: null,
      id_com_customer: null,
      id_com_location: null,
      id_com_user: null,
      lines: [],
      submitStatus: false,
    },
    search: {
      keyword: null,
    },
    offerSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getOffers(state) {
      return state.offers
    },
    getColors(state) {
      return state.exterior_colors
    },
    getTermsContent(state) {
      return state.offerTerm
    },
    getPrices(state) {
      return state.priceList
    },
    getOffersCount(state) {
      return state.offersCount
    },
    getOffer(state) {
      return state.offer
    },
    getOfferSaveStatus(state) {
      return state.offerSave
    },
    getCustomersList(state) {
      return state.customersList
    },
    search(state) {
      return state.search
    },
  },
  mutations: {
    OFFERS_LIST(state, data) {
      state.offers = data
    },
    OFFERS_TERM_CONTENT(state, data) {
      state.offerTerm = data
    },
    COLOR_LIST(state, data) {
      state.exterior_colors = data
    },
    PRICE_LIST(state, data) {
      state.priceList = data
    },
    OFFER_VIEW(state, data) {
      state.offer = data
    },
    OFFERS_COUNT(state, data) {
      state.offersCount = data
    },
    OFFER_SAVE(state, data) {
      state.offerSave = data
    },
    RESET_OFFERS_LIST(state) {
      state.offers = []
    },
    RESET_COLOR_LIST(state) {
      state.exterior_colors = []
    },
    RESET_OFFER_TERMS(state) {
      state.offerTerm = []
    },
    OFFER_RESET(state) {
      state.offer = {
        id: null,
        title: null,
        onumber: null,
        odate: moment().format('YYYY-MM-DD'),
        vdate: null,
        content: null,
        terms: null,
        id_com_customer: null,
        id_com_location: null,
        id_com_user: null,
        lines: [],
        submitStatus: false,
      }
    },
  },
  actions: {
    offersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/offer', data)
        .then(response => {
          commit('OFFERS_LIST', response.data.data)
          commit('OFFERS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    offerView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_offer.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/offer', data)
        .then(response => {
          commit('OFFER_VIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    offerTermContent({ commit }, id) {
      const data = JSON.stringify({
        id_com_customer: id,
      })
      return axiosIns
        .post('/data/offer/getOfferInfo', data)
        .then(response => {
          commit('OFFERS_TERM_CONTENT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getModelInfo({ commit }, id) {
      const data = JSON.stringify({
        id_com_model: id,
      })
      return axiosIns
        .post('/data/offer/getColors', data)
        .then(response => {
          commit('COLOR_LIST', response.data.data)
          commit('PRICE_LIST', response.data.price)
          return response.data
        })
        .catch(error => console.error(error))
    },
    offerSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/offer/saveData', data)
        .then(response => {
          commit('OFFER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    itemsDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      return axiosIns
        .post('/data/offer/deleteItem', data)
        .then(response => {
          commit('OFFER_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    offerReset({ commit }) {
      commit('OFFER_RESET')
    },
  },
}
