export default [
  {
    path: '/service/work-orders/defines/workers',
    name: 'SwoDefinesWorkers',
    component: () => import('@/views/Service_work_orders/Defines/Workers/Index.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'Teknisyenler',
          active: true,
        },
      ],
      resource: 'SERVICE_WORKERS',
      action: 'read',
    },
  },
  {
    path: '/service/work-orders/defines/workers/add',
    name: 'SwoDefinesWorkerAdd',
    component: () => import('@/views/Service_work_orders/Defines/Workers/Add.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'Teknisyenler',
          to: '/service/work-orders/defines/workers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SERVICE_WORKERS',
      action: 'read',
    },
  },
  {
    path: '/service/work-orders/defines/workers/edit/:id',
    name: 'SwoDefinesWorkerEdit',
    component: () => import('@/views/Service_work_orders/Defines/Workers/Edit.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'Teknisyenler',
          to: '/service/work-orders/defines/workers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SERVICE_WORKERS',
      action: 'read',
    },
  },
]
