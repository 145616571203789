import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: [],
    dataCount: 0,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getReportDataCount(state) {
      return state.dataCount
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_REPORT_DATA_COUNT(state, data) {
      state.dataCount = data
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/webforms/digital_response', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_REPORT_DATA_COUNT', response.data.count)
        })
        .catch(error => console.log(error))
    },
  },
}
