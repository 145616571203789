export default [
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Core/Users/Index.vue'),
    meta: {
      pageTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          active: true,
        },
      ],
      resource: 'USERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/users/add',
    name: 'UsersAdd',
    component: () => import('@/views/Core/Users/Add.vue'),
    meta: {
      pageTitle: 'Kullanıcı Ekle',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          to: '/users',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'USERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'UsersEdit',
    component: () => import('@/views/Core/Users/Edit.vue'),
    meta: {
      pageTitle: 'Kullanıcı Güncelle',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          to: '/users',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'USERS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/users/view/:id',
    name: 'UsersView',
    component: () => import('@/views/Core/Users/View.vue'),
    meta: {
      pageTitle: 'Kullanıcı Görüntüle',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          to: '/users',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'USERS_VIEW',
      action: 'read',
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/Notifications/Index.vue'),
    meta: {
      pageTitle: 'Bildirimlerim',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'NOTIFICATIONS',
      action: 'read',
    },
  },
]
