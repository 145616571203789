export default [
  {
    path: '/swap-models',
    name: 'SwapModels',
    component: () => import('@/views/Defines/Swapmodels/Index.vue'),
    meta: {
      pageTitle: 'Araç Modelleri',
      breadcrumb: [
        {
          text: 'Araç Modelleri',
          active: true,
        },
      ],
      resource: 'DEFINES_SWAP_MODELS',
      action: 'read',
    },
  },
  {
    path: '/swap-models/add',
    name: 'SwapModelsAdd',
    component: () => import('@/views/Defines/Swapmodels/Add.vue'),
    meta: {
      pageTitle: 'Araç Modeli Ekle',
      breadcrumb: [
        {
          text: 'Araç Modelleri',
          to: '/swap-models',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_SWAP_MODELS',
      action: 'read',
    },
  },
  {
    path: '/swap-models/edit/:id',
    name: 'SwapModelsEdit',
    component: () => import('@/views/Defines/Swapmodels/Edit.vue'),
    meta: {
      pageTitle: 'Araç Modeli Güncelle',
      breadcrumb: [
        {
          text: 'Araç Modelleri',
          to: '/swap-models',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_SWAP_MODELS',
      action: 'read',
    },
  },
]
