export default [
  {
    path: '/portal/polls',
    name: 'PortalPolls',
    component: () => import('@/views/Portal/Polls/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Tek Soruluk Anket',
          active: true,
        },
      ],
      resource: 'PORTAL_POLLS_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/polls/add',
    name: 'PortalPollAdd',
    component: () => import('@/views/Portal/Polls/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Tek Soruluk Anket',
          to: '/portal/polls',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_POLLS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/polls/edit/:id',
    name: 'PortalPollEdit',
    component: () => import('@/views/Portal/Polls/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Tek Soruluk Anket',
          to: '/portal/polls',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_POLLS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/portal/polls/result/:id',
    name: 'PortalPollResult',
    component: () => import('@/views/Portal/Polls/Result.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Tek Soruluk Anket',
          to: '/portal/polls',
          active: false,
        },
        {
          text: 'Sonuçlar',
          active: true,
        },
      ],
      resource: 'PORTAL_POLLS_EDIT',
      action: 'read',
    },
  },
]
