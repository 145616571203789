import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    locations: [],
    locationsCount: 0,
    location: {},
    locationSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getLocations(state) {
      return state.locations
    },
    getLocationsCount(state) {
      return state.locationsCount
    },
    getLocation(state) {
      return state.location
    },
    getLocationSaveStatus(state) {
      return state.locationSave
    },
  },
  mutations: {
    LOCATIONS_LIST(state, data) {
      state.locations = data
    },
    LOCATION_VIEW(state, data) {
      state.location = data
    },
    LOCATIONS_COUNT(state, data) {
      state.locationsCount = data
    },
    LOCATION_SAVE(state, data) {
      state.locationSave = data
    },
  },
  actions: {
    locationsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/locations', data)
        .then(response => {
          commit('LOCATIONS_LIST', response.data.data)
          commit('LOCATIONS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    locationView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_location.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/locations', data)
        .then(response => {
          commit('LOCATION_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    locationSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/locations/saveData', data)
        .then(response => {
          commit('LOCATION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    locationDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/locations/deleteData', data)
        .then(response => {
          commit('LOCATION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
