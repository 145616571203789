import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    contractedCompanies: [],
    contractedCompaniesCount: 0,
    contractedCompany: {
      id: null,
      title: null,
      content: null,
      status: '1',
      image: null,
      upload_image: null,
      submitStatus: false,
    },
    contractedCompanySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getContractedCompanies(state) {
      return state.contractedCompanies
    },
    getContractedCompaniesCount(state) {
      return state.contractedCompaniesCount
    },
    getContractedCompany(state) {
      return state.contractedCompany
    },
    getContractedCompanySaveStatus(state) {
      return state.contractedCompanySave
    },
  },
  mutations: {
    CONTRACTED_COMPANIES_LIST(state, data) {
      state.contractedCompanies = data
    },
    CONTRACTED_COMPANY_VIEW(state, data) {
      state.contractedCompany = data
    },
    CONTRACTED_COMPANIES_COUNT(state, data) {
      state.contractedCompaniesCount = data
    },
    CONTRACTED_COMPANY_SAVE(state, data) {
      state.contractedCompanySave = data
    },
    CONTRACTED_COMPANY_RESET(state, data) {
      if (data.status) {
        state.contractedCompany = {
          id: null,
          title: null,
          content: null,
          status: '1',
          image: null,
          upload_image: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    contractedCompaniesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/contracted_companies', data)
        .then(response => {
          commit('CONTRACTED_COMPANIES_LIST', response.data.data)
          commit('CONTRACTED_COMPANIES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    contractedCompanyView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_contracted_companies.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/contracted_companies', data)
        .then(response => {
          commit('CONTRACTED_COMPANY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    contractedCompanySave({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('title', postData.title)
      formData.append('content', postData.content)
      formData.append('old_image', postData.image)
      formData.append('image', postData.upload_image)
      formData.append('status', postData.status)
      axiosIns
        .post('/portal/contracted_companies/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('CONTRACTED_COMPANY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    contractedCompanyReset({ commit }) {
      commit('CONTRACTED_COMPANY_RESET', { status: true })
    },
    contractedCompanyDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/contracted_companies/deleteData', data)
        .then(response => {
          commit('CONTRACTED_COMPANY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
