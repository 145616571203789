export default [
  {
    path: '/tirebrands',
    name: 'TireBrands',
    component: () => import('@/views/Defines/Tire_brands/Index.vue'),
    meta: {
      pageTitle: 'Lastik Markaları',
      breadcrumb: [
        {
          text: 'Lastik Markaları',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_BRANDS',
      action: 'read',
    },
  },
  {
    path: '/tirebrands/add',
    name: 'TireBrandsAdd',
    component: () => import('@/views/Defines/Tire_brands/Add.vue'),
    meta: {
      pageTitle: 'Lastik Markası Ekle',
      breadcrumb: [
        {
          text: 'Lastik Markaları',
          to: '/tirebrands',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_BRANDS',
      action: 'read',
    },
  },
  {
    path: '/tirebrands/edit/:id',
    name: 'TireBrandsEdit',
    component: () => import('@/views/Defines/Tire_brands/Edit.vue'),
    meta: {
      pageTitle: 'Lastik Markası Güncelle',
      breadcrumb: [
        {
          text: 'Lastik Markaları',
          to: '/tirebrands',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_BRANDS',
      action: 'read',
    },
  },
]
