export default [
  {
    path: '/hr/employees',
    name: 'HrEmployees',
    component: () => import('@/views/Hr/Employees/Index.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'Personeller',
          active: true,
        },
      ],
      resource: 'HR_EMPLOYEES_LIST',
      action: 'read',
    },
  },
  {
    path: '/hr/employees/add',
    name: 'HrEmployeeAdd',
    component: () => import('@/views/Hr/Employees/Add.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'Personeller',
          to: '/hr/employees',
          active: false,
        },
        {
          text: 'Personel Ekle',
          active: true,
        },
      ],
      resource: 'HR_EMPLOYEES_ADD',
      action: 'read',
    },
  },
  {
    path: '/hr/employees/edit/:id',
    name: 'HrEmployeeEdit',
    component: () => import('@/views/Hr/Employees/Edit.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'Personeller',
          to: '/hr/employees',
          active: false,
        },
        {
          text: 'Personel Güncelle',
          active: true,
        },
      ],
      resource: 'HR_EMPLOYEES_EDIT',
      action: 'read',
    },
  },
]
