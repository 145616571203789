import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    interviewPoll: {
      brand: null,
      subject: null,
      pollcode: null,
      poll: [],
    },
  },
  getters: {
    getInterviewPoll(state) {
      return state.interviewPoll
    },
  },
  mutations: {
    SET_INTERVIEW_POLL(state, data) {
      state.interviewPoll = data
    },
  },
  actions: {
    interviewPoll({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/interview_polls/getPoll', data)
        .then(response => {
          commit('SET_INTERVIEW_POLL', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
