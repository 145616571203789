import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    meetGroups: [],
    meetGroupsCount: 0,
    meetGroup: {},
    meetGroupSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMeetGroups(state) {
      return state.meetGroups
    },
    getMeetGroupsCount(state) {
      return state.meetGroupsCount
    },
    getMeetGroup(state) {
      return state.meetGroup
    },
    getMeetGroupSaveStatus(state) {
      return state.meetGroupSave
    },
  },
  mutations: {
    MEET_GROUPS_LIST(state, data) {
      state.meetGroups = data
    },
    MEET_GROUP_VIEW(state, data) {
      state.meetGroup = data
    },
    MEET_GROUPS_COUNT(state, data) {
      state.meetGroupsCount = data
    },
    MEET_GROUP_SAVE(state, data) {
      state.meetGroupSave = data
    },
  },
  actions: {
    meetGroupsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/meet_groups', data)
        .then(response => {
          commit('MEET_GROUPS_LIST', response.data.data)
          commit('MEET_GROUPS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    meetGroupView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_meet_group.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/meet_groups', data)
        .then(response => {
          commit('MEET_GROUP_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    meetGroupSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/meet_groups/saveData', data)
        .then(response => {
          commit('MEET_GROUP_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    meetGroupDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/meet_groups/deleteData', data)
        .then(response => {
          commit('MEET_GROUP_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
