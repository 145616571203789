import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    feedback_types: [],
    feedback_typesCount: 0,
    feedback_type: {},
    feedback_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getFeedback_types(state) {
      return state.feedback_types
    },
    getFeedback_typesCount(state) {
      return state.feedback_typesCount
    },
    getFeedback_type(state) {
      return state.feedback_type
    },
    getFeedback_typeSaveStatus(state) {
      return state.feedback_typeSave
    },
  },
  mutations: {
    FEEDBACK_TYPES_LIST(state, data) {
      state.feedback_types = data
    },
    FEEDBACK_TYPE_VIEW(state, data) {
      state.feedback_type = data
    },
    FEEDBACK_TYPES_COUNT(state, data) {
      state.feedback_typesCount = data
    },
    FEEDBACK_TYPE_SAVE(state, data) {
      state.feedback_typeSave = data
    },
  },
  actions: {
    feedback_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/feedback_types', data)
        .then(response => {
          commit('FEEDBACK_TYPES_LIST', response.data.data)
          commit('FEEDBACK_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    feedback_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_feedback_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/feedback_types', data)
        .then(response => {
          commit('FEEDBACK_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    feedback_typeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/feedback_types/saveData', data)
        .then(response => {
          commit('FEEDBACK_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    feedback_typeDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/feedback_types/deleteData', data)
        .then(response => {
          commit('FEEDBACK_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
