import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    rates: [],
    ratesCount: 0,
    rate: {},
    rateSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getRates(state) {
      return state.rates
    },
    getRatesCount(state) {
      return state.ratesCount
    },
    getRate(state) {
      return state.rate
    },
    getRateSaveStatus(state) {
      return state.rateSave
    },
  },
  mutations: {
    RATES_LIST(state, data) {
      state.rates = data
    },
    RATE_VIEW(state, data) {
      state.rate = data
    },
    RATES_COUNT(state, data) {
      state.ratesCount = data
    },
    RATE_SAVE(state, data) {
      state.rateSave = data
    },
    RESET_RATE(state) {
      state.rate = {}
    },
  },
  actions: {
    ratesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/interest_rate', data)
        .then(response => {
          commit('RATES_LIST', response.data.data)
          commit('RATES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    rateView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_interest_rate.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/interest_rate', data)
        .then(response => {
          commit('RATE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    rateSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/interest_rate/saveData', data)
        .then(response => {
          commit('RATE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    rateDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/interest_rate/deleteData', data)
        .then(response => {
          commit('RATE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
