import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: {
      data: [],
      totals: {},
    },
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
  },
  mutations: {
    REPORT_DATA(state, data) {
      state.reportData = data
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/sales/digital_report', data)
        .then(response => {
          commit('REPORT_DATA', response.data)
        })
        .catch(error => console.log(error))
    },
  },
}
