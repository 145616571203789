import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tireBrands: [],
    tireBrandsCount: 0,
    tireBrand: {},
    tireBrandSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getTireBrands(state) {
      return state.tireBrands
    },
    getTireBrandsCount(state) {
      return state.tireBrandsCount
    },
    getTireBrand(state) {
      return state.tireBrand
    },
    getTireBrandSaveStatus(state) {
      return state.tireBrandSave
    },
  },
  mutations: {
    TIRE_BRANDS_LIST(state, data) {
      state.tireBrands = data
    },
    TIRE_BRAND_VIEW(state, data) {
      state.tireBrand = data
    },
    TIRE_BRANDS_COUNT(state, data) {
      state.tireBrandsCount = data
    },
    TIRE_BRAND_SAVE(state, data) {
      state.tireBrandSave = data
    },
  },
  actions: {
    tireBrandsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/tire_brands', data)
        .then(response => {
          commit('TIRE_BRANDS_LIST', response.data.data)
          commit('TIRE_BRANDS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    tireBrandView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_tire_brand.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/tire_brands', data)
        .then(response => {
          commit('TIRE_BRAND_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    tireBrandSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/tire_brands/saveData', data)
        .then(response => {
          commit('TIRE_BRAND_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    tireBrandDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/tire_brands/deleteData', data)
        .then(response => {
          commit('TIRE_BRAND_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
