import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tireStatuses: [],
    tireStatusesCount: 0,
    tireStatus: {},
    tireStatusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getTireStatuses(state) {
      return state.tireStatuses
    },
    getTireStatusesCount(state) {
      return state.tireStatusesCount
    },
    getTireStatus(state) {
      return state.tireStatus
    },
    getTireStatusSaveStatus(state) {
      return state.tireStatusSave
    },
  },
  mutations: {
    TIRE_STATUSES_LIST(state, data) {
      state.tireStatuses = data
    },
    TIRE_STATUS_VIEW(state, data) {
      state.tireStatus = data
    },
    TIRE_STATUSES_COUNT(state, data) {
      state.tireStatusesCount = data
    },
    TIRE_STATUS_SAVE(state, data) {
      state.tireStatusSave = data
    },
  },
  actions: {
    tireStatusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/tire_statuses', data)
        .then(response => {
          commit('TIRE_STATUSES_LIST', response.data.data)
          commit('TIRE_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    tireStatusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_tire_status.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/tire_statuses', data)
        .then(response => {
          commit('TIRE_STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    tireStatusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/tire_statuses/saveData', data)
        .then(response => {
          commit('TIRE_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    tireStatusDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/tire_statuses/deleteData', data)
        .then(response => {
          commit('TIRE_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
