export default [
  {
    path: '/webforms/G2bidform',
    name: 'G2bidform',
    component: () => import('@/views/Webforms/G2bidform/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Teklif Ver',
          active: true,
        },
      ],
      resource: 'WEBFORM_G2BIDFORM',
      action: 'read',
    },
  },
  {
    path: '/webforms/G2bidform/view/:id',
    name: 'G2bidformView',
    component: () => import('@/views/Webforms/G2bidform/View.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Teklif Ver',
          to: '/webforms/G2bidform',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'WEBFORM_G2BIDFORM',
      action: 'read',
    },
  },
]
