import axiosIns from '@/libs/axios'

const ROOT_URL = '/rentals/rental_expense_report'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    itemData: {
      title: null,
      report_code: null,
      licence_plate: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    itemData(state) {
      return state.itemData
    },
    dataList(state) {
      return state.dataList
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_ITEM_DATA(state, data) {
      state.itemData = data
    },
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
