export default [
  {
    path: '/reports/marketing/ads',
    name: 'ReportsMarketingAds',
    component: () => import('@/views/Reports/Marketing/AdsReport.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Dijital Reklam Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_MARKETING_DIGITAL',
      action: 'read',
    },
  },
  {
    path: '/reports/marketing/automotive',
    name: 'ReportsMarketingAutomotive',
    component: () => import('@/views/Reports/Marketing/Automotive.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Otomotiv Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_MARKETING_AUTOMOTIVE',
      action: 'read',
    },
  },
  {
    path: '/reports/marketing/meet-report',
    name: 'ReportsMarketingMeet',
    component: () => import('@/views/Reports/Marketing/MeetReport.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Satışlar Temas Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_MARKETING_MEET',
      action: 'read',
    },
  },
]
