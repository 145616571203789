export default [
  {
    path: '/user_types',
    name: 'UserTypes',
    component: () => import('@/views/Core/User_types/Index.vue'),
    meta: {
      pageTitle: 'Kullanıcı Tipleri',
      breadcrumb: [
        {
          text: 'Kullanıcı Tipleri',
          active: true,
        },
      ],
      resource: 'USER_TYPES_LIST',
      action: 'read',
    },
  },
  {
    path: '/user_types/add',
    name: 'UserTypesAdd',
    component: () => import('@/views/Core/User_types/Add.vue'),
    meta: {
      pageTitle: 'Kullanıcı Tipi Ekle',
      breadcrumb: [
        {
          text: 'Kullanıcı Tipleri',
          to: '/user_types',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'USER_TYPES_ADD',
      action: 'read',
    },
  },
  {
    path: '/user_types/edit/:id',
    name: 'UserTypesEdit',
    component: () => import('@/views/Core/User_types/Edit.vue'),
    meta: {
      pageTitle: 'Kullanıcı Tipi Güncelle',
      breadcrumb: [
        {
          text: 'Kullanıcı Tipleri',
          to: '/user_types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'USER_TYPES_EDIT',
      action: 'read',
    },
  },
]
