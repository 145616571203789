export default [
  {
    path: '/webforms/callform',
    name: 'Callform',
    component: () => import('@/views/Webforms/Callform/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'Biz Sizi Arayalım',
          active: true,
        },
      ],
      resource: 'WEBFORM_CALLFORM',
      action: 'read',
    },
  },
  {
    path: '/webforms/callform/view/:id',
    name: 'CallformView',
    component: () => import('@/views/Webforms/Callform/View.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'Biz Sizi Arayalım',
          to: '/webforms/callform',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'WEBFORM_CALLFORM',
      action: 'read',
    },
  },
]
