export default [
  {
    path: '/crmsubjects',
    name: 'CrmSubjects',
    component: () => import('@/views/Defines/Crm_subjects/Index.vue'),
    meta: {
      pageTitle: 'Başvuru Konuları',
      breadcrumb: [
        {
          text: 'Başvuru Konuları',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_SUBJECTS',
      action: 'read',
    },
  },
  {
    path: '/crmsubjects/add',
    name: 'CrmSubjectsAdd',
    component: () => import('@/views/Defines/Crm_subjects/Add.vue'),
    meta: {
      pageTitle: 'Başvuru Konusu Ekle',
      breadcrumb: [
        {
          text: 'Başvuru Konuları',
          to: '/crmsubjects',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_SUBJECTS',
      action: 'read',
    },
  },
  {
    path: '/crmsubjects/edit/:id',
    name: 'CrmSubjectsEdit',
    component: () => import('@/views/Defines/Crm_subjects/Edit.vue'),
    meta: {
      pageTitle: 'Başvuru Konusu Güncelle',
      breadcrumb: [
        {
          text: 'Başvuru Konuları',
          to: '/crmsubjects',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_SUBJECTS',
      action: 'read',
    },
  },
]
