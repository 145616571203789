import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    eduCalendars: [],
    eduCalendarsCount: 0,
    eduCalendar: {
      id: null,
      title: null,
      content: null,
      edate: null,
      submitStatus: false,
    },
    eduCalendarSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getEduCalendars(state) {
      return state.eduCalendars
    },
    getEduCalendarsCount(state) {
      return state.eduCalendarsCount
    },
    getEduCalendar(state) {
      return state.eduCalendar
    },
    getEduCalendarSaveStatus(state) {
      return state.eduCalendarSave
    },
  },
  mutations: {
    EDU_CALENDARS_LIST(state, data) {
      state.eduCalendars = data
    },
    EDU_CALENDAR_VIEW(state, data) {
      state.eduCalendar = data
    },
    EDU_CALENDARS_COUNT(state, data) {
      state.eduCalendarsCount = data
    },
    EDU_CALENDAR_SAVE(state, data) {
      state.eduCalendarSave = data
    },
    EDU_CALENDAR_RESET(state, data) {
      if (data.status) {
        state.eduCalendar = {
          id: null,
          title: null,
          content: null,
          edate: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    eduCalendarsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/edu_calendar', data)
        .then(response => {
          commit('EDU_CALENDARS_LIST', response.data.data)
          commit('EDU_CALENDARS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    eduCalendarView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_edu_calendar.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/edu_calendar', data)
        .then(response => {
          commit('EDU_CALENDAR_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    eduCalendarSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/portal/edu_calendar/saveData', data)
        .then(response => {
          commit('EDU_CALENDAR_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    eduCalendarReset({ commit }) {
      commit('EDU_CALENDAR_RESET', { status: true })
    },
    eduCalendarDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/edu_calendar/deleteData', data)
        .then(response => {
          commit('EDU_CALENDAR_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
