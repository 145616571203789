import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    careers: [],
    careersCount: 0,
    career: {
      id: null,
      title: null,
      content: null,
      status: null,
      submitStatus: false,
    },
    careerSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCareers(state) {
      return state.careers
    },
    getCareersCount(state) {
      return state.careersCount
    },
    getCareer(state) {
      return state.career
    },
    getCareerSaveStatus(state) {
      return state.careerSave
    },
  },
  mutations: {
    CAREERS_LIST(state, data) {
      state.careers = data
    },
    CAREER_VIEW(state, data) {
      state.career = data
    },
    CAREERS_COUNT(state, data) {
      state.careersCount = data
    },
    CAREER_SAVE(state, data) {
      state.careerSave = data
    },
    CAREER_RESET(state, data) {
      if (data.status) {
        state.career = {
          id: null,
          title: null,
          content: null,
          status: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    careersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/career', data)
        .then(response => {
          commit('CAREERS_LIST', response.data.data)
          commit('CAREERS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    careerView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_career.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/career', data)
        .then(response => {
          commit('CAREER_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    careerSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/portal/career/saveData', data)
        .then(response => {
          commit('CAREER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    careerReset({ commit }) {
      commit('CAREER_RESET', { status: true })
    },
    careerDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/career/deleteData', data)
        .then(response => {
          commit('CAREER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
