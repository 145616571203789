import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    summary: {
      requests: [],
      subjects: [],
      requestTypes: [],
      requestTypesChart: [],
      donatChart: {},
      answerDurations: {},
      solutionDurations: {},
      usersSolutionDurations: [],
      requestTotal: 0,
    },
    detailDurations: {},
    exportPdfFile: {
      status: null,
      folder: null,
    },
  },
  getters: {
    getSummary(state) {
      return state.summary
    },
    getDetailDurations(state) {
      return state.detailDurations
    },
    getSummaryChart(state) {
      return state.summary.donatChart
    },
    getExportPdfFile(state) {
      return state.exportPdfFile
    },
  },
  mutations: {
    SUMMARY(state, data) {
      state.summary = data
    },
    DETAIL_DURATIONS(state, data) {
      state.detailDurations = data
    },
    TOGGLE_DETAIL_DURATION_USERS(state, params) {
      const data = state.detailDurations
      const { usersDetail } = data[params.location].brands[params.brand].departments[params.department]
      data[params.location].brands[params.brand].departments[params.department].usersDetail = !usersDetail
      state.detailDurations = data
    },
    EXPORT_PDF(state, data) {
      state.exportPdfFile = data
    },
  },
  actions: {
    summary({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/crm/requests', data)
        .then(response => {
          commit('SUMMARY', response.data)
        })
        .catch(error => console.error(error))
    },
    detailDurations({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/crm/requests/detailDurations', data)
        .then(response => {
          commit('DETAIL_DURATIONS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    toggleDetailDurationUsers({ commit }, params) {
      commit('TOGGLE_DETAIL_DURATION_USERS', params)
    },
    createPdf({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/crm/requests/createPdf', data)
        .then(response => {
          commit('EXPORT_PDF', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
