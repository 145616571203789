export default [
  {
    path: '/marketing/batch-interviews',
    name: 'MarketingBatchInterviews',
    component: () => import('@/views/Marketing/BatchInterviews/Index.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Data Aktarımı',
          active: true,
        },
      ],
      resource: 'MARKETING_BATCH_INTERVIEWS',
      action: 'read',
    },
  },
  {
    path: '/marketing/batch-interviews/import',
    name: 'MarketingBatchInterviews',
    component: () => import('@/views/Marketing/BatchInterviews/Import.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Data Aktarımı',
          to: '/marketing/batch-interviews',
        },
        {
          text: 'Yeni Aktarım',
          active: true,
        },
      ],
      resource: 'MARKETING_BATCH_INTERVIEWS',
      action: 'read',
    },
  },
]
