export default [
  {
    path: '/tireshelfs',
    name: 'TireShelfs',
    component: () => import('@/views/Defines/Tire_shelfs/Index.vue'),
    meta: {
      pageTitle: 'Raflar',
      breadcrumb: [
        {
          text: 'Raflar',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_SHELFS',
      action: 'read',
    },
  },
  {
    path: '/tireshelfs/add',
    name: 'TireShelfsAdd',
    component: () => import('@/views/Defines/Tire_shelfs/Add.vue'),
    meta: {
      pageTitle: 'Raf Ekle',
      breadcrumb: [
        {
          text: 'Raflar',
          to: '/tireshelfs',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_SHELFS',
      action: 'read',
    },
  },
  {
    path: '/tireshelfs/edit/:id',
    name: 'TireShelfsEdit',
    component: () => import('@/views/Defines/Tire_shelfs/Edit.vue'),
    meta: {
      pageTitle: 'Raf Güncelle',
      breadcrumb: [
        {
          text: 'Raflar',
          to: '/tireshelfs',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_SHELFS',
      action: 'read',
    },
  },
]
