export default [
  {
    path: '/insurancecompanies',
    name: 'InsuranceCompanies',
    component: () => import('@/views/Defines/Insurance_companies/Index.vue'),
    meta: {
      pageTitle: 'Sigorta Firmaları',
      breadcrumb: [
        {
          text: 'Sigorta Firmaları',
          active: true,
        },
      ],
      resource: 'DEFINES_INSURANCE_COMPANIES',
      action: 'read',
    },
  },
  {
    path: '/insurancecompanies/add',
    name: 'InsuranceCompaniesAdd',
    component: () => import('@/views/Defines/Insurance_companies/Add.vue'),
    meta: {
      pageTitle: 'Sigorta Firması Ekle',
      breadcrumb: [
        {
          text: 'Sigorta Firmaları',
          to: '/insurancecompanies',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_INSURANCE_COMPANIES',
      action: 'read',
    },
  },
  {
    path: '/insurancecompanies/edit/:id',
    name: 'InsuranceCompaniesEdit',
    component: () => import('@/views/Defines/Insurance_companies/Edit.vue'),
    meta: {
      pageTitle: 'Sigorta Firması Güncelle',
      breadcrumb: [
        {
          text: 'Sigorta Firmaları',
          to: '/insurancecompanies',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_INSURANCE_COMPANIES',
      action: 'read',
    },
  },
]
