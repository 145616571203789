import moment from 'moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    userList: [],
    dataCounts: 0,
    dataItem: {},
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataFilter: {
      id_com_user: null,
      id_com_insurance_renewal_periods: null,
      search_keyword: null,
      completeStatus: false,
    },
    interviewLine: {
      newCustomer: false,
      interview_number: null,
      company_name: null,
      tckn: null,
      name: null,
      phone: null,
      idate: moment().format('YYYY-MM-DD'),
      itime: moment().format('HH:MM'),
      content: null,
      id_com_meet: null,
      id_com_interview_subject: null,
      id_com_interview_status: null,
      id_com_customer: null,
      id_com_insurance_renewal: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    userList(state) {
      return state.userList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataFilter(state) {
      return state.dataFilter
    },
    interviewLine(state) {
      return state.interviewLine
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_USER_LIST(state, data) {
      state.userList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_INTERVIEW_LINE(state, id) {
      state.interviewLine = {
        newCustomer: false,
        company_name: null,
        tckn: null,
        name: null,
        phone: null,
        idate: moment().format('YYYY-MM-DD'),
        itime: moment().format('HH:MM'),
        content: null,
        id_com_meet: null,
        id_com_interview_subject: null,
        id_com_interview_status: null,
        id_com_customer: null,
        id_com_insurance_renewal: id,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/Insurance/renewal', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getUserList({ commit }, id) {
      axiosIns
        .post('/Insurance/renewal/userList', { id_com_insurance_renewal_periods: id })
        .then(response => {
          commit('SET_USER_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_insurance_renewal.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/Insurance/renewal', data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return true
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/Insurance/renewal/saveData', data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
    saveInterview({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/Insurance/renewal/saveInterview', data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
    renewalImport({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/Insurance/renewal/import', data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
  },
}
