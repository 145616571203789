export default [
  {
    path: '/rental/kasko',
    name: 'RentalKasko',
    component: () => import('@/views/Rental/KaskoControl/Index.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Kasko Kontrol Listesi',
          active: true,
        },
      ],
      resource: 'RENTAL_KASKO',
      action: 'read',
    },
  },
  {
    path: '/rental/noter',
    name: 'RentalNoter',
    component: () => import('@/views/Rental/Noter/Index.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Noter Satış Ekleme Listesi',
          active: true,
        },
      ],
      resource: 'RENTAL_NOTER',
      action: 'read',
    },
  },
  {
    path: '/rental/damga-vergisi',
    name: 'RentalDamgaVergisi',
    component: () => import('@/views/Rental/DamgaVergisi/Index.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Damga Vergisi Listesi',
          active: true,
        },
      ],
      resource: 'RENTAL_DAMGA_VERGISI',
      action: 'read',
    },
  },
  {
    path: '/rental/sigorta/yeni-policeler',
    name: 'RentalSigortaYeni',
    component: () => import('@/views/Rental/InsuranceWatchList/YeniPolice.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sigorta',
          active: true,
        },
        {
          text: 'Yeni Poliçeler',
          active: true,
        },
      ],
      resource: 'RENTAL_SIGORTA',
      action: 'read',
    },
  },
  {
    path: '/rental/sigorta/yenileme-policeler',
    name: 'RentalSigortaYenileme',
    component: () => import('@/views/Rental/InsuranceWatchList/YenilemePolice.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sigorta',
          active: true,
        },
        {
          text: 'Yenileme Poliçeler',
          active: true,
        },
      ],
      resource: 'RENTAL_SIGORTA',
      action: 'read',
    },
  },
  {
    path: '/rental/sigorta/iptal-policeler',
    name: 'RentalSigortaIptal',
    component: () => import('@/views/Rental/InsuranceWatchList/IptalPolice.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sigorta',
          active: true,
        },
        {
          text: 'İptal Poliçeler',
          active: true,
        },
      ],
      resource: 'RENTAL_SIGORTA',
      action: 'read',
    },
  },
]
