import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    fixed_educations: [],
    fixed_educationsCount: 0,
    fixed_education: {
      id: null,
      title: null,
      content: null,
      file: null,
      edatid_com_portal_fixed_education_typese: null,
      upload_doc: null,
      submitStatus: false,
    },
    fixed_educationSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getFixedEducations(state) {
      return state.fixed_educations
    },
    getFixedEducationsCount(state) {
      return state.fixed_educationsCount
    },
    getFixedEducation(state) {
      return state.fixed_education
    },
    getFixedEducationSaveStatus(state) {
      return state.fixed_educationSave
    },
  },
  mutations: {
    FIXED_EDUCATIONS_LIST(state, data) {
      state.fixed_educations = data
    },
    FIXED_EDUCATION_VIEW(state, data) {
      state.fixed_education = data
    },
    FIXED_EDUCATIONS_COUNT(state, data) {
      state.fixed_educationsCount = data
    },
    FIXED_EDUCATION_SAVE(state, data) {
      state.fixed_educationSave = data
    },
    FIXED_EDUCATION_RESET(state, data) {
      if (data.status) {
        state.fixed_education = {
          id: null,
          title: null,
          content: null,
          file: null,
          id_com_portal_fixed_education_types: null,
          upload_doc: null,
          upload_images: [],
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    fixed_educationsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/fixed_educations', data)
        .then(response => {
          commit('FIXED_EDUCATIONS_LIST', response.data.data)
          commit('FIXED_EDUCATIONS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    fixed_educationView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_fixed_educations.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/fixed_educations', data)
        .then(response => {
          commit('FIXED_EDUCATION_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    fixed_educationSave({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('title', postData.title)
      formData.append('id_com_portal_fixed_education_types', postData.id_com_portal_fixed_education_types)
      formData.append('old_file', postData.file)
      formData.append('doc', postData.upload_doc)
      axiosIns
        .post('/portal/fixed_educations/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('FIXED_EDUCATION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    fixed_educationReset({ commit }) {
      commit('FIXED_EDUCATION_RESET', { status: true })
    },
    fixed_educationDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/fixed_educations/deleteData', data)
        .then(response => {
          commit('FIXED_EDUCATION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
