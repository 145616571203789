// import axios from '@/libs/axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    districts: [],
    districtsCount: 0,
    district: {},
    districtSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDistricts(state) {
      return state.districts
    },
    getDistrictsCount(state) {
      return state.districtsCount
    },
    getDistrict(state) {
      return state.district
    },
    getDistrictSaveStatus(state) {
      return state.districtSave
    },
  },
  mutations: {
    DISTRICTS_LIST(state, data) {
      state.districts = data
    },
    DISTRICT_VIEW(state, data) {
      state.district = data
    },
    DISTRICTS_COUNT(state, data) {
      state.districtsCount = data
    },
    DISTRICT_SAVE(state, data) {
      state.districtSave = data
    },
  },
  actions: {
    districtsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/districts', data)
        .then(response => {
          commit('DISTRICTS_LIST', response.data.data)
          commit('DISTRICTS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    districtView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_district.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/districts', data)
        .then(response => {
          commit('DISTRICT_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    districtSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/districts/saveData', data)
        .then(response => {
          commit('DISTRICT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    districtDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/districts/deleteData', data)
        .then(response => {
          commit('DISTRICT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
