export default [
  {
    path: '/reports/rental/interviews',
    name: 'ReportsRentalInterivews',
    component: () => import('@/views/Reports/Rental/InterviewsReport.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Görüşmeler',
          active: true,
        },
      ],
      resource: 'REPORTS_RENTAL_INTERVIEWS',
      action: 'read',
    },
  },
  {
    path: '/reports/rental/renewals',
    name: 'ReportsRentalRenewals',
    component: () => import('@/views/Reports/Rental/RenewalsReport.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sözleşme Yenileme',
          active: true,
        },
      ],
      resource: 'REPORTS_RENTAL_RENEWALS',
      action: 'read',
    },
  },
  {
    path: '/reports/rental/expense',
    name: 'ReportsRentalExpense',
    component: () => import('@/views/Reports/Rental/ExpenseReport.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Masraf Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_RENTAL_EXPENSE',
      action: 'read',
    },
  },
  {
    path: '/reports/rental/document_report',
    name: 'ReportsRentalDocuments',
    component: () => import('@/views/Reports/Rental/DocumentReport.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Doküman Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_RENTAL_DOCUMENTS',
      action: 'read',
    },
  },
]
