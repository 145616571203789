import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    saleMeetReport: {
      data: [],
    },
  },
  getters: {
    getMeetReport(state) {
      return state.saleMeetReport
    },
  },
  mutations: {
    MEET_REPORT_REPORT(state, data) {
      state.saleMeetReport = data
    },
  },
  actions: {
    meetReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/marketing/meet_report', data)
        .then(response => {
          commit('MEET_REPORT_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
