import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    g2Survey: {
      totals: {},
      statuses: [],
      users: [],
      detail: [],
    },
  },
  getters: {
    getG2SurveyReport(state) {
      return state.g2Survey
    },
  },
  mutations: {
    G2_SURVEY_REPORT(state, data) {
      state.g2Survey = data
    },
  },
  actions: {
    g2SurveyReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/crm/surveys/gonye2', data)
        .then(response => {
          commit('G2_SURVEY_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
