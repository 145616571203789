import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    automotiveReport: {
      data: [],
    },
  },
  getters: {
    getAutomotiveReport(state) {
      return state.automotiveReport
    },
  },
  mutations: {
    AUTOMOTIVE_REPORT_REPORT(state, data) {
      state.automotiveReport = data
    },
  },
  actions: {
    automotiveReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/marketing/automotive', data)
        .then(response => {
          commit('AUTOMOTIVE_REPORT_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
