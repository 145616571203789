import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    couponData: {
      id: null,
      code: null,
      coupon_type: null,
      customer: null,
      phone: null,
      username: null,
      username_used: null,
      id_com_coupon_type: null,
      id_com_user: null,
      id_com_user_used: null,
      id_com_customer: null,
      id_com_service_tracing: null,
      edate: null,
    },
  },
  getters: {
    getCoupon(state) {
      return state.couponData
    },
  },
  mutations: {
    SET_COUPON_DATA(state, data) {
      state.couponData = data
    },
    USE_COUPON_DATA(state, data) {
      state.couponData = data
    },
  },
  actions: {
    coupon({ commit }, params) {
      axiosIns
        .post('/data/coupons/checkCoupon', params)
        .then(response => {
          commit('SET_COUPON_DATA', response.data.data)
        })
        .catch(error => console.log(error))
    },
    useCoupon({ commit }, params) {
      axiosIns
        .post('/data/coupons/useCoupon', params)
        .then(response => {
          commit('USE_COUPON_DATA', response.data.data)
        })
        .catch(error => console.log(error))
    },
  },
}
