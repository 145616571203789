export default [
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import('@/views/Alerts/Index.vue'),
    meta: {
      pageTitle: 'Hatırlatmalar',
      breadcrumb: [
        {
          text: 'Hatırlatmalar',
          active: true,
        },
      ],
      resource: 'ALERTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/alerts/search',
    name: 'AlertSearch',
    component: () => import('@/views/Alerts/Search.vue'),
    meta: {
      pageTitle: 'Hatırlatma Oluştur',
      breadcrumb: [
        {
          text: 'Hatırlatmalar',
          to: '/alerts',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          active: true,
        },
      ],
      resource: 'ALERTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/alerts/add/:id',
    name: 'AlertAdd',
    component: () => import('@/views/Alerts/Add.vue'),
    meta: {
      pageTitle: 'Hatırlatma Oluştur',
      breadcrumb: [
        {
          text: 'Hatırlatmalar',
          to: '/alerts',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'ALERTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/alerts/edit/:id',
    name: 'AlertEdit',
    component: () => import('@/views/Alerts/Edit.vue'),
    meta: {
      pageTitle: 'Hatırlatma Güncelle',
      breadcrumb: [
        {
          text: 'Hatırlatmalar',
          to: '/alerts',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ALERTS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/alerts/view/:id',
    name: 'AlertView',
    component: () => import('@/views/Alerts/View.vue'),
    meta: {
      pageTitle: 'Hatırlatma Görüntüle',
      breadcrumb: [
        {
          text: 'Hatırlatmalar',
          to: '/alerts',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ALERTS_VIEW',
      action: 'read',
    },
  },
]
