export default [
  {
    path: '/webforms/testdrive',
    name: 'Testdrive',
    component: () => import('@/views/Webforms/Testdrive/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'Test Sürüşü',
          active: true,
        },
      ],
      resource: 'WEBFORM_TESTDRIVE',
      action: 'read',
    },
  },
  {
    path: '/webforms/testdrive/view/:id',
    name: 'TestdriveView',
    component: () => import('@/views/Webforms/Testdrive/View.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'Test Sürüşü',
          to: '/webforms/testdrive',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'WEBFORM_TESTDRIVE',
      action: 'read',
    },
  },
]
