import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    saleGoals: [],
    saleGoalsCount: null,
    saleGoal: {
      id: null,
      goal: null,
      showroom: null,
      phone: null,
      web: null,
      web_distributor: null,
      tahsis_baglanti: null,
      month: null,
      year: null,
      id_com_brand: null,
      id_com_user: null,
      stock: null,
      daily_sale: null,
      total_sale: null,
      connection: null,
      adddate: null,
      addtime: null,
      submitStatus: false,
    },
    saleGoalSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getSaleGoals(state) {
      return state.saleGoals
    },
    getSaleGoalsCount(state) {
      return state.saleGoalsCount
    },
    getSaleGoal(state) {
      return state.saleGoal
    },
    getSaleGoalSaveStatus(state) {
      return state.saleGoalSave
    },
  },
  mutations: {
    SALE_GOALS_LIST(state, data) {
      state.saleGoals = data
    },
    SALE_GOAL(state, data) {
      state.saleGoal = data
    },
    SALE_GOALS_COUNT(state, data) {
      state.saleGoalsCount = data
    },
    SALE_GOAL_SAVE(state, data) {
      state.saleGoalSave = data
    },
    SALE_GOAL_RESET(state, data) {
      if (data.status) {
        state.saleGoal = {
          id: null,
          goal: null,
          showroom: null,
          phone: null,
          web: null,
          web_distributor: null,
          tahsis_baglanti: null,
          month: null,
          year: null,
          id_com_brand: null,
          id_com_user: null,
          stock: null,
          daily_sale: null,
          total_sale: null,
          connection: null,
          adddate: null,
          addtime: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    saleGoalList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/sale_goals', data)
        .then(response => {
          commit('SALE_GOALS_LIST', response.data.data)
          commit('SALE_GOALS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    saleGoalView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_sales_goals.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/sale_goals', data)
        .then(response => {
          commit('SALE_GOAL', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saleGoalSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/sale_goals/saveData', data)
        .then(response => {
          commit('SALE_GOAL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
