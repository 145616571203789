import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    renewals: {
      data: [],
      chartData: {
        labels: [],
        series: [],
      },
      totals: {
        total: 0,
      },
    },
    years: [],
  },
  getters: {
    getRenewalsReport(state) {
      return state.renewals
    },
  },
  mutations: {
    RENEWALS_REPORT(state, data) {
      state.renewals = data
    },
  },
  actions: {
    renewalsReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/rental/renewals', data)
        .then(response => {
          commit('RENEWALS_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
