export default [
  {
    path: '/cars',
    name: 'Cars',
    component: () => import('@/views/Cars/Index.vue'),
    meta: {
      pageTitle: 'Araç Kartları',
      breadcrumb: [
        {
          text: 'Müşteri Araç',
          active: true,
        },
      ],
      resource: 'CARS_LIST',
      action: 'read',
    },
  },
  {
    path: '/cars/search',
    name: 'CarsAdd',
    component: () => import('@/views/Cars/Search.vue'),
    meta: {
      pageTitle: 'Müşteri Aracı Ekle',
      breadcrumb: [
        {
          text: 'Araç Kartları',
          to: '/cars',
          active: false,
        },
        {
          text: 'Müşteri Seç',
          active: true,
        },
      ],
      resource: 'CARS_ADD',
      action: 'read',
    },
  },
  {
    path: '/cars/add/:id',
    name: 'CarsAdd',
    component: () => import('@/views/Cars/Add.vue'),
    meta: {
      pageTitle: 'Müşteri Aracı Ekle',
      breadcrumb: [
        {
          text: 'Araç Kartları',
          to: '/cars',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'CARS_ADD',
      action: 'read',
    },
  },
  {
    path: '/cars/edit/:id',
    name: 'CarsEdit',
    component: () => import('@/views/Cars/Edit.vue'),
    meta: {
      pageTitle: 'Müşteri Aracı Güncelle',
      breadcrumb: [
        {
          text: 'Araç Kartları',
          to: '/cars',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'CARS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/cars/view/:id',
    name: 'CarsView',
    component: () => import('@/views/Cars/View.vue'),
    meta: {
      pageTitle: 'Müşteri Aracı Görüntüle',
      breadcrumb: [
        {
          text: 'Araç Kartları',
          to: '/cars',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CARS_VIEW',
      action: 'read',
    },
  },
]
