import axiosIns from '@/libs/axios'

const ROOT_URL = '/rentals/rental_documents'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      sdate: null,
      edate: null,
      filename: null,
      notes: null,
      id_com_rental_stocks: null,
      id_com_rental_document_types: null,
      id_com_customer: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    uploading: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    uploading(state) {
      return state.uploading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_UPLOADING(state, data) {
      state.uploading = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        sdate: null,
        edate: null,
        filename: null,
        notes: null,
        id_com_rental_stocks: null,
        id_com_rental_document_types: null,
        id_com_customer: null,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_rental_documents.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('id_com_rental_document_types', postData.id_com_rental_document_types)
      formData.append('id_com_rental_stocks', postData.id_com_rental_stocks)
      formData.append('sdate', postData.sdate)
      formData.append('edate', postData.edate)
      formData.append('notes', postData.notes)
      formData.append('upload_file', postData.upload_file)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          commit('SET_UPLOADING', false)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
  },
}
