// import axios from '@/libs/axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    neighborhoods: [],
    neighborhoodsCount: 0,
    neighborhood: {},
    neighborhoodSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getNeighborhoods(state) {
      return state.neighborhoods
    },
    getNeighborhoodsCount(state) {
      return state.neighborhoodsCount
    },
    getNeighborhood(state) {
      return state.neighborhood
    },
    getNeighborhoodSaveStatus(state) {
      return state.neighborhoodSave
    },
  },
  mutations: {
    NEIGHBORHOODS_LIST(state, data) {
      state.neighborhoods = data
    },
    NEIGHBORHOOD_VIEW(state, data) {
      state.neighborhood = data
    },
    NEIGHBORHOODS_COUNT(state, data) {
      state.neighborhoodsCount = data
    },
    NEIGHBORHOOD_SAVE(state, data) {
      state.neighborhoodSave = data
    },
  },
  actions: {
    neighborhoodsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/neighborhoods', data)
        .then(response => {
          commit('NEIGHBORHOODS_LIST', response.data.data)
          commit('NEIGHBORHOODS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    neighborhoodView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_neighborhood.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/neighborhoods', data)
        .then(response => {
          commit('NEIGHBORHOOD_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    neighborhoodSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/neighborhoods/saveData', data)
        .then(response => {
          commit('NEIGHBORHOOD_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
