import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    purchasing_requests: [],
    purchasing_request: {
      id: null,
      id_com_department: null,
      id_com_brand: null,
      notes: null,
      image: null,
      lines: [
        {
          id_com_product_groups: null,
          id_com_product_units: null,
          content: null,
          quantity: null,
        },
      ],
      submitStatus: false,
    },
    purchasing_requests_count: 0,
    purchasing_request_save: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getPurchasingRequests(state) {
      return state.purchasing_requests
    },
    getPurchasingRequest(state) {
      return state.purchasing_request
    },
    getPurchasingRequestsCount(state) {
      return state.purchasing_requests_count
    },
    getPurchasingRequestSave(state) {
      return state.purchasing_request_save
    },
  },
  mutations: {
    PURCHASING_REQUESTS_LIST(state, data) {
      state.purchasing_requests = data
    },
    PURCHASING_REQUESTS_COUNT(state, data) {
      state.purchasing_requests_count = data
    },
    PURCHASING_REQUEST_VIEW(state, data) {
      state.purchasing_request = data
    },
    PURCHASING_REQUEST_SAVE(state, data) {
      state.purchasing_request_save = data
    },
    PURCHASING_REQUEST_RESET(state, data) {
      if (data.status) {
        state.purchasing_request = {
          id: null,
          id_com_department: null,
          id_com_brand: null,
          notes: null,
          image: null,
          lines: [
            {
              id_com_product_groups: null,
              id_com_product_units: null,
              content: null,
              quantity: null,
            },
          ],
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    purchasingRequests({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/purchasing/requests', data)
        .then(response => {
          commit('PURCHASING_REQUESTS_LIST', response.data.data)
          commit('PURCHASING_REQUESTS_COUNT', response.data.count)
        })
    },
    purchasingRequestView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_purchasing_requests.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/purchasing/requests', data)
        .then(response => {
          commit('PURCHASING_REQUEST_VIEW', response.data.data)
        })
    },
    purchasingRequestsSave({ commit }, postData) {
      const formData = new FormData()
      if (postData.id) {
        formData.append('id', postData.id)
      }
      formData.append('id_com_department', postData.id_com_department)
      formData.append('id_com_brand', postData.id_com_brand)
      formData.append('notes', postData.notes)
      formData.append('image', postData.image)
      postData.lines.forEach((item, ikey) => {
        formData.append(`lines[${ikey}][id_com_product_groups]`, item.id_com_product_groups)
        formData.append(`lines[${ikey}][id_com_product_units]`, item.id_com_product_units)
        formData.append(`lines[${ikey}][content]`, item.content)
        formData.append(`lines[${ikey}][quantity]`, item.quantity)
        // formData.append('lines[]', JSON.stringify(item))
      })
      axiosIns
        .post('/purchasing/requests/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('PURCHASING_REQUEST_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    purchasingRequestReset({ commit }) {
      commit('PURCHASING_REQUEST_RESET', { status: true })
    },
  },
}
