import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    swapmodels: [],
    swapmodelsCount: 0,
    swapmodel: {},
    swapmodelSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getSwapmodels(state) {
      return state.swapmodels
    },
    getSwapmodelsCount(state) {
      return state.swapmodelsCount
    },
    getSwapmodel(state) {
      return state.swapmodel
    },
    getSwapmodelSaveStatus(state) {
      return state.swapmodelSave
    },
  },
  mutations: {
    SWAPMODELS_LIST(state, data) {
      state.swapmodels = data
    },
    SWAPMODEL_VIEW(state, data) {
      state.swapmodel = data
    },
    SWAPMODELS_COUNT(state, data) {
      state.swapmodelsCount = data
    },
    SWAPMODEL_SAVE(state, data) {
      state.swapmodelSave = data
    },
  },
  actions: {
    swapmodelsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/swapmodels', data)
        .then(response => {
          commit('SWAPMODELS_LIST', response.data.data)
          commit('SWAPMODELS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    swapmodelView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_swap_model.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/swapmodels', data)
        .then(response => {
          commit('SWAPMODEL_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    swapmodelSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/swapmodels/saveData', data)
        .then(response => {
          commit('SWAPMODEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
