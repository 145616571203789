import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    hr_notifications: [],
    hr_notificationsCount: 0,
    hr_notification: {
      id: null,
      title: null,
      text1: null,
      text2: null,
      text3: null,
      image: null,
      canvasImage: null,
      status: null,
      mailto: null,
      id_com_hr_notification_types: null,
    },
    hr_notificationSave: {
      message: null,
      status: null,
      id: null,
    },
    hr_notificationDelete: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getHr_notifications(state) {
      return state.hr_notifications
    },
    getHr_notificationsCount(state) {
      return state.hr_notificationsCount
    },
    getHr_notification(state) {
      return state.hr_notification
    },
    getHr_notificationSaveStatus(state) {
      return state.hr_notificationSave
    },
    getHr_notificationDeleteStatus(state) {
      return state.hr_notificationDelete
    },
  },
  mutations: {
    HR_NOTIFICATIONS_LIST(state, data) {
      state.hr_notifications = data
    },
    HR_NOTIFICATION_VIEW(state, data) {
      state.hr_notification = data
    },
    HR_NOTIFICATIONS_COUNT(state, data) {
      state.hr_notificationsCount = data
    },
    HR_NOTIFICATION_SAVE(state, data) {
      state.hr_notificationSave = data
    },
    HR_NOTIFICATION_RESET(state) {
      state.hr_notification = {
        id: null,
        title: null,
        text1: null,
        text2: null,
        text3: null,
        image: null,
        canvasImage: null,
        status: null,
        id_com_hr_notification_types: null,
      }
    },
    HR_NOTIFICATION_DELETE(state, data) {
      state.hr_notificationDelete = data
    },
  },
  actions: {
    hr_notificationsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/hr/hr_notifications', data)
        .then(response => {
          commit('HR_NOTIFICATIONS_LIST', response.data.data)
          commit('HR_NOTIFICATIONS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    hr_notificationView({ commit }, id) {
      commit('HR_NOTIFICATION_RESET')
      const data = JSON.stringify({
        where: {
          'com_hr_notifications.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/hr/hr_notifications', data)
        .then(response => {
          commit('HR_NOTIFICATION_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    hr_notificationSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/hr/hr_notifications/saveData', data)
        .then(response => {
          commit('HR_NOTIFICATION_SAVE', response.data)
          if (!postData.id) {
            commit('HR_NOTIFICATION_RESET')
          }
        })
        .catch(error => console.error(error))
    },
    hr_notificationSend({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/hr/hr_notifications/sendNotification', data)
        .then(response => {
          commit('HR_NOTIFICATION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    hr_notificationDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/hr/hr_notifications/deleteData', data)
        .then(response => {
          commit('HR_NOTIFICATION_DELETE', response.data)
        })
        .catch(error => console.error(error))
    },
    hr_notificationReset({ commit }) {
      commit('HR_NOTIFICATION_RESET')
    },
  },
}
