import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    crm_statuses: [],
    crm_statusesCount: 0,
    crm_status: {},
    crm_statusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCrm_statuses(state) {
      return state.crm_statuses
    },
    getCrm_statusesCount(state) {
      return state.crm_statusesCount
    },
    getCrm_status(state) {
      return state.crm_status
    },
    getCrm_statusSaveStatus(state) {
      return state.crm_statusSave
    },
  },
  mutations: {
    CRM_STATUSES_LIST(state, data) {
      state.crm_statuses = data
    },
    CRM_STATUS_VIEW(state, data) {
      state.crm_status = data
    },
    CRM_STATUSES_COUNT(state, data) {
      state.crm_statusesCount = data
    },
    CRM_STATUS_SAVE(state, data) {
      state.crm_statusSave = data
    },
  },
  actions: {
    crm_statusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/crm_statuses', data)
        .then(response => {
          commit('CRM_STATUSES_LIST', response.data.data)
          commit('CRM_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    crm_statusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_crm_status.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/crm_statuses', data)
        .then(response => {
          commit('CRM_STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    crm_statusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/crm_statuses/saveData', data)
        .then(response => {
          commit('CRM_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    crm_statusDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/crm_statuses/deleteData', data)
        .then(response => {
          commit('CRM_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
