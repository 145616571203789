import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketing_ad_types: [],
    marketing_ad_typesCount: 0,
    marketing_ad_type: {},
    marketing_ad_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMarketing_ad_types(state) {
      return state.marketing_ad_types
    },
    getMarketing_ad_typesCount(state) {
      return state.marketing_ad_typesCount
    },
    getMarketing_ad_type(state) {
      return state.marketing_ad_type
    },
    getMarketing_ad_typeSaveStatus(state) {
      return state.marketing_ad_typeSave
    },
  },
  mutations: {
    MARKETING_AD_TYPES_LIST(state, data) {
      state.marketing_ad_types = data
    },
    MARKETING_AD_TYPE_VIEW(state, data) {
      state.marketing_ad_type = data
    },
    MARKETING_AD_TYPES_COUNT(state, data) {
      state.marketing_ad_typesCount = data
    },
    MARKETING_AD_TYPE_SAVE(state, data) {
      state.marketing_ad_typeSave = data
    },
  },
  actions: {
    marketing_ad_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/marketing/marketing_ad_types', data)
        .then(response => {
          commit('MARKETING_AD_TYPES_LIST', response.data.data)
          commit('MARKETING_AD_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    marketing_ad_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_marketing_ad_types.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/marketing/marketing_ad_types', data)
        .then(response => {
          commit('MARKETING_AD_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    marketing_ad_typeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/marketing/marketing_ad_types/saveData', data)
        .then(response => {
          commit('MARKETING_AD_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
