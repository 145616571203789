export default [
  {
    path: '/meets',
    name: 'Meets',
    component: () => import('@/views/Defines/Meets/Index.vue'),
    meta: {
      pageTitle: 'Temas',
      breadcrumb: [
        {
          text: 'Temas',
          active: true,
        },
      ],
      resource: 'DEFINES_MEETS',
      action: 'read',
    },
  },
  {
    path: '/meets/add',
    name: 'MeetsAdd',
    component: () => import('@/views/Defines/Meets/Add.vue'),
    meta: {
      pageTitle: 'Temas Ekle',
      breadcrumb: [
        {
          text: 'Temas',
          to: '/meets',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_MEETS',
      action: 'read',
    },
  },
  {
    path: '/meets/edit/:id',
    name: 'MeetsEdit',
    component: () => import('@/views/Defines/Meets/Edit.vue'),
    meta: {
      pageTitle: 'Temas Güncelle',
      breadcrumb: [
        {
          text: 'Temas',
          to: '/meets',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_MEETS',
      action: 'read',
    },
  },
]
