import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    csiRows: [],
    csiRowsCount: 0,
    csiRow: {
      id: null,
      year: null,
      month: null,
      goals: null,
      score: null,
      ranking: null,
      id_com_location: null,
      id_com_department: null,
      id_com_brand: null,
      id_com_user_person: null,
    },
    csiRowSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCsiRows(state) {
      return state.csiRows
    },
    getCsiRowsCount(state) {
      return state.csiRowsCount
    },
    getCsiRow(state) {
      return state.csiRow
    },
    getCsiRowSaveStatus(state) {
      return state.csiRowSave
    },
  },
  mutations: {
    CSI_ROWS_LIST(state, data) {
      state.csiRows = data
    },
    CSI_ROW_VIEW(state, data) {
      state.csiRow = data
    },
    CSI_ROWS_COUNT(state, data) {
      state.csiRowsCount = data
    },
    CSI_ROW_SAVE(state, data) {
      state.csiRowSave = data
    },
  },
  actions: {
    csiRowsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/external_data/csi', data)
        .then(response => {
          commit('CSI_ROWS_LIST', response.data.data)
          commit('CSI_ROWS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    csiRowView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_csi.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/external_data/csi', data)
        .then(response => {
          commit('CSI_ROW_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    csiRowSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/external_data/csi/saveData', data)
        .then(response => {
          commit('CSI_ROW_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    csiResetData({ commit }) {
      commit('CSI_ROW_VIEW', {
        id: null,
        year: null,
        month: null,
        goals: null,
        score: null,
        ranking: null,
        id_com_location: null,
        id_com_department: null,
        id_com_brand: null,
        id_com_user_person: null,
      })
    },
  },
}
