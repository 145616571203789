import axiosIns from '@/libs/axios'

const ROOT_URL = '/rentals/rental_noter'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    uploading: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    uploading(state) {
      return state.uploading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_UPLOADING(state, data) {
      state.uploading = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('id_com_rental_stocks', postData.id)
      formData.append('upload_file', postData.upload_file)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          commit('SET_UPLOADING', false)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
  },
}
