export default [
  {
    path: '/webforms/contact',
    name: 'Contact',
    component: () => import('@/views/Webforms/Contact/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İletişim',
          active: true,
        },
      ],
      resource: 'WEBFORM_CONTACT',
      action: 'read',
    },
  },
  {
    path: '/webforms/contact/view/:id',
    name: 'ContactView',
    component: () => import('@/views/Webforms/Contact/View.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İletişim',
          to: '/webforms/contact',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'WEBFORM_CONTACT',
      action: 'read',
    },
  },
]
