export default [
  {
    path: '/service/work-orders/defines/works',
    name: 'SwoDefinesWorks',
    component: () => import('@/views/Service_work_orders/Defines/Works/Index.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'İş Tanımları',
          active: true,
        },
      ],
      resource: 'SERVICE_WORKS',
      action: 'read',
    },
  },
  {
    path: '/service/work-orders/defines/works/add',
    name: 'SwoDefinesWorkAdd',
    component: () => import('@/views/Service_work_orders/Defines/Works/Add.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'İş Tanımları',
          to: '/service/work-orders/defines/works',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SERVICE_WORKS',
      action: 'read',
    },
  },
  {
    path: '/service/work-orders/defines/works/edit/:id',
    name: 'SwoDefinesWorkEdit',
    component: () => import('@/views/Service_work_orders/Defines/Works/Edit.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'İş Tanımları',
          to: '/service/work-orders/defines/works',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SERVICE_WORKS',
      action: 'read',
    },
  },
]
