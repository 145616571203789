export default [
  {
    path: '/portal/edu_calendar',
    name: 'PortalEduCalendars',
    component: () => import('@/views/Portal/EduCalendar/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Eğitim Takvimi',
          active: true,
        },
      ],
      resource: 'PORTAL_EDU_CALENDARS_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/edu_calendar/add',
    name: 'PortalEduCalendarAdd',
    component: () => import('@/views/Portal/EduCalendar/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Eğitim Takvimi',
          to: '/portal/edu_calendar',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_EDU_CALENDARS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/edu_calendar/edit/:id',
    name: 'PortalEduCalendarEdit',
    component: () => import('@/views/Portal/EduCalendar/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Eğitim Takvimi',
          to: '/portal/edu_calendar',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_EDU_CALENDARS_EDIT',
      action: 'read',
    },
  },
]
