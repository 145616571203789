import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    regions: [],
    regionsCount: 0,
    region: {},
    regionSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getRegions(state) {
      return state.regions
    },
    getRegionsCount(state) {
      return state.regionsCount
    },
    getRegion(state) {
      return state.region
    },
    getRegionSaveStatus(state) {
      return state.regionSave
    },
  },
  mutations: {
    REGIONS_LIST(state, data) {
      state.regions = data
    },
    REGION_VIEW(state, data) {
      state.region = data
    },
    REGIONS_COUNT(state, data) {
      state.regionsCount = data
    },
    REGION_SAVE(state, data) {
      state.regionSave = data
    },
  },
  actions: {
    regionsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/regions', data)
        .then(response => {
          commit('REGIONS_LIST', response.data.data)
          commit('REGIONS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    regionView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_region.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/regions', data)
        .then(response => {
          commit('REGION_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    regionSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/regions/saveData', data)
        .then(response => {
          commit('REGION_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
