import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    serviceTypes: [],
    serviceTypesCount: 0,
    serviceType: {},
    serviceTypeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getServiceTypes(state) {
      return state.serviceTypes
    },
    getServiceTypesCount(state) {
      return state.serviceTypesCount
    },
    getServiceType(state) {
      return state.serviceType
    },
    getServiceTypeSaveStatus(state) {
      return state.serviceTypeSave
    },
  },
  mutations: {
    SERVICE_TYPES_LIST(state, data) {
      state.serviceTypes = data
    },
    SERVICE_TYPE_VIEW(state, data) {
      state.serviceType = data
    },
    SERVICE_TYPES_COUNT(state, data) {
      state.serviceTypesCount = data
    },
    SERVICE_TYPE_SAVE(state, data) {
      state.serviceTypeSave = data
    },
  },
  actions: {
    serviceTypesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/service_types', data)
        .then(response => {
          commit('SERVICE_TYPES_LIST', response.data.data)
          commit('SERVICE_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    serviceTypeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_service_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/service_types', data)
        .then(response => {
          commit('SERVICE_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    serviceTypeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/service_types/saveData', data)
        .then(response => {
          commit('SERVICE_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceTypeDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/service_types/deleteData', data)
        .then(response => {
          commit('SERVICE_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
