import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    otvRates: [],
    otvRatesCount: 0,
    otvRate: {},
    otvRateSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getOtvRates(state) {
      return state.otvRates
    },
    getOtvRatesCount(state) {
      return state.otvRatesCount
    },
    getOtvRate(state) {
      return state.otvRate
    },
    getOtvRateSaveStatus(state) {
      return state.otvRateSave
    },
  },
  mutations: {
    OTV_RATES_LIST(state, data) {
      state.otvRates = data
    },
    OTV_RATE_VIEW(state, data) {
      state.otvRate = data
    },
    OTV_RATES_COUNT(state, data) {
      state.otvRatesCount = data
    },
    OTV_RATE_SAVE(state, data) {
      state.otvRateSave = data
    },
  },
  actions: {
    otvRatesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/otv_rates', data)
        .then(response => {
          commit('OTV_RATES_LIST', response.data.data)
          commit('OTV_RATES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    otvRateView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_otvrate.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/defines/otv_rates', data)
        .then(response => {
          commit('OTV_RATE_VIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    otvRateSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/otv_rates/saveData', data)
        .then(response => {
          commit('OTV_RATE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    otvRateDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/otv_rates/deleteData', data)
        .then(response => {
          commit('OTV_RATE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
