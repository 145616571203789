export default [
  {
    path: '/external/csi/brands',
    name: 'CsiBrands',
    component: () => import('@/views/External/Csi/Brands/Index.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'CSI Marka',
          active: true,
        },
      ],
      resource: 'EXTERNAL_CSI',
      action: 'read',
    },
  },
  {
    path: '/external/csi/brands/add',
    name: 'CsiBrandAdd',
    component: () => import('@/views/External/Csi/Brands/Add.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'CSI Marka',
          to: '/external/csi/brands',
          active: false,
        },
        {
          text: 'Veri Girişi',
          active: true,
        },
      ],
      resource: 'EXTERNAL_CSI',
      action: 'read',
    },
  },
  {
    path: '/external/csi/brands/edit/:id',
    name: 'CsiBrandEdit',
    component: () => import('@/views/External/Csi/Brands/Edit.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'CSI Marka',
          to: '/external/csi/brands',
          active: false,
        },
        {
          text: 'Veri Güncelle',
          active: true,
        },
      ],
      resource: 'EXTERNAL_CSI',
      action: 'read',
    },
  },
  {
    path: '/external/csi/users',
    name: 'CsiUsers',
    component: () => import('@/views/External/Csi/Users/Index.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'CSI Danışman',
          active: true,
        },
      ],
      resource: 'EXTERNAL_CSI',
      action: 'read',
    },
  },
  {
    path: '/external/csi/users/add',
    name: 'CsiUserAdd',
    component: () => import('@/views/External/Csi/Users/Add.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'CSI Danışman',
          to: '/external/csi/users',
          active: false,
        },
        {
          text: 'Veri Girişi',
          active: true,
        },
      ],
      resource: 'EXTERNAL_CSI',
      action: 'read',
    },
  },
  {
    path: '/external/csi/users/edit/:id',
    name: 'CsiUserEdit',
    component: () => import('@/views/External/Csi/Users/Edit.vue'),
    meta: {
      pageTitle: 'Veri Girişi',
      breadcrumb: [
        {
          text: 'CSI Danışman',
          to: '/external/csi/users',
          active: false,
        },
        {
          text: 'Veri Güncelle',
          active: true,
        },
      ],
      resource: 'EXTERNAL_CSI',
      action: 'read',
    },
  },
]
