import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    saleDeliveries: [],
    dataItem: {
      id: null,
      order_number: null,
      email: null,
      birthday: null,
      delivery: null,
      license_plate: null,
      photo: null,
      kvkk: null,
      id_com_customer: null,
      id_com_location: null,
      id_com_brand: null,
      modelType: null,
    },
    saleDeliveriesCount: null,
    saleDeliverySave: {
      message: null,
      status: null,
      id: null,
    },
    quickSearch: {
      keyword: '',
    },
  },
  getters: {
    getSaleDeliveries(state) {
      return state.saleDeliveries
    },
    getSaleDeliveriesCount(state) {
      return state.saleDeliveriesCount
    },
    dataItem(state) {
      return state.dataItem
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
    getSaveStatus(state) {
      return state.saleDeliverySave
    },
  },
  mutations: {
    SALE_DELIVERIES_LIST(state, data) {
      state.saleDeliveries = data
    },
    SET_DELIVERY_ITEM(state, data) {
      state.dataItem = data
    },
    SALE_DELIVERIES_COUNT(state, data) {
      state.saleDeliveriesCount = data
    },
    SALE_DELIVERY_SAVE(state, data) {
      state.saleDeliverySave = data
    },
    SET_QUICK_SEARCH(state, data) {
      state.quickSearch = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        order_number: null,
        email: null,
        birthday: null,
        delivery: null,
        license_plate: null,
        photo: null,
        kvkk: null,
        id_com_customer: null,
        id_com_location: null,
        id_com_brand: null,
        modelType: null,
      }
    },
  },
  actions: {
    saleDeliveriesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/sale_delivery/pending', data)
        .then(response => {
          commit('SALE_DELIVERIES_LIST', response.data.data)
          commit('SALE_DELIVERIES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }, id) {
      const data = JSON.stringify({ id })
      axiosIns
        .post('/data/sale_delivery/saleView', data)
        .then(response => {
          commit('SET_DELIVERY_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    sendDeliveryMail({ commit }, params) {
      axiosIns
        .post('/data/sale_delivery/sendDeliveryMail', params)
        .then(response => {
          commit('SALE_DELIVERY_SAVE', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saleDeliverySave({ commit }, postData) {
      const formData = new FormData()
      formData.append('delivery', postData.delivery)
      formData.append('license_plate', postData.license_plate)
      formData.append('email', postData.email)
      formData.append('birthday', postData.birthday)
      formData.append('id_com_customer', postData.id_com_customer)
      formData.append('id_com_sale_orderform', postData.id_com_sale_orderform)
      formData.append('id_com_location', postData.id_com_location)
      formData.append('id_com_stock', postData.id_com_stock)
      formData.append('model_type', postData.modelType)
      formData.append('image', postData.photo)
      return axiosIns
        .post('/data/sale_delivery/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SALE_DELIVERY_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
