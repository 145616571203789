export default [
  {
    path: '/marketing/campaign-sms',
    name: 'MarketingCampaignSms',
    component: () => import('@/views/Marketing/Campaign_sms/Index.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Kampanya Smsleri',
          active: true,
        },
      ],
      resource: 'MARKETING_CAMPAIGN_SMS',
      action: 'read',
    },
  },
]
