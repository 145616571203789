import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    awards: [],
    awardsCount: 0,
    award: {
      id: null,
      title: null,
      content: null,
      image: null,
      upload_image: null,
      submitStatus: false,
    },
    awardSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getAwards(state) {
      return state.awards
    },
    getAwardsCount(state) {
      return state.awardsCount
    },
    getAward(state) {
      return state.award
    },
    getAwardSaveStatus(state) {
      return state.awardSave
    },
  },
  mutations: {
    AWARDS_LIST(state, data) {
      state.awards = data
    },
    AWARD_VIEW(state, data) {
      state.award = data
    },
    AWARDS_COUNT(state, data) {
      state.awardsCount = data
    },
    AWARD_SAVE(state, data) {
      state.awardSave = data
    },
    AWARD_RESET(state, data) {
      if (data.status) {
        state.award = {
          id: null,
          title: null,
          content: null,
          image: null,
          upload_image: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    awardsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/awards', data)
        .then(response => {
          commit('AWARDS_LIST', response.data.data)
          commit('AWARDS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    awardView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_awards.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/awards', data)
        .then(response => {
          commit('AWARD_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    awardSave({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('title', postData.title)
      formData.append('content', postData.content)
      formData.append('old_image', postData.image)
      formData.append('image', postData.upload_image)
      axiosIns
        .post('/portal/awards/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('AWARD_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    awardReset({ commit }) {
      commit('AWARD_RESET', { status: true })
    },
    awardDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/awards/deleteData', data)
        .then(response => {
          commit('AWARD_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
