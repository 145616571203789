export default [
  {
    path: '/portal/events',
    name: 'PortalEvents',
    component: () => import('@/views/Portal/Events/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Etkinlik Takvimi',
          active: true,
        },
      ],
      resource: 'PORTAL_EVENTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/events/add',
    name: 'PortalEventAdd',
    component: () => import('@/views/Portal/Events/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Etkinlik Takvimi',
          to: '/portal/events',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_EVENTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/events/edit/:id',
    name: 'PortalEventEdit',
    component: () => import('@/views/Portal/Events/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Etkinlik Takvimi',
          to: '/portal/events',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_EVENTS_EDIT',
      action: 'read',
    },
  },
]
