import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    adsReport: {
      data: [],
      totals: {},
    },
  },
  getters: {
    getAdsReport(state) {
      return state.adsReport
    },
  },
  mutations: {
    ADS_REPORT_REPORT(state, data) {
      state.adsReport = data
    },
  },
  actions: {
    adsReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/marketing/ads', data)
        .then(response => {
          commit('ADS_REPORT_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
