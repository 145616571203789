import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    events: [],
    eventsCount: 0,
    event: {
      id: null,
      title: null,
      summary: null,
      content: null,
      icon: null,
      edate: null,
      submitStatus: false,
    },
    eventSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getEvents(state) {
      return state.events
    },
    getEventsCount(state) {
      return state.eventsCount
    },
    getEvent(state) {
      return state.event
    },
    getEventSaveStatus(state) {
      return state.eventSave
    },
  },
  mutations: {
    EVENTS_LIST(state, data) {
      state.events = data
    },
    EVENT_VIEW(state, data) {
      state.event = data
    },
    EVENTS_COUNT(state, data) {
      state.eventsCount = data
    },
    EVENT_SAVE(state, data) {
      state.eventSave = data
    },
    EVENT_RESET(state, data) {
      if (data.status) {
        state.event = {
          id: null,
          title: null,
          summary: null,
          content: null,
          icon: null,
          edate: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    eventsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/events', data)
        .then(response => {
          commit('EVENTS_LIST', response.data.data)
          commit('EVENTS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    eventView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_events.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/events', data)
        .then(response => {
          commit('EVENT_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    eventSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/portal/events/saveData', data)
        .then(response => {
          commit('EVENT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    eventReset({ commit }) {
      commit('EVENT_RESET', { status: true })
    },
    eventDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/events/deleteData', data)
        .then(response => {
          commit('EVENT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
