import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    crmAnswers: [],
    crmAnswersCount: 0,
    crmAnswer: {
      id: null,
      content: null,
      comment: null,
      id_com_user: null,
      id_com_crm: null,
    },
    crmAnswerSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCrmAnswers(state) {
      return state.crmAnswers
    },
    getCrmAnswersCount(state) {
      return state.crmAnswersCount
    },
    getCrmAnswer(state) {
      return state.crmAnswer
    },
    getCrmAnswerSaveStatus(state) {
      return state.crmAnswerSave
    },
  },
  mutations: {
    CRM_ANSWERS_LIST(state, data) {
      state.crmAnswers = data
    },
    CRM_ANSWER_VIEW(state, data) {
      // eslint-disable-next-line no-param-reassign
      data.submitStatus = false
      state.crmAnswer = data
    },
    CRM_ANSWERS_COUNT(state, data) {
      state.crmAnswersCount = data
    },
    CRM_ANSWER_SAVE(state, data) {
      state.crmAnswerSave = data
    },
    CRM_ANSWER_RESET(state, data) {
      if (data.status) {
        state.crmAnswer.id = data.id
      }
    },
  },
  actions: {
    crmAnswersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/crm_answers', data)
        .then(response => {
          commit('CRM_ANSWERS_LIST', response.data.data)
          commit('CRM_ANSWERS_COUNT', response.data.count)
          commit('CRM_ANSWER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    crmAnswerView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_crm.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/crm_answers', data)
        .then(response => {
          commit('CRM_ANSWER_VIEW', response.data.data)
          commit('CRM_ANSWER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    crmAnswerSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/crm_answers/saveData', data)
        .then(response => {
          commit('CRM_ANSWER_SAVE', response.data)
          commit('CRM_ANSWER_RESET', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
