import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    interviews: {
      data: [],
      meetsTotal: [],
      grandTotal: [],
    },
    meets: {
      data: [],
      meetsTotal: [],
      grandTotal: [],
    },
    years: [],
  },
  getters: {
    getInterviewsReport(state) {
      return state.interviews
    },
    getMeetReport(state) {
      return state.meets
    },
    getInterviewYears(state) {
      return state.years
    },
  },
  mutations: {
    INTERVIEWS_REPORT(state, data) {
      state.interviews = data
    },
    MEET_REPORT(state, data) {
      state.meets = data
    },
    INTERVIEW_YEARS(state, data) {
      state.years = data
    },
  },
  actions: {
    interviewsReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/rental/interviews', data)
        .then(response => {
          commit('INTERVIEWS_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
    meetReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/rental/interviews/meetReport', data)
        .then(response => {
          commit('MEET_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
    interviewYears({ commit }) {
      axiosIns
        .post('/reports/rental/interviews/getYears')
        .then(response => {
          commit('INTERVIEW_YEARS', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
