import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    crm_levels: [],
    crm_levelsCount: 0,
    crm_level: {},
    crm_levelSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCrm_levels(state) {
      return state.crm_levels
    },
    getCrm_levelsCount(state) {
      return state.crm_levelsCount
    },
    getCrm_level(state) {
      return state.crm_level
    },
    getCrm_levelSaveStatus(state) {
      return state.crm_levelSave
    },
  },
  mutations: {
    CRM_LEVELS_LIST(state, data) {
      state.crm_levels = data
    },
    CRM_LEVEL_VIEW(state, data) {
      state.crm_level = data
    },
    CRM_LEVELS_COUNT(state, data) {
      state.crm_levelsCount = data
    },
    CRM_LEVEL_SAVE(state, data) {
      state.crm_levelSave = data
    },
  },
  actions: {
    crm_levelsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/crm_levels', data)
        .then(response => {
          commit('CRM_LEVELS_LIST', response.data.data)
          commit('CRM_LEVELS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    crm_levelView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_crm_level.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/crm_levels', data)
        .then(response => {
          commit('CRM_LEVEL_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    crm_levelSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/crm_levels/saveData', data)
        .then(response => {
          commit('CRM_LEVEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    crm_levelDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/crm_levels/deleteData', data)
        .then(response => {
          commit('CRM_LEVEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
