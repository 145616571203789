export default [
  {
    path: '/crmlevels',
    name: 'CrmLevels',
    component: () => import('@/views/Defines/Crm_levels/Index.vue'),
    meta: {
      pageTitle: 'Önem Dereceleri',
      breadcrumb: [
        {
          text: 'Önem Dereceleri',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_LEVELS',
      action: 'read',
    },
  },
  {
    path: '/crmlevels/add',
    name: 'CrmLevelsAdd',
    component: () => import('@/views/Defines/Crm_levels/Add.vue'),
    meta: {
      pageTitle: 'Önem Derecesi Ekle',
      breadcrumb: [
        {
          text: 'Önem Dereceleri',
          to: '/crmlevels',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_LEVELS',
      action: 'read',
    },
  },
  {
    path: '/crmlevels/edit/:id',
    name: 'CrmLevelsEdit',
    component: () => import('@/views/Defines/Crm_levels/Edit.vue'),
    meta: {
      pageTitle: 'Önem Derecesi Güncelle',
      breadcrumb: [
        {
          text: 'Önem Dereceleri',
          to: '/crmlevels',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_LEVELS',
      action: 'read',
    },
  },
]
