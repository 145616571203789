import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    interview_statuses: [],
    interview_statusesCount: 0,
    interview_status: {},
    interview_statusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getInterview_statuses(state) {
      return state.interview_statuses
    },
    getInterview_statusesCount(state) {
      return state.interview_statusesCount
    },
    getInterview_status(state) {
      return state.interview_status
    },
    getInterview_statusSaveStatus(state) {
      return state.interview_statusSave
    },
  },
  mutations: {
    INTERVIEW_STATUSES_LIST(state, data) {
      state.interview_statuses = data
    },
    INTERVIEW_STATUS_VIEW(state, data) {
      state.interview_status = data
    },
    INTERVIEW_STATUSES_COUNT(state, data) {
      state.interview_statusesCount = data
    },
    INTERVIEW_STATUS_SAVE(state, data) {
      state.interview_statusSave = data
    },
  },
  actions: {
    interview_statusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/interview_statuses', data)
        .then(response => {
          commit('INTERVIEW_STATUSES_LIST', response.data.data)
          commit('INTERVIEW_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    interview_statusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_interview_status.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/interview_statuses', data)
        .then(response => {
          commit('INTERVIEW_STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    interview_statusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/interview_statuses/saveData', data)
        .then(response => {
          commit('INTERVIEW_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    interview_statusDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/interview_statuses/deleteData', data)
        .then(response => {
          commit('INTERVIEW_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
