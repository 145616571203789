import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    crmSolutions: [],
    crmSolutionsCount: 0,
    crmSolution: {
      id: null,
      content: null,
      comment: null,
      id_com_user: null,
      id_com_crm: null,
    },
    crmSolutionSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCrmSolutions(state) {
      return state.crmSolutions
    },
    getCrmSolutionsCount(state) {
      return state.crmSolutionsCount
    },
    getCrmSolution(state) {
      return state.crmSolution
    },
    getCrmSolutionSaveStatus(state) {
      return state.crmSolutionSave
    },
  },
  mutations: {
    CRM_SOLUTIONS_LIST(state, data) {
      state.crmSolutions = data
    },
    CRM_SOLUTION_VIEW(state, data) {
      // eslint-disable-next-line no-param-reassign
      data.submitStatus = false
      state.crmSolution = data
    },
    CRM_SOLUTIONS_COUNT(state, data) {
      state.crmSolutionsCount = data
    },
    CRM_SOLUTION_SAVE(state, data) {
      state.crmSolutionSave = data
    },
    CRM_SOLUTION_RESET(state, data) {
      if (data.status) {
        state.crmSolution.id = data.id
      }
    },
  },
  actions: {
    crmSolutionsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/crm_solutions', data)
        .then(response => {
          commit('CRM_SOLUTIONS_LIST', response.data.data)
          commit('CRM_SOLUTIONS_COUNT', response.data.count)
          commit('CRM_SOLUTION_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    crmSolutionView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_crm.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/crm_solutions', data)
        .then(response => {
          commit('CRM_SOLUTION_VIEW', response.data.data)
          commit('CRM_SOLUTION_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    crmSolutionSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/crm_solutions/saveData', data)
        .then(response => {
          commit('CRM_SOLUTION_SAVE', response.data)
          commit('CRM_SOLUTION_RESET', response.data)
        })
        .catch(error => console.error(error))
    },
    crmSolutionStatusUpdate({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/crm_solutions/statusUpdate', data)
        .then(response => {
          commit('CRM_SOLUTION_SAVE', response.data)
          commit('CRM_SOLUTION_RESET', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
