import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    realty_sale_blocks: [],
    realty_sale_blocksCount: 0,
    realty_sale_block: {
      id: null,
      title: null,
      id_com_realty_project: null,
    },
    realty_sale_blockSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getRealty_sale_blocks(state) {
      return state.realty_sale_blocks
    },
    getRealty_sale_blocksCount(state) {
      return state.realty_sale_blocksCount
    },
    getRealty_sale_block(state) {
      return state.realty_sale_block
    },
    getRealty_sale_blockSaveStatus(state) {
      return state.realty_sale_blockSave
    },
  },
  mutations: {
    SET_REALTY_SALE_BLOCKS(state, data) {
      state.realty_sale_blocks = data
    },
    SET_REALTY_SALE_BLOCK(state, data) {
      state.realty_sale_block = data
    },
    SET_REALTY_SALE_BLOCKS_COUNT(state, data) {
      state.realty_sale_blocksCount = data
    },
    SET_REALTY_SALE_BLOCK_SAVE(state, data) {
      state.realty_sale_blockSave = data
    },
  },
  actions: {
    realty_sale_blocksList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/realty_blocks', data)
        .then(response => {
          commit('SET_REALTY_SALE_BLOCKS', response.data.data)
          commit('SET_REALTY_SALE_BLOCKS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    realty_sale_blockView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_realty_block.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/realty_blocks', data)
        .then(response => {
          commit('SET_REALTY_SALE_BLOCK', response.data.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_blockSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/realty_blocks/saveData', data)
        .then(response => {
          commit('SET_REALTY_SALE_BLOCK_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_blockDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/realty_blocks/deleteData', data)
        .then(response => {
          commit('SET_REALTY_SALE_BLOCK_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
