import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    statuses: [],
    statusesCount: 0,
    status: {},
    statusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getStatuses(state) {
      return state.statuses
    },
    getStatusesCount(state) {
      return state.statusesCount
    },
    getStatus(state) {
      return state.status
    },
    getStatusSaveStatus(state) {
      return state.statusSave
    },
  },
  mutations: {
    STATUSES_LIST(state, data) {
      state.statuses = data
    },
    STATUS_VIEW(state, data) {
      state.status = data
    },
    STATUSES_COUNT(state, data) {
      state.statusesCount = data
    },
    STATUS_SAVE(state, data) {
      state.statusSave = data
    },
  },
  actions: {
    statusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/statuses', data)
        .then(response => {
          commit('STATUSES_LIST', response.data.data)
          commit('STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    statusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_status.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/statuses', data)
        .then(response => {
          commit('STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    statusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/statuses/saveData', data)
        .then(response => {
          commit('STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
