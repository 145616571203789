export default [
  // Sales
  {
    path: '/realty/sales',
    name: 'RealtySales',
    component: () => import('@/views/Realty/Sales/Index.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Satış',
          active: true,
        },
      ],
      resource: 'REALTY_SALES',
      action: 'read',
    },
  },
  {
    path: '/realty/sales/edit/:id',
    name: 'RealtySaleEdit',
    component: () => import('@/views/Realty/Sales/Edit.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Satış',
          to: '/realty/sales',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'REALTY_SALES_EDIT',
      action: 'read',
    },
  },
  /* Defines */
  // Blocks
  {
    path: '/realty/defines/blocks',
    name: 'RealtyBlocks',
    component: () => import('@/views/Realty/Defines/Blocks/Index.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Bloklar',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_BLOCKS',
      action: 'read',
    },
  },
  {
    path: '/realty/defines/blocks/add',
    name: 'RealtyBlockAdd',
    component: () => import('@/views/Realty/Defines/Blocks/Add.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Bloklar',
          to: '/realty/defines/blocks',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_BLOCKS',
      action: 'read',
    },
  },
  {
    path: '/realty/defines/blocks/edit/:id',
    name: 'RealtyBlockEdit',
    component: () => import('@/views/Realty/Defines/Blocks/Edit.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Bloklar',
          to: '/realty/defines/blocks',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_BLOCKS',
      action: 'read',
    },
  },
  // Projects
  {
    path: '/realty/defines/projects',
    name: 'RealtyProjects',
    component: () => import('@/views/Realty/Defines/Projects/Index.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Projeler',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_PROJECTS',
      action: 'read',
    },
  },
  {
    path: '/realty/defines/projects/add',
    name: 'RealtyProjectAdd',
    component: () => import('@/views/Realty/Defines/Projects/Add.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Projeler',
          to: '/realty/defines/projects',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_PROJECTS',
      action: 'read',
    },
  },
  {
    path: '/realty/defines/projects/edit/:id',
    name: 'RealtyProjectEdit',
    component: () => import('@/views/Realty/Defines/Projects/Edit.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Projeler',
          to: '/realty/defines/projects',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_PROJECTS',
      action: 'read',
    },
  },
  // Types
  {
    path: '/realty/defines/types',
    name: 'RealtyTypes',
    component: () => import('@/views/Realty/Defines/Types/Index.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Gayrimenkul Tipi',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_TYPES',
      action: 'read',
    },
  },
  {
    path: '/realty/defines/types/add',
    name: 'RealtyProjectAdd',
    component: () => import('@/views/Realty/Defines/Types/Add.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Gayrimenkul Tipi',
          to: '/realty/defines/types',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_TYPES',
      action: 'read',
    },
  },
  {
    path: '/realty/defines/types/edit/:id',
    name: 'RealtyProjectEdit',
    component: () => import('@/views/Realty/Defines/Types/Edit.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Gayrimenkul Tipi',
          to: '/realty/defines/types',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'REALTY_DEFINES_TYPES',
      action: 'read',
    },
  },
  // Stocks
  {
    path: '/realty/stocks',
    name: 'RealtyStocks',
    component: () => import('@/views/Realty/Stocks/Index.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Stok Listesi',
          active: true,
        },
      ],
      resource: 'REALTY_STOCKS',
      action: 'read',
    },
  },
  {
    path: '/realty/stocks/add',
    name: 'RealtyStockAdd',
    component: () => import('@/views/Realty/Stocks/Add.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Stok Listesi',
          to: '/realty/stocks',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'REALTY_STOCKS',
      action: 'read',
    },
  },
  {
    path: '/realty/stocks/edit/:id',
    name: 'RealtyStockEdit',
    component: () => import('@/views/Realty/Stocks/Edit.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Stok Listesi',
          to: '/realty/stocks',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'REALTY_STOCKS',
      action: 'read',
    },
  },
  {
    path: '/realty/stocks/multiple/:id',
    name: 'RealtyStockMultiple',
    component: () => import('@/views/Realty/Stocks/Multiple.vue'),
    meta: {
      pageTitle: 'Gayrimenkul',
      breadcrumb: [
        {
          text: 'Stok Listesi',
          to: '/realty/stocks',
          active: false,
        },
        {
          text: 'Fiyat Güncelleme',
          active: true,
        },
      ],
      resource: 'REALTY_STOCKS',
      action: 'read',
    },
  },
]
