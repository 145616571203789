export default [
  {
    path: '/webforms/G2saleform',
    name: 'G2saleform',
    component: () => import('@/views/Webforms/G2saleform/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Satmak İstiyorum',
          active: true,
        },
      ],
      resource: 'WEBFORM_G2SALEFORM',
      action: 'read',
    },
  },
  {
    path: '/webforms/G2saleform/view/:id',
    name: 'G2saleformView',
    component: () => import('@/views/Webforms/G2saleform/View.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Satmak İstiyorum',
          to: '/webforms/G2saleform',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'WEBFORM_G2SALEFORM',
      action: 'read',
    },
  },
]
