export default [
  {
    path: '/rental/contracts',
    name: 'RentalContracts',
    component: () => import('@/views/Rental/Contracts/Index.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sözleşmeler',
          active: true,
        },
      ],
      resource: 'RENTAL_CONTRACTS',
      action: 'read',
    },
  },
  {
    path: '/rental/contracts/search',
    name: 'RentalContractSearch',
    component: () => import('@/views/Rental/Contracts/Search.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sözleşmeler',
          to: '/rental/contracts',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          active: true,
        },
      ],
      resource: 'RENTAL_CONTRACTS',
      action: 'read',
    },
  },
  {
    path: '/rental/contracts/add/:id',
    name: 'RentalContractAdd',
    component: () => import('@/views/Rental/Contracts/Add.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sözleşmeler',
          to: '/rental/contracts',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          to: '/rental/contracts/search',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'RENTAL_CONTRACTS',
      action: 'read',
    },
  },
  {
    path: '/rental/contracts/edit/:id',
    name: 'RentalContractEdit',
    component: () => import('@/views/Rental/Contracts/Edit.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sözleşmeler',
          to: '/rental/contracts',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'RENTAL_CONTRACTS',
      action: 'read',
    },
  },
  {
    path: '/rental/contracts/view/:id',
    name: 'RentalContractView',
    component: () => import('@/views/Rental/Contracts/View.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sözleşmeler',
          to: '/rental/contracts',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'RENTAL_CONTRACTS',
      action: 'read',
    },
  },
  {
    path: '/rental/contracts/watch-list',
    name: 'RentalContractTracking',
    component: () => import('@/views/Rental/Contracts/WatchList.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Sözleşmeler',
          to: '/rental/contracts',
          active: false,
        },
        {
          text: 'Sözleşme Takip Listesi',
          active: true,
        },
      ],
      resource: 'RENTAL_CONTRACTS',
      action: 'read',
    },
  },
]
