import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketing_ad_media: [],
    marketing_ad_mediaCount: 0,
    marketing_ad_mediaDelete: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMarketing_ad_media(state) {
      return state.marketing_ad_media
    },
    getMarketing_ad_mediaCount(state) {
      return state.marketing_ad_mediaCount
    },
    getMarketing_ad_mediaDeleteStatus(state) {
      return state.marketing_ad_mediaDelete
    },
  },
  mutations: {
    MARKETING_AD_MEDIA_LIST(state, data) {
      state.marketing_ad_media = data
    },
    MARKETING_AD_MEDIA_COUNT(state, data) {
      state.marketing_ad_mediaCount = data
    },
    MARKETING_AD_MEDIA_DELETE(state, data) {
      state.marketing_ad_mediaDelete = data
    },
  },
  actions: {
    marketing_ad_mediaList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/marketing/marketing_ad_media', data)
        .then(response => {
          commit('MARKETING_AD_MEDIA_LIST', response.data.data)
          commit('MARKETING_AD_MEDIA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    marketing_ad_mediaDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/marketing/marketing_ad_media/deleteData', data)
        .then(response => {
          commit('MARKETING_AD_MEDIA_DELETE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
