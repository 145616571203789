import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    fuels: [],
    fuelsCount: 0,
    fuel: {},
    fuelSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getFuels(state) {
      return state.fuels
    },
    getFuelsCount(state) {
      return state.fuelsCount
    },
    getFuel(state) {
      return state.fuel
    },
    getFuelSaveStatus(state) {
      return state.fuelSave
    },
  },
  mutations: {
    FUELS_LIST(state, data) {
      state.fuels = data
    },
    FUEL_VIEW(state, data) {
      state.fuel = data
    },
    FUELS_COUNT(state, data) {
      state.fuelsCount = data
    },
    FUEL_SAVE(state, data) {
      state.fuelSave = data
    },
  },
  actions: {
    fuelsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/fuels', data)
        .then(response => {
          commit('FUELS_LIST', response.data.data)
          commit('FUELS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    fuelView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_fuel.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/fuels', data)
        .then(response => {
          commit('FUEL_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    fuelSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/fuels/saveData', data)
        .then(response => {
          commit('FUEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    fuelDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/fuels/deleteData', data)
        .then(response => {
          commit('FUEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
