import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    swapcars: [],
    swapcarsCount: 0,
    swapcar: {
      id: null,
      hardware: null,
      model_year: null,
      km: null,
      cc: null,
      hp: null,
      expertise_report: null,
      swap_price: null,
      request_price: null,
      license_owner: null,
      muayene: null,
      equipment: null,
      expertise: [],
      tramer: null,
      notes: null,
      trafik: null,
      edate: null,
      interview_number: null,
      chasis: null,
      license_plate: null,
      license_file: null,
      fr_date: null,
      in_date: null,
      ws_date: null,
      we_date: null,
      swapcar_type: null,
      invoice_type: null,
      user_request: null,
      id_com_gear: null,
      id_com_fuel: null,
      id_com_color: null,
      id_com_customer: null,
      id_com_swap_brand: null,
      id_com_swap_model: null,
      id_com_user_request: null,
      id_com_location: null,
      detailForm: false,
      submitStatus: false,
    },
    swapcarSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getSwapcars(state) {
      return state.swapcars
    },
    getSwapcarsCount(state) {
      return state.swapcarsCount
    },
    getSwapcar(state) {
      return state.swapcar
    },
    getSwapcarSaveStatus(state) {
      return state.swapcarSave
    },
  },
  mutations: {
    SWAPCARS_LIST(state, data) {
      state.swapcars = data
    },
    SWAPCAR_VIEW(state, data) {
      state.swapcar = data
    },
    SWAPCARS_COUNT(state, data) {
      state.swapcarsCount = data
    },
    SWAPCAR_SAVE(state, data) {
      state.swapcarSave = data
    },
    SWAPCAR_RESET(state, data) {
      state.swapcar = {
        id: null,
        hardware: null,
        model_year: null,
        km: null,
        cc: null,
        hp: null,
        expertise_report: null,
        swap_price: null,
        request_price: null,
        license_owner: null,
        muayene: null,
        equipment: null,
        expertise: [],
        tramer: null,
        notes: null,
        trafik: null,
        edate: null,
        interview_number: null,
        chasis: null,
        license_plate: null,
        license_file: null,
        fr_date: null,
        in_date: null,
        ws_date: null,
        we_date: null,
        swapcar_type: null,
        invoice_type: null,
        user_request: null,
        id_com_gear: null,
        id_com_fuel: null,
        id_com_color: null,
        id_com_customer: null,
        id_com_swap_brand: null,
        id_com_swap_model: null,
        id_com_user_request: null,
        id_com_location: null,
        detailForm: false,
        submitStatus: false,
      }
    },
  },
  actions: {
    swapcarsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/swapcars', data)
        .then(response => {
          commit('SWAPCARS_LIST', response.data.data)
          commit('SWAPCARS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    swapcarView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_swapcar.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/swapcars', data)
        .then(response => {
          commit('SWAPCAR_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    swapcarSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/swapcars/saveData', data)
        .then(response => {
          commit('SWAPCAR_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    swapcarRequest({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/swapcars/requestData', data)
        .then(response => {
          commit('SWAPCAR_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    carSearch({ commit }, params) {
      axiosIns
        .post('/data/swapcars/carSearch', params)
        .then(response => {
          commit('SWAPCARS_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    swapcarReset({ commit }) {
      commit('SWAPCAR_RESET', { status: true })
    },
  },
}
