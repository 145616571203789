export default [
  {
    path: '/guides',
    name: 'Guides',
    component: () => import('@/views/Guides/Index.vue'),
    meta: {
      pageTitle: 'Kullanım Kılavuzu',
      breadcrumb: [
        {
          text: 'Kullanım Kılavuzu',
          active: true,
        },
      ],
      resource: 'DASHBOARD',
      action: 'read',
    },
  },
]
