import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    couponTypes: [],
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    getCouponTypes(state) {
      return state.couponTypes
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_COUPON_TYPES(state, data) {
      state.couponTypes = data
    },
  },
  actions: {
    dataList({ commit }, params) {
      return axiosIns
        .post('/data/campaign_sms', params)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          return response.data.data
        })
        .catch(error => console.log(error))
    },
    couponTypes({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/campaign_sms/getcoupontypes', data)
        .then(response => {
          commit('SET_COUPON_TYPES', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
