import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: {},
    totalData: {},
    years: [],
    filterData: {
      year: null,
      months: null,
      id_com_location: null,
    },
    loadingReport: true,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getTotalData(state) {
      return state.totalData
    },
    getYears(state) {
      return state.years
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_REPORT_DATA_TOTAL(state, data) {
      state.totalData = data
    },
    SET_YEARS_DATA(state, data) {
      state.years = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/insurance/sales', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_REPORT_DATA_TOTAL', response.data.totals)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
    years({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/reports/insurance/sales/getYears', data)
        .then(response => {
          commit('SET_YEARS_DATA', response.data.data)
          return response.data.data
        })
        .catch(error => console.log(error))
    },
  },
}
