import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketing_ad_platforms: [],
    marketing_ad_platformsCount: 0,
    marketing_ad_platform: {},
    marketing_ad_platformSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMarketing_ad_platforms(state) {
      return state.marketing_ad_platforms
    },
    getMarketing_ad_platformsCount(state) {
      return state.marketing_ad_platformsCount
    },
    getMarketing_ad_platform(state) {
      return state.marketing_ad_platform
    },
    getMarketing_ad_platformSaveStatus(state) {
      return state.marketing_ad_platformSave
    },
  },
  mutations: {
    MARKETING_AD_PLATFORMS_LIST(state, data) {
      state.marketing_ad_platforms = data
    },
    MARKETING_AD_PLATFORM_VIEW(state, data) {
      state.marketing_ad_platform = data
    },
    MARKETING_AD_PLATFORMS_COUNT(state, data) {
      state.marketing_ad_platformsCount = data
    },
    MARKETING_AD_PLATFORM_SAVE(state, data) {
      state.marketing_ad_platformSave = data
    },
  },
  actions: {
    marketing_ad_platformsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/marketing/marketing_ad_platforms', data)
        .then(response => {
          commit('MARKETING_AD_PLATFORMS_LIST', response.data.data)
          commit('MARKETING_AD_PLATFORMS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    marketing_ad_platformView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_marketing_ad_platforms.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/marketing/marketing_ad_platforms', data)
        .then(response => {
          commit('MARKETING_AD_PLATFORM_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    marketing_ad_platformSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/marketing/marketing_ad_platforms/saveData', data)
        .then(response => {
          commit('MARKETING_AD_PLATFORM_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
