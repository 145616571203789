export default [
  {
    path: '/portal/awards',
    name: 'PortalAwards',
    component: () => import('@/views/Portal/Awards/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Ödüller',
          active: true,
        },
      ],
      resource: 'PORTAL_AWARDS_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/awards/add',
    name: 'PortalAwardAdd',
    component: () => import('@/views/Portal/Awards/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Ödüller',
          to: '/portal/awards',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_AWARDS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/awards/edit/:id',
    name: 'PortalAwardEdit',
    component: () => import('@/views/Portal/Awards/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Ödüller',
          to: '/portal/awards',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_AWARDS_EDIT',
      action: 'read',
    },
  },
]
