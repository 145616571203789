import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    galleryPhotos: [],
    galleryPhotosCount: 0,
    galleryPhoto: {
      id: null,
      title: null,
      content: null,
      cover: null,
      upload_cover: null,
      upload_images: [],
      submitStatus: false,
    },
    galleryPhotoSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getGalleryPhotos(state) {
      return state.galleryPhotos
    },
    getGalleryPhotosCount(state) {
      return state.galleryPhotosCount
    },
    getGalleryPhoto(state) {
      return state.galleryPhoto
    },
    getGalleryPhotoSaveStatus(state) {
      return state.galleryPhotoSave
    },
  },
  mutations: {
    GALLERY_PHOTOS_LIST(state, data) {
      state.galleryPhotos = data
    },
    GALLERY_PHOTO_VIEW(state, data) {
      state.galleryPhoto = data
    },
    GALLERY_PHOTOS_COUNT(state, data) {
      state.galleryPhotosCount = data
    },
    GALLERY_PHOTO_SAVE(state, data) {
      state.galleryPhotoSave = data
    },
    GALLERY_PHOTO_RESET(state, data) {
      if (data.status) {
        state.galleryPhoto = {
          id: null,
          title: null,
          content: null,
          cover: null,
          upload_cover: null,
          upload_images: [],
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    galleryPhotosList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/gallery', data)
        .then(response => {
          commit('GALLERY_PHOTOS_LIST', response.data.data)
          commit('GALLERY_PHOTOS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    galleryPhotoView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_gallery.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/gallery', data)
        .then(response => {
          commit('GALLERY_PHOTO_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    galleryPhotoSave({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('title', postData.title)
      formData.append('content', postData.content)
      formData.append('old_image', postData.cover)
      formData.append('image', postData.upload_image)
      if (postData.upload_images) {
        postData.upload_images.forEach(e => {
          formData.append('images[]', e)
        })
      }
      axiosIns
        .post('/portal/gallery/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('GALLERY_PHOTO_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    galleryPhotoReset({ commit }) {
      commit('GALLERY_PHOTO_RESET', { status: true })
    },
    galleryDeleteImage({ commit }, id) {
      const data = JSON.stringify({ id })
      axiosIns
        .post('/portal/gallery/deleteImage', data)
        .then(response => {
          commit('GALLERY_PHOTO_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    galleryDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/gallery/deleteData', data)
        .then(response => {
          commit('GALLERY_PHOTO_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
