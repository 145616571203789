export default [
  {
    path: '/colors',
    name: 'Colors',
    component: () => import('@/views/Defines/Colors/Index.vue'),
    meta: {
      pageTitle: 'Renk Seçenekleri',
      breadcrumb: [
        {
          text: 'Renk Seçenekleri',
          active: true,
        },
      ],
      resource: 'DEFINES_COLORS',
      action: 'read',
    },
  },
  {
    path: '/colors/add',
    name: 'ColorsAdd',
    component: () => import('@/views/Defines/Colors/Add.vue'),
    meta: {
      pageTitle: 'Renk Seçeneği Ekle',
      breadcrumb: [
        {
          text: 'Renk Seçenekleri',
          to: '/colors',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_COLORS',
      action: 'read',
    },
  },
  {
    path: '/colors/edit/:id',
    name: 'ColorsEdit',
    component: () => import('@/views/Defines/Colors/Edit.vue'),
    meta: {
      pageTitle: 'Renk Seçeneği Güncelle',
      breadcrumb: [
        {
          text: 'Renk Seçenekleri',
          to: '/colors',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_COLORS',
      action: 'read',
    },
  },
]
