import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    offerLinePriceItems: [],
    offerLinePriceItemsCount: 0,
    offerLinePriceItem: {
      id: null,
      title: null,
      id_offer_line: null,
      id_com_user: null,
    },
    offerLinePriceItemSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getOfferLinePriceItems(state) {
      return state.offerLinePriceItems
    },
    getOfferLinePriceItemsCount(state) {
      return state.offerLinePriceItemsCount
    },
    getOfferLinePriceItem(state) {
      return state.offerLinePriceItem
    },
    getOfferLinePriceItemSaveStatus(state) {
      return state.offerLinePriceItemSave
    },
  },
  mutations: {
    OFFER_LINE_PRICE_ITEMS_LIST(state, data) {
      state.offerLinePriceItems = data
    },
    OFFER_LINE_PRICE_ITEM_VIEW(state, data) {
      state.offerLinePriceItem = data
    },
    OFFER_LINE_PRICE_ITEMS_COUNT(state, data) {
      state.offerLinePriceItemsCount = data
    },
    OFFER_LINE_PRICE_ITEM_SAVE(state, data) {
      state.offerLinePriceItemSave = data
    },
    RESET_OFFER_LINE_PRICE_ITEMS_LIST(state) {
      state.offerLinePriceItems = []
    },
    OFFER_LINE_PRICE_ITEM_RESET(state) {
      state.offerLinePriceItem = {
        id: null,
        title: null,
        id_offer_line: null,
        id_com_user: null,
      }
    },
  },
  actions: {
    offerLinePriceItemsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/offer_line_price_items', data)
        .then(response => {
          commit('OFFER_LINE_PRICE_ITEMS_LIST', response.data.data)
          commit('OFFER_LINE_PRICE_ITEMS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    offerLinePriceItemView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_offer_line_price_items.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/offer_line_price_items', data)
        .then(response => {
          commit('OFFER_LINE_PRICE_ITEM_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    offerLinePriceItemSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/offer_line_price_items/saveData', data)
        .then(response => {
          commit('OFFER_LINE_PRICE_ITEM_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    offerLinePriceItemReset({ commit }) {
      commit('OFFER_LINE_PRICE_ITEM_RESET')
    },
  },
}
