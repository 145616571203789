export default [
  {
    path: '/crmfeedbacktypes',
    name: 'CrmFeedbackTypes',
    component: () => import('@/views/Defines/Crm_feedback_types/Index.vue'),
    meta: {
      pageTitle: 'Başvuru Tipleri',
      breadcrumb: [
        {
          text: 'Başvuru Tipleri',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_TYPES',
      action: 'read',
    },
  },
  {
    path: '/crmfeedbacktypes/add',
    name: 'CrmFeedbackTypesAdd',
    component: () => import('@/views/Defines/Crm_feedback_types/Add.vue'),
    meta: {
      pageTitle: 'Başvuru Tipi Ekle',
      breadcrumb: [
        {
          text: 'Başvuru Tipleri',
          to: '/crmfeedbacktypes',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_TYPES',
      action: 'read',
    },
  },
  {
    path: '/crmfeedbacktypes/edit/:id',
    name: 'CrmFeedbackTypesEdit',
    component: () => import('@/views/Defines/Crm_feedback_types/Edit.vue'),
    meta: {
      pageTitle: 'Başvuru Tipi Güncelle',
      breadcrumb: [
        {
          text: 'Başvuru Tipleri',
          to: '/crmfeedbacktypes',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_TYPES',
      action: 'read',
    },
  },
]
