export default [
  {
    path: '/purchasing_orders',
    name: 'purchasing_orders',
    component: () => import('@/views/Purchasing/Orders/Index.vue'),
    meta: {
      pageTitle: 'Satın Almalar',
      breadcrumb: [
        {
          text: 'Satın Almalar',
          active: true,
        },
      ],
      resource: 'PURCHASING_ORDERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_orders/add/:id',
    name: 'PurchasingOrdersAdd',
    component: () => import('@/views/Purchasing/Orders/Add.vue'),
    meta: {
      pageTitle: 'Satın Almalar',
      breadcrumb: [
        {
          text: 'Satın Almalar',
          to: '/purchasing_orders',
        },
        {
          text: 'Sipariş Oluştur',
          active: true,
        },
      ],
      resource: 'PURCHASING_ORDERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_orders/view/:id',
    name: 'PurchasingOrdersView',
    component: () => import('@/views/Purchasing/Orders/View.vue'),
    meta: {
      pageTitle: 'Satın Almalar',
      breadcrumb: [
        {
          text: 'Satın Almalar',
          to: '/purchasing_orders',
        },
        {
          text: 'Talep Görüntüle',
          active: true,
        },
      ],
      resource: 'PURCHASING_ORDERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_orders/completed',
    name: 'CompletedOrders',
    component: () => import('@/views/Purchasing/Orders/CompletedOrders.vue'),
    meta: {
      pageTitle: 'Satın Almalar',
      breadcrumb: [
        {
          text: 'Satın Almalar',
          to: '/purchasing_orders',
        },
        {
          text: 'Tamamlanan',
          active: true,
        },
      ],
      resource: 'PURCHASING_ORDERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_orders/waiting',
    name: 'WaitingOrders',
    component: () => import('@/views/Purchasing/Orders/WaitingOrders.vue'),
    meta: {
      pageTitle: 'Satın Almalar',
      breadcrumb: [
        {
          text: 'Satın Almalar',
          to: '/purchasing_orders',
        },
        {
          text: 'Bekleyen',
          active: true,
        },
      ],
      resource: 'PURCHASING_ORDERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_orders/canceled',
    name: 'CanceledOrders',
    component: () => import('@/views/Purchasing/Orders/CanceledOrders.vue'),
    meta: {
      pageTitle: 'Satın Almalar',
      breadcrumb: [
        {
          text: 'Satın Almalar',
          to: '/purchasing_orders',
        },
        {
          text: 'İptal Edilen',
          active: true,
        },
      ],
      resource: 'PURCHASING_ORDERS_LIST',
      action: 'read',
    },
  },
]
