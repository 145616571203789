import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    requests: [],
    requestsCount: 0,
    requestsCounters: [],
    request: {
      id: null,
      content: null,
      id_com_crm_level: null,
      id_com_confirmation_user: null,
      id_com_crm_status: null,
      id_com_crm_applysource: null,
      id_com_feedback_type: null,
      id_com_crm_subject1: null,
      id_com_crm_subject2: null,
      id_com_crm_subject3: null,
      id_com_department1: null,
      id_com_department2: null,
      id_com_brand: null,
      id_com_location: null,
      id_com_customer: null,
      id_com_cars: null,
      id_com_user: null,
      id_com_user1: null,
      id_com_user2: null,
      id_com_user3: null,
      email_to: [],
      email_users: [],
      files: [],
      submitStatus: false,
    },
    emailToView: [],
    customerCrmHistory: [],
    quickSearch: {
      keyword: '',
      id_com_crm: '',
    },
    requestItem: {
      id: null,
    },
    requestSave: {
      message: null,
      status: null,
      id: null,
    },
    filterData: {
      id_com_crm_subject: [],
      id_com_brand: [],
      id_com_department: [],
      id_com_crm_status: [],
      id_com_open_user: [],
      id_com_user: [],
      id_com_crm_applysource: [],
      id_com_feedback_type: [],
      id_com_location: [],
      sdate: null,
      edate: null,
      detailSearch: false,
    },
    requestModal: {
      data: {},
      status: false,
    },
  },
  getters: {
    getRequests(state) {
      return state.requests
    },
    getRequestsCount(state) {
      return state.requestsCount
    },
    getRequestsCounters(state) {
      return state.requestsCounters
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
    getRequest(state) {
      return state.request
    },
    getRequestSaveStatus(state) {
      return state.requestSave
    },
    getRequestEMails(state) {
      return state.emailToView
    },
    getCustomerCrmHistory(state) {
      return state.customerCrmHistory
    },
    getFilterData(state) {
      return state.filterData
    },
    getRequestItem(state) {
      return state.requestItem
    },
    requestModal(state) {
      return state.requestModal
    },
  },
  mutations: {
    REQUESTS_LIST(state, data) {
      state.requests = data
    },
    REQUEST_VIEW(state, data) {
      // eslint-disable-next-line no-param-reassign
      data.submitStatus = false
      state.request = data
    },
    REQUESTS_COUNT(state, data) {
      state.requestsCount = data
    },
    REQUESTS_COUNTERS(state, data) {
      state.requestsCounters = data
    },
    SET_QUICK_SEARCH(state, data) {
      state.quickSearch = data
    },
    REQUEST_SAVE(state, data) {
      state.requestSave = data
    },
    REQUEST_EMAILS(state, data) {
      state.emailToView = data
    },
    REQUEST_EMAIL_TO(state, data) {
      state.request.email_to = data
    },
    CUSTOMER_CRM_HISTORY(state, data) {
      state.customerCrmHistory = data
    },
    SET_REQUESTS_ITEM(state, data) {
      state.requestItem = data
    },
    REQUEST_RESET(state, data) {
      if (data.status) {
        state.request = {
          id: null,
          content: null,
          id_com_crm_level: null,
          id_com_confirmation_user: null,
          id_com_crm_status: null,
          id_com_crm_applysource: null,
          id_com_feedback_type: null,
          id_com_crm_subject1: null,
          id_com_crm_subject2: null,
          id_com_crm_subject3: null,
          id_com_department1: null,
          id_com_department2: null,
          id_com_brand: null,
          id_com_location: null,
          id_com_customer: null,
          id_com_cars: null,
          id_com_user: null,
          id_com_user1: null,
          id_com_user2: null,
          id_com_user3: null,
          files: [],
          submitStatus: false,
        }
        state.emailToView = []
      }
    },
    RESET_FILTER_DATA(state) {
      state.filterData = {
        id_com_crm_subject: [],
        id_com_brand: [],
        id_com_department: [],
        id_com_crm_status: [],
        id_com_open_user: [],
        id_com_user: [],
        id_com_crm_applysource: [],
        id_com_feedback_type: [],
        id_com_location: [],
        sdate: null,
        edate: null,
        detailSearch: false,
      }
    },
    SET_REQUEST_MODAL(state, data) {
      state.requestModal.data = data
    },
  },
  actions: {
    requestsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/crm', data)
        .then(response => {
          commit('REQUESTS_LIST', response.data.data)
          commit('REQUESTS_COUNT', response.data.count)
          commit('REQUESTS_COUNTERS', response.data.counters)
          commit('REQUEST_SAVE', {
            message: null,
            status: null,
            id: null,
          })
        })
        .catch(error => console.error(error))
    },
    requestItem({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/data/crm', data)
        .then(response => {
          commit('SET_REQUESTS_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    requestModalItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_crm.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/crm', data)
        .then(response => {
          commit('SET_REQUEST_MODAL', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    requestView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_crm.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/crm', data)
        .then(response => {
          commit('REQUEST_VIEW', response.data.data)
          if (response.data.data.email_users) {
            commit('REQUEST_EMAILS', response.data.data.email_users)
          }
          commit('REQUEST_SAVE', {
            message: null,
            status: null,
            id: null,
          })
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    requestSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/crm/saveData', data)
        .then(response => {
          commit('REQUEST_SAVE', response.data)
          if (!postData.id) {
            commit('REQUEST_RESET', response.data)
          }
        })
        .catch(error => console.error(error))
    },
    requestReset({ commit }) {
      commit('REQUEST_RESET', { status: true })
    },
    getEmails({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/crm/getEmails', data)
        .then(response => {
          commit('REQUEST_EMAIL_TO', response.data.email_to)
          commit('REQUEST_EMAILS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    customerCrmHistory({ commit }, idComCustomer) {
      const data = JSON.stringify({ id_com_customer: idComCustomer })
      axiosIns
        .post('/data/crm/customerCrmHistory', data)
        .then(response => {
          commit('CUSTOMER_CRM_HISTORY', response.data.data)
        })
        .catch(error => console.error(error))
    },
    requestDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/data/crm/deleteData', data)
        .then(response => {
          commit('REQUEST_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
