import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    sparepart_order_types: [],
    sparepart_order_typesCount: 0,
    sparepart_order_type: {},
    sparepart_order_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getSparepart_order_types(state) {
      return state.sparepart_order_types
    },
    getSparepart_order_typesCount(state) {
      return state.sparepart_order_typesCount
    },
    getSparepart_order_type(state) {
      return state.sparepart_order_type
    },
    getSparepart_order_typeSaveStatus(state) {
      return state.sparepart_order_typeSave
    },
  },
  mutations: {
    SPAREPART_ORDER_TYPES_LIST(state, data) {
      state.sparepart_order_types = data
    },
    SPAREPART_ORDER_TYPE_VIEW(state, data) {
      state.sparepart_order_type = data
    },
    SPAREPART_ORDER_TYPES_COUNT(state, data) {
      state.sparepart_order_typesCount = data
    },
    SPAREPART_ORDER_TYPE_SAVE(state, data) {
      state.sparepart_order_typeSave = data
    },
  },
  actions: {
    sparepart_order_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/sparepart_order_types', data)
        .then(response => {
          commit('SPAREPART_ORDER_TYPES_LIST', response.data.data)
          commit('SPAREPART_ORDER_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    sparepart_order_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_sparepart_order_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/sparepart_order_types', data)
        .then(response => {
          commit('SPAREPART_ORDER_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    sparepart_order_typeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/sparepart_order_types/saveData', data)
        .then(response => {
          commit('SPAREPART_ORDER_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    sparepart_order_typeDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/sparepart_order_types/deleteData', data)
        .then(response => {
          commit('SPAREPART_ORDER_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
