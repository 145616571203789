import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    consultantGoals: [],
    consultantGoalsCount: null,
    consultantGoal: {
      id: null,
      id_com_brand: null,
      id_com_consultant: null,
      goal: null,
      digital_goal: null,
      showroom: null,
      phone: null,
      web: null,
      web_distributor: null,
      tahsis_baglanti: null,
      test_drive: null,
      month: null,
      year: null,
      id_com_user: null,
      adddate: null,
      addtime: null,
      submitStatus: false,
    },
    consultantGoalSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getConsultantGoals(state) {
      return state.consultantGoals
    },
    getConsultantGoalsCount(state) {
      return state.consultantGoalsCount
    },
    getConsultantGoal(state) {
      return state.consultantGoal
    },
    getConsultantGoalSaveStatus(state) {
      return state.consultantGoalSave
    },
  },
  mutations: {
    CONSULTANT_GOALS_LIST(state, data) {
      state.consultantGoals = data
    },
    CONSULTANT_GOAL(state, data) {
      state.consultantGoal = data
    },
    CONSULTANT_GOALS_COUNT(state, data) {
      state.consultantGoalsCount = data
    },
    CONSULTANT_GOAL_SAVE(state, data) {
      state.consultantGoalSave = data
    },
  },
  actions: {
    consultantGoalsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/consultant_goals', data)
        .then(response => {
          commit('CONSULTANT_GOALS_LIST', response.data.data)
          commit('CONSULTANT_GOALS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    consultantGoalView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_consultant_goals.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/consultant_goals', data)
        .then(response => {
          commit('CONSULTANT_GOAL', response.data.data)
        })
        .catch(error => console.error(error))
    },
    consultantGoalSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/consultant_goals/saveData', data)
        .then(response => {
          commit('CONSULTANT_GOAL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
