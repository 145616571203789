export default [
  {
    path: '/tirestatuses',
    name: 'TireStatuses',
    component: () => import('@/views/Defines/Tire_statuses/Index.vue'),
    meta: {
      pageTitle: 'Lastik Durumları',
      breadcrumb: [
        {
          text: 'Lastik Durumları',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_STATUSES',
      action: 'read',
    },
  },
  {
    path: '/tirestatuses/add',
    name: 'TireStatusesAdd',
    component: () => import('@/views/Defines/Tire_statuses/Add.vue'),
    meta: {
      pageTitle: 'Lastik Durumu Ekle',
      breadcrumb: [
        {
          text: 'Lastik Durumları',
          to: '/tirestatuses',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_STATUSES',
      action: 'read',
    },
  },
  {
    path: '/tirestatuses/edit/:id',
    name: 'TireStatusesEdit',
    component: () => import('@/views/Defines/Tire_statuses/Edit.vue'),
    meta: {
      pageTitle: 'Lastik Durumu Güncelle',
      breadcrumb: [
        {
          text: 'Lastik Durumları',
          to: '/tirestatuses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_STATUSES',
      action: 'read',
    },
  },
]
