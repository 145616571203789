import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketing_ad_forms: [],
    marketing_ad_formsCount: 0,
    marketing_ad_form: {},
    marketing_ad_formSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMarketing_ad_forms(state) {
      return state.marketing_ad_forms
    },
    getMarketing_ad_formsCount(state) {
      return state.marketing_ad_formsCount
    },
    getMarketing_ad_form(state) {
      return state.marketing_ad_form
    },
    getMarketing_ad_formSaveStatus(state) {
      return state.marketing_ad_formSave
    },
  },
  mutations: {
    MARKETING_AD_FORMS_LIST(state, data) {
      state.marketing_ad_forms = data
    },
    MARKETING_AD_FORM_VIEW(state, data) {
      state.marketing_ad_form = data
    },
    MARKETING_AD_FORMS_COUNT(state, data) {
      state.marketing_ad_formsCount = data
    },
    MARKETING_AD_FORM_SAVE(state, data) {
      state.marketing_ad_formSave = data
    },
  },
  actions: {
    marketing_ad_formsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/marketing/marketing_ad_forms', data)
        .then(response => {
          commit('MARKETING_AD_FORMS_LIST', response.data.data)
          commit('MARKETING_AD_FORMS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    marketing_ad_formView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_marketing_ad_forms.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/marketing/marketing_ad_forms', data)
        .then(response => {
          commit('MARKETING_AD_FORM_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    marketing_ad_formSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/marketing/marketing_ad_forms/saveData', data)
        .then(response => {
          commit('MARKETING_AD_FORM_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
