import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    completedSaleDeliveries: [],
    completedSaleDeliveriesCount: null,
    completedSaleDelivery: {},
    completedSaleDeliverySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCompletedSaleDeliveries(state) {
      return state.completedSaleDeliveries
    },
    getCompletedSaleDeliveriesCount(state) {
      return state.completedSaleDeliveriesCount
    },
    getCompletedSaleDelivery(state) {
      return state.completedSaleDelivery
    },
    getCompletedSaleDeliverySaveStatus(state) {
      return state.completedSaleDeliverySave
    },
  },
  mutations: {
    COMPLETED_SALE_DELIVERIES_LIST(state, data) {
      state.completedSaleDeliveries = data
    },
    COMPLETED_SALE_DELIVERIES_COUNT(state, data) {
      state.completedSaleDeliveriesCount = data
    },
    COMPLETED_SALE_DELIVERY_VIEW(state, data) {
      state.completedSaleDelivery = data
    },
    COMPLETED_SALE_DELIVERY_SAVE(state, data) {
      state.completedSaleDeliverySave = data
    },
  },
  actions: {
    completedSaleDeliveriesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/sale_delivery/completed', data)
        .then(response => {
          commit('COMPLETED_SALE_DELIVERIES_LIST', response.data.data)
          commit('COMPLETED_SALE_DELIVERIES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    completedSaleDeliveryView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_sale_delivery.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/sale_delivery/completed', data)
        .then(response => {
          commit('COMPLETED_SALE_DELIVERY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    closeDelivery({ commit }, id) {
      const data = JSON.stringify({ id })
      return axiosIns
        .post('/data/sale_delivery/closeDelivery', data)
        .then(response => {
          commit('COMPLETED_SALE_DELIVERY_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
