export default [
  {
    path: '/departments',
    name: 'Departments',
    component: () => import('@/views/Defines/Departments/Index.vue'),
    meta: {
      pageTitle: 'Departmanlar',
      breadcrumb: [
        {
          text: 'Departmanlar',
          active: true,
        },
      ],
      resource: 'DEFINES_DEPARTMENTS',
      action: 'read',
    },
  },
  {
    path: '/departments/add',
    name: 'DepartmentsAdd',
    component: () => import('@/views/Defines/Departments/Add.vue'),
    meta: {
      pageTitle: 'Departman Ekle',
      breadcrumb: [
        {
          text: 'Departmanlar',
          to: '/departments',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_DEPARTMENTS',
      action: 'read',
    },
  },
  {
    path: '/departments/edit/:id',
    name: 'DepartmentsEdit',
    component: () => import('@/views/Defines/Departments/Edit.vue'),
    meta: {
      pageTitle: 'Departman Güncelle',
      breadcrumb: [
        {
          text: 'Departmanlar',
          to: '/departments',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_DEPARTMENTS',
      action: 'read',
    },
  },
]
