export default [
  {
    path: '/portal/careers',
    name: 'PortalCareers',
    component: () => import('@/views/Portal/Career/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Personel İlanları',
          active: true,
        },
      ],
      resource: 'PORTAL_CAREERS_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/careers/add',
    name: 'PortalCareerAdd',
    component: () => import('@/views/Portal/Career/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Personel İlanları',
          to: '/portal/careers',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_CAREERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/careers/edit/:id',
    name: 'PortalCareerEdit',
    component: () => import('@/views/Portal/Career/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Personel İlanları',
          to: '/portal/careers',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_CAREERS_EDIT',
      action: 'read',
    },
  },
]
