export default [
  {
    path: '/user-section',
    name: 'UserSection',
    component: () => import('@/views/Defines/User_section/Index.vue'),
    meta: {
      pageTitle: 'Kullanıcı Bölümleri',
      breadcrumb: [
        {
          text: 'Kullanıcı Bölümleri',
          active: true,
        },
      ],
      resource: 'DEFINES_USER_SECTIONS',
      action: 'read',
    },
  },
  {
    path: '/user-section/add',
    name: 'UserSectionAdd',
    component: () => import('@/views/Defines/User_section/Add.vue'),
    meta: {
      pageTitle: 'İnallar Markası Ekle',
      breadcrumb: [
        {
          text: 'Kullanıcı Bölümleri',
          to: '/user-section',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_USER_SECTIONS',
      action: 'read',
    },
  },
  {
    path: '/user-section/edit/:id',
    name: 'UserSectionEdit',
    component: () => import('@/views/Defines/User_section/Edit.vue'),
    meta: {
      pageTitle: 'İnallar Markası Güncelle',
      breadcrumb: [
        {
          text: 'Kullanıcı Bölümleri',
          to: '/user-section',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_USER_SECTIONS',
      action: 'read',
    },
  },
]
