import moment from 'moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    inallar2Surveys: {
      sales: 0,
      surveys: 0,
      score: 0,
      others: 0,
    },
    requestCounts: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
    pendingServices: [],
    deliveredCarsCounts: {
      sales: 0,
      g2sales: 0,
    },
    pendingRequests: [],
    notServices: [],
    alerts: [],
    interviews: [],
    params: {
      sdate: `${moment().format('YYYY-MM')}-01`,
      edate: moment().format('YYYY-MM-DD'),
    },
  },
  getters: {
    getinallar2Surveys(state) {
      return state.inallar2Surveys
    },
    getRequestCounts(state) {
      return state.requestCounts
    },
    getPendingServices(state) {
      return state.pendingServices
    },
    getDeliveredCarsCounts(state) {
      return state.deliveredCarsCounts
    },
    getPendingRequests(state) {
      return state.pendingRequests
    },
    getNotServices(state) {
      return state.notServices
    },
    getAlerts(state) {
      return state.alerts
    },
    getInterviews(state) {
      return state.interviews
    },
    getParams(state) {
      return state.params
    },
  },
  mutations: {
    INALLAR2_SURVEYS(state, data) {
      state.inallar2Surveys = data
    },
    REQUEST_COUNTS(state, data) {
      state.requestCounts = data
    },
    PENDING_SERVICES(state, data) {
      state.pendingServices = data
    },
    DELIVERED_CARS_COUNTS(state, data) {
      state.deliveredCarsCounts = data
    },
    NOT_SERVICES(state, data) {
      state.notServices = data
    },
    PENDING_REQUESTS(state, data) {
      state.pendingRequests = data
    },
    ALERTS(state, data) {
      state.alerts = data
    },
    INTERVIEWS(state, data) {
      state.interviews = data
    },
  },
  actions: {
    inallar2Surveys({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dashboard/crm/dashboard/g2SurveysCounts', data)
        .then(response => {
          commit('INALLAR2_SURVEYS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    requestCounts({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dashboard/crm/dashboard/requestsCounts', data)
        .then(response => {
          commit('REQUEST_COUNTS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pendingServices({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dashboard/crm/dashboard/pendingServices', data)
        .then(response => {
          commit('PENDING_SERVICES', response.data.data)
        })
        .catch(error => console.error(error))
    },
    deliveredCarsCounts({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dashboard/crm/dashboard/deliveredCarsCounts', data)
        .then(response => {
          commit('DELIVERED_CARS_COUNTS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pendingRequests({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dashboard/crm/dashboard/pendingRequests', data)
        .then(response => {
          commit('PENDING_REQUESTS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    notServices({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dashboard/crm/dashboard/notServices', data)
        .then(response => {
          commit('NOT_SERVICES', response.data.data)
        })
        .catch(error => console.error(error))
    },
    alerts({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dashboard/crm/dashboard/alerts', data)
        .then(response => {
          commit('ALERTS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    interviews({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/dashboard/crm/dashboard/interviews', data)
        .then(response => {
          commit('INTERVIEWS', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
