export default [
  {
    path: '/marketing/birthday-sms',
    name: 'MarketingBirthdaySms',
    component: () => import('@/views/Marketing/Birthday_sms/Index.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Doğum Günü SMS',
          active: true,
        },
      ],
      resource: 'MARKETING_BIRTHDAY_SMS',
      action: 'read',
    },
  },
]
