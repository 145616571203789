export default [
  {
    path: '/rental/stocks',
    name: 'RentalStocks',
    component: () => import('@/views/Rental/Stocks/Index.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Stoklar',
          active: true,
        },
      ],
      resource: 'RENTAL_STOCKS',
      action: 'read',
    },
  },
  {
    path: '/rental/stocks/search',
    name: 'RentalStockSearch',
    component: () => import('@/views/Rental/Stocks/Search.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/rental/stocks',
          active: false,
        },
        {
          text: 'Bekleyen Stoklar',
          active: true,
        },
      ],
      resource: 'RENTAL_STOCKS',
      action: 'read',
    },
  },
  {
    path: '/rental/stocks/add/:arackodu',
    name: 'RentalStockAdd',
    component: () => import('@/views/Rental/Stocks/Add.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/rental/stocks',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'RENTAL_STOCKS',
      action: 'read',
    },
  },
  {
    path: '/rental/stocks/edit/:id',
    name: 'RentalStockEdit',
    component: () => import('@/views/Rental/Stocks/Edit.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/rental/stocks',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'RENTAL_STOCKS',
      action: 'read',
    },
  },
  {
    path: '/rental/stocks/view/:id',
    name: 'RentalStockView',
    component: () => import('@/views/Rental/Stocks/View.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/rental/stocks',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'RENTAL_STOCKS',
      action: 'read',
    },
  },
]
