export default [
  {
    path: '/reports/sales/sales-gpr',
    name: 'ReportsSalesGPR',
    component: () => import('@/views/Reports/Sales/SalesGPR.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Satış GPR',
          active: true,
        },
      ],
      resource: 'REPORTS_SALES_GPR',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/manager-gpr',
    name: 'ReportsSalesManagerGPR',
    component: () => import('@/views/Reports/Sales/ManagerGPR.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Yönetim GPR',
          active: true,
        },
      ],
      resource: 'REPORTS_SALES_MANAGER_GPR',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/digital',
    name: 'DigitalReport',
    component: () => import('@/views/Reports/Sales/DigitalReport.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Dijital Satışlar',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_DIGITAL',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/sale-users',
    name: 'ReportsSaleUsers',
    component: () => import('@/views/Reports/Sales/SaleUsers.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Temsilciler Raporu',
          active: true,
        },
      ],

      resource: 'REPORTS_SALE_USERS',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/accessories',
    name: 'ReportsSaleAccessories',
    component: () => import('@/views/Reports/Sales/Accessories.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Aksesuar Detay',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_ACCESSORIES',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/interviews',
    name: 'ReportsSaleInterviews',
    component: () => import('@/views/Reports/Sales/InterviewsReport.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Görüşmeler',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_INTERVIEWS',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/digital-response',
    name: 'DigitalResponseReport',
    component: () => import('@/views/Reports/Sales/DigitalResponse/Index.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Dijital Dönüş Süreleri',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_DIGITAL_RESPONSE',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/stocks',
    name: 'SalesStockReport',
    component: () => import('@/views/Reports/Sales/Stocks.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Sıfır Satış Stoklar',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_STOCKS',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/sales-by-city',
    name: 'SalesByCity',
    component: () => import('@/views/Reports/Sales/SalesByCity/Index.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'İl - İlçe Satışlar',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_BY_CITY',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/sale-invoices',
    name: 'SaleInvoices',
    component: () => import('@/views/Reports/Sales/SaleInvoices/Index.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Satış Faturaları',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_INVOICES',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/expertise',
    name: 'SalesExpertise',
    component: () => import('@/views/Reports/Sales/Expertise/Index.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Ön Fiyat - Ekspertiz Talepleri',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_EXPERTISE',
      action: 'read',
    },
  },
  {
    path: '/reports/sales/contracted-credits',
    name: 'SalesContractedCredits',
    component: () => import('@/views/Reports/Sales/ContractedCredits/Index.vue'),
    meta: {
      pageTitle: 'Sıfır Satış Raporları',
      breadcrumb: [
        {
          text: 'Anlaşmalı Banka Krediler',
          active: true,
        },
      ],
      resource: 'REPORTS_SALE_CONTRACTED_CREDITS',
      action: 'read',
    },
  },
]
