import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    interview_subjects: [],
    interview_subjectsCount: 0,
    interview_subject: {},
    interview_subjectSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getInterview_subjects(state) {
      return state.interview_subjects
    },
    getInterview_subjectsCount(state) {
      return state.interview_subjectsCount
    },
    getInterview_subject(state) {
      return state.interview_subject
    },
    getInterview_subjectSaveStatus(state) {
      return state.interview_subjectSave
    },
  },
  mutations: {
    INTERVIEW_SUBJECTS_LIST(state, data) {
      state.interview_subjects = data
    },
    INTERVIEW_SUBJECT_VIEW(state, data) {
      state.interview_subject = data
    },
    INTERVIEW_SUBJECTS_COUNT(state, data) {
      state.interview_subjectsCount = data
    },
    INTERVIEW_SUBJECT_SAVE(state, data) {
      state.interview_subjectSave = data
    },
  },
  actions: {
    interview_subjectsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/interview_subjects', data)
        .then(response => {
          commit('INTERVIEW_SUBJECTS_LIST', response.data.data)
          commit('INTERVIEW_SUBJECTS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    interview_subjectView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_interview_subject.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/interview_subjects', data)
        .then(response => {
          commit('INTERVIEW_SUBJECT_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    interview_subjectSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/interview_subjects/saveData', data)
        .then(response => {
          commit('INTERVIEW_SUBJECT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    interview_subjectDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/interview_subjects/deleteData', data)
        .then(response => {
          commit('INTERVIEW_SUBJECT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
