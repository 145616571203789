import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    payments: [],
    paymentsCount: 0,
    payment: {},
    paymentSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getPayments(state) {
      return state.payments
    },
    getPaymentsCount(state) {
      return state.paymentsCount
    },
    getPayment(state) {
      return state.payment
    },
    getPaymentSaveStatus(state) {
      return state.paymentSave
    },
  },
  mutations: {
    PAYMENTS_LIST(state, data) {
      state.payments = data
    },
    PAYMENT_VIEW(state, data) {
      state.payment = data
    },
    PAYMENTS_COUNT(state, data) {
      state.paymentsCount = data
    },
    PAYMENT_SAVE(state, data) {
      state.paymentSave = data
    },
  },
  actions: {
    paymentsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/payments', data)
        .then(response => {
          commit('PAYMENTS_LIST', response.data.data)
          commit('PAYMENTS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    paymentView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_payment.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/payments', data)
        .then(response => {
          commit('PAYMENT_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    paymentSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/payments/saveData', data)
        .then(response => {
          commit('PAYMENT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    paymentDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/payments/deleteData', data)
        .then(response => {
          commit('PAYMENT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
