export default [
  {
    path: '/kdvrates',
    name: 'KdvRates',
    component: () => import('@/views/Defines/Kdv_rates/Index.vue'),
    meta: {
      pageTitle: 'KDV Oranları',
      breadcrumb: [
        {
          text: 'KDV Oranları',
          active: true,
        },
      ],
      resource: 'DEFINES_KDV_RATES',
      action: 'read',
    },
  },
  {
    path: '/kdvrates/add',
    name: 'KdvRatesAdd',
    component: () => import('@/views/Defines/Kdv_rates/Add.vue'),
    meta: {
      pageTitle: 'KDV Oranı Ekle',
      breadcrumb: [
        {
          text: 'KDV Oranları',
          to: '/kdvrates',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_KDV_RATES',
      action: 'read',
    },
  },
  {
    path: '/kdvrates/edit/:id',
    name: 'KdvRatesEdit',
    component: () => import('@/views/Defines/Kdv_rates/Edit.vue'),
    meta: {
      pageTitle: 'KDV Oranı Güncelle',
      breadcrumb: [
        {
          text: 'KDV Oranları',
          to: '/kdvrates',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_KDV_RATES',
      action: 'read',
    },
  },
]
