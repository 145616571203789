import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    saveTokenResult: {
      id: null,
      status: null,
      message: null,
    },
  },
  getters: {
    saveTokenResult(state) {
      return state.saveTokenResult
    },
  },
  mutations: {
    SAVE_TOKEN_RESULT(state, data) {
      state.saveTokenResult = data
    },
  },
  actions: {
    saveToken({ commit }, params) {
      return axiosIns
        .post('/core/user_tokens', params)
        .then(response => {
          commit('SAVE_TOKEN_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
