import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: [],
    renewalStatus: [],
    users: [],
    filterData: {
      sdate: null,
      edate: null,
      id_com_user: null,
      id_com_insurance_renewal_periods: null,
    },
    loadingReport: null,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getRenewalStatus(state) {
      return state.renewalStatus
    },
    getUsers(state) {
      return state.users
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_RENEWAL_STATUS_DATA(state, data) {
      state.renewalStatus = data
    },
    SET_USER_DATA(state, data) {
      state.users = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/insurance/renewal', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_RENEWAL_STATUS_DATA', response.data.renewalStatus)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
    users({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/insurance/renewal/getUsers', data)
        .then(response => {
          commit('SET_USER_DATA', response.data.data)
        })
        .catch(error => console.log(error))
    },
  },
}
