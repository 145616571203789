export default [
  {
    path: '/interviews/sales',
    name: 'SalesInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/Sales.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Sıfır Satış',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/service-mechanical',
    name: 'ServiceMechanicalInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/ServiceMechanical.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Servis Mekanik',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/service-damage',
    name: 'ServiceDamageInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/ServiceDamage.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Servis Hasar',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/second-hand',
    name: 'SecondHandInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/SecondHand.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'İkinci El',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/insurance',
    name: 'InsuranceInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/Insurance.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Sigorta',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/rental',
    name: 'RentalInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/Rental.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Filo Kiralama',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/realty-sale',
    name: 'RealtySaleInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/RealtySales.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Gayrimenkul',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/customer-relationship',
    name: 'CRInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/CustomerRelationship.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Müşteri İlişkileri',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/spare-parts',
    name: 'SparePartsInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/SpareParts.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Yedek Parça',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
  {
    path: '/interviews/accessory-sales',
    name: 'AccessorySalesInterviewOpen',
    component: () => import('@/views/Interviews/Lists/Opens/AccessorySales.vue'),
    meta: {
      pageTitle: 'Görüşme Notları',
      breadcrumb: [
        {
          text: 'Tüm Görüşmeler',
          to: '/interviews',
          active: false,
        },
        {
          text: 'Aksesuar Satış',
          active: true,
        },
      ],
      resource: 'INTERVIEWS_LIST',
      action: 'read',
    },
  },
]
