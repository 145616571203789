import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: {
      data: [],
    },
    filterData: {
      sdate: moment().startOf('month').format('YYYY-MM-DD'),
      edate: moment().format('YYYY-MM-DD'),
      id_com_brand: null,
      id_com_department: null,
    },
    loadingReport: null,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
    crmCounts(state) {
      return state.crmCounts
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_USERS_DATA(state, data) {
      state.reportUsers = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
    CRM_COUNT(state, data) {
      state.crmCounts = data
    },
    SET_RESET_FILTER_DATA(state) {
      state.filterData = {
        sdate: moment().startOf('month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        id_com_brand: null,
        id_com_department: null,
      }
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/crm/UnreachableRequest', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data)
          commit('CRM_COUNT', response.data.count)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
