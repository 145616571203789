import axiosIns from '@/libs/axios'

const ROOT_URL = '/rentals/rental_expenses'
export default {
  namespaced: true,
  state: {
    dataList: [],
    grandTotal: null,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    grandTotal(state) {
      return state.grandTotal
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data.data
      state.grandTotal = data.grandTotal
    },
  },
  actions: {
    getDataList({ commit }, arackodu) {
      axiosIns
        .post(ROOT_URL, { arackodu })
        .then(response => {
          commit('SET_DATA_LIST', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
