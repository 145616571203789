import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    jobs: [],
    jobsCount: 0,
    job: {},
    jobSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getJobs(state) {
      return state.jobs
    },
    getJobsCount(state) {
      return state.jobsCount
    },
    getJob(state) {
      return state.job
    },
    getJobSaveStatus(state) {
      return state.jobSave
    },
  },
  mutations: {
    JOBS_LIST(state, data) {
      state.jobs = data
    },
    JOB_VIEW(state, data) {
      state.job = data
    },
    JOBS_COUNT(state, data) {
      state.jobsCount = data
    },
    JOB_SAVE(state, data) {
      state.jobSave = data
    },
  },
  actions: {
    jobsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/jobs', data)
        .then(response => {
          commit('JOBS_LIST', response.data.data)
          commit('JOBS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    jobView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_job.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/jobs', data)
        .then(response => {
          commit('JOB_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    jobSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/jobs/saveData', data)
        .then(response => {
          commit('JOB_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    jobDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/jobs/deleteData', data)
        .then(response => {
          commit('JOB_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
