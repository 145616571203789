export default [
  {
    path: '/insurance/renewal/periods',
    name: 'InsuranceRenewalPeridos',
    component: () => import('@/views/Insurance/Renewal/Periods/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Yenileme Listesi',
          to: '/insurance/renewal',
          active: false,
        },
        {
          text: 'Dönemler',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_PERIODS',
      action: 'read',
    },
  },
  {
    path: '/insurance/renewal/periods/view/:id',
    name: 'InsuranceRenewalPeridoView',
    component: () => import('@/views/Insurance/Renewal/Periods/View.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Yenileme Listesi',
          to: '/insurance/renewal',
          active: false,
        },
        {
          text: 'Dönemler',
          to: '/insurance/renewal/periods',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_PERIODS',
      action: 'read',
    },
  },
  {
    path: '/insurance/renewal/import',
    name: 'InsuranceRenewalImport',
    component: () => import('@/views/Insurance/Renewal/Import/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Yenileme Listesi',
          to: '/insurance/renewal/periods',
          active: false,
        },
        {
          text: 'Liste Aktarım',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_IMPORT',
      action: 'read',
    },
  },
  {
    path: '/insurance/renewal',
    name: 'InsuranceRenewals',
    component: () => import('@/views/Insurance/Renewal/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Yenileme Listesi',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_LIST',
      action: 'read',
    },
  },
]
