export default [
  {
    path: '/hr/notifications',
    name: 'HrNotifications',
    component: () => import('@/views/Hr/Notifications/Index.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'Duyurular',
          active: true,
        },
      ],
      resource: 'HR_NOTIFICATIONS_LIST',
      action: 'read',
    },
  },
  {
    path: '/hr/notifications/add',
    name: 'HrNotificationAdd',
    component: () => import('@/views/Hr/Notifications/Add.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'Duyurular',
          to: '/hr/notifications',
          active: false,
        },
        {
          text: 'Duyuru Oluştur',
          active: true,
        },
      ],
      resource: 'HR_NOTIFICATIONS_ADD',
      action: 'read',
    },
  },
  {
    path: '/hr/notifications/edit/:id',
    name: 'HrNotificationEdit',
    component: () => import('@/views/Hr/Notifications/Edit.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'Duyurular',
          to: '/hr/notifications',
          active: false,
        },
        {
          text: 'Duyuru Güncelle',
          active: true,
        },
      ],
      resource: 'HR_NOTIFICATIONS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/hr/notifications/view/:id',
    name: 'HrNotificationView',
    component: () => import('@/views/Hr/Notifications/View.vue'),
    meta: {
      pageTitle: 'İnsan Kaynakları',
      breadcrumb: [
        {
          text: 'Duyurular',
          to: '/hr/notifications',
          active: false,
        },
        {
          text: 'Duyuru Görüntüle',
          active: true,
        },
      ],
      resource: 'HR_NOTIFICATIONS_VIEW',
      action: 'read',
    },
  },
]
