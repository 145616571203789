import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    realty_sale_projects: [],
    realty_sale_projectsCount: 0,
    realty_sale_project: {
      id: null,
      title: null,
      status: null,
    },
    realty_sale_projectSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getRealty_sale_projects(state) {
      return state.realty_sale_projects
    },
    getRealty_sale_projectsCount(state) {
      return state.realty_sale_projectsCount
    },
    getRealty_sale_project(state) {
      return state.realty_sale_project
    },
    getRealty_sale_projectSaveStatus(state) {
      return state.realty_sale_projectSave
    },
  },
  mutations: {
    SET_REALTY_SALE_PROJECTS(state, data) {
      state.realty_sale_projects = data
    },
    SET_REALTY_SALE_PROJECT(state, data) {
      state.realty_sale_project = data
    },
    SET_REALTY_SALE_PROJECTS_COUNT(state, data) {
      state.realty_sale_projectsCount = data
    },
    SET_REALTY_SALE_PROJECT_SAVE(state, data) {
      state.realty_sale_projectSave = data
    },
  },
  actions: {
    realty_sale_projectsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/realty_projects', data)
        .then(response => {
          commit('SET_REALTY_SALE_PROJECTS', response.data.data)
          commit('SET_REALTY_SALE_PROJECTS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    realty_sale_projectView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_realty_project.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/realty_projects', data)
        .then(response => {
          commit('SET_REALTY_SALE_PROJECT', response.data.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_projectSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/realty_projects/saveData', data)
        .then(response => {
          commit('SET_REALTY_SALE_PROJECT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_projectDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/realty_projects/deleteData', data)
        .then(response => {
          commit('SET_REALTY_SALE_PROJECT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
