export default [
  {
    path: '/gears',
    name: 'Gears',
    component: () => import('@/views/Defines/Gears/Index.vue'),
    meta: {
      pageTitle: 'Vites Tipleri',
      breadcrumb: [
        {
          text: 'Vites Tipleri',
          active: true,
        },
      ],
      resource: 'DEFINES_GEARS',
      action: 'read',
    },
  },
  {
    path: '/gears/add',
    name: 'GearsAdd',
    component: () => import('@/views/Defines/Gears/Add.vue'),
    meta: {
      pageTitle: 'Vites Tipi Ekle',
      breadcrumb: [
        {
          text: 'Vites Tipleri',
          to: '/gears',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_GEARS',
      action: 'read',
    },
  },
  {
    path: '/gears/edit/:id',
    name: 'GearsEdit',
    component: () => import('@/views/Defines/Gears/Edit.vue'),
    meta: {
      pageTitle: 'Vites Tipi Güncelle',
      breadcrumb: [
        {
          text: 'Vites Tipleri',
          to: '/gears',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_GEARS',
      action: 'read',
    },
  },
]
