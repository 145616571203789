import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    abilities: [],
  },
  getters: {
    getAbilities(state) {
      return state.abilities
    },
  },
  mutations: {
    ABILITIES_LIST(state, data) {
      state.abilities = data
    },
  },
  actions: {
    abilitiesList({ commit }) {
      axiosIns
        .post('/core/abilities')
        .then(response => {
          commit('ABILITIES_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    abilitiesDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/core/abilities/getDataList', data)
        .then(response => {
          commit('ABILITIES_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
