export default [
  {
    path: '/crmstatuses',
    name: 'CrmStatuses',
    component: () => import('@/views/Defines/Crm_statuses/Index.vue'),
    meta: {
      pageTitle: 'Başvuru Durumları',
      breadcrumb: [
        {
          text: 'Başvuru Durumları',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_STATUSES',
      action: 'read',
    },
  },
  {
    path: '/crmstatuses/add',
    name: 'CrmStatusesAdd',
    component: () => import('@/views/Defines/Crm_statuses/Add.vue'),
    meta: {
      pageTitle: 'Başvuru Durumu Ekle',
      breadcrumb: [
        {
          text: 'Başvuru Durumları',
          to: '/crmstatuses',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_STATUSES',
      action: 'read',
    },
  },
  {
    path: '/crmstatuses/edit/:id',
    name: 'CrmStatusesEdit',
    component: () => import('@/views/Defines/Crm_statuses/Edit.vue'),
    meta: {
      pageTitle: 'Başvuru Durumu Güncelle',
      breadcrumb: [
        {
          text: 'Başvuru Durumları',
          to: '/crmstatuses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_CRM_STATUSES',
      action: 'read',
    },
  },
]
