import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    bulletin_types: [],
    bulletin_typesCount: 0,
    bulletin_type: {},
    bulletin_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getBulletin_types(state) {
      return state.bulletin_types
    },
    getBulletin_typesCount(state) {
      return state.bulletin_typesCount
    },
    getBulletin_type(state) {
      return state.bulletin_type
    },
    getBulletin_typeSaveStatus(state) {
      return state.bulletin_typeSave
    },
  },
  mutations: {
    BULLETIN_TYPES_LIST(state, data) {
      state.bulletin_types = data
    },
    BULLETIN_TYPE_VIEW(state, data) {
      state.bulletin_type = data
    },
    BULLETIN_TYPES_COUNT(state, data) {
      state.bulletin_typesCount = data
    },
    BULLETIN_TYPE_SAVE(state, data) {
      state.bulletin_typeSave = data
    },
  },
  actions: {
    bulletin_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/bulletin_types', data)
        .then(response => {
          commit('BULLETIN_TYPES_LIST', response.data.data)
          commit('BULLETIN_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    bulletin_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_bulletin_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/bulletin_types', data)
        .then(response => {
          commit('BULLETIN_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    bulletin_typeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/bulletin_types/saveData', data)
        .then(response => {
          commit('BULLETIN_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
