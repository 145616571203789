import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    g2Stocks: [],
    g2StocksCount: 0,
    g2Stock: {},
    g2StockSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getG2Stocks(state) {
      return state.g2Stocks
    },
    getG2StocksCount(state) {
      return state.g2StocksCount
    },
    getG2Stock(state) {
      return state.g2Stock
    },
    getG2StockSaveStatus(state) {
      return state.g2StockSave
    },
  },
  mutations: {
    SET_G2STOCKS(state, data) {
      state.g2Stocks = data
    },
    SET_G2STOCK(state, data) {
      state.g2Stock = data
    },
    SET_G2STOCK_COUNT(state, data) {
      state.g2StocksCount = data
    },
    SET_G2STOCK_SAVE(state, data) {
      state.g2StockSave = data
    },
  },
  actions: {
    g2Stocks({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/g2stock', data)
        .then(response => {
          commit('SET_G2STOCKS', response.data.data)
          commit('SET_G2STOCK_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    g2Stock({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_g2stock.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/g2stock', data)
        .then(response => {
          commit('SET_G2STOCK', response.data.data)
        })
        .catch(error => console.error(error))
    },
    g2StockSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/g2stock/saveData', data)
        .then(response => {
          commit('SET_G2STOCK_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
