export default [
  {
    path: '/swapbrands',
    name: 'Swapbrands',
    component: () => import('@/views/Defines/Swapbrands/Index.vue'),
    meta: {
      pageTitle: 'Markalar',
      breadcrumb: [
        {
          text: 'Araç Markaları',
          active: true,
        },
      ],
      resource: 'DEFINES_SWAP_BRANDS',
      action: 'read',
    },
  },
  {
    path: '/swapbrands/add',
    name: 'SwapbrandsAdd',
    component: () => import('@/views/Defines/Swapbrands/Add.vue'),
    meta: {
      pageTitle: 'Araç Markası Ekle',
      breadcrumb: [
        {
          text: 'Araç Markaları',
          to: '/swapbrands',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_SWAP_BRANDS',
      action: 'read',
    },
  },
  {
    path: '/swapbrands/edit/:id',
    name: 'UserTypesEdit',
    component: () => import('@/views/Defines/Swapbrands/Edit.vue'),
    meta: {
      pageTitle: 'Araç Markası Güncelle',
      breadcrumb: [
        {
          text: 'Araç Markaları',
          to: '/swapbrands',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_SWAP_BRANDS',
      action: 'read',
    },
  },
]
