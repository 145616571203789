import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    quote_of_days: [],
    quote_of_daysCount: 0,
    quote_of_day: {
      id: null,
      name: null,
      content: null,
      submitStatus: false,
    },
    quote_of_daySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getQuote_of_days(state) {
      return state.quote_of_days
    },
    getQuote_of_daysCount(state) {
      return state.quote_of_daysCount
    },
    getQuote_of_day(state) {
      return state.quote_of_day
    },
    getQuote_of_daySaveStatus(state) {
      return state.quote_of_daySave
    },
  },
  mutations: {
    QUOTE_OF_DAYS_LIST(state, data) {
      state.quote_of_days = data
    },
    QUOTE_OF_DAY_VIEW(state, data) {
      state.quote_of_day = data
    },
    QUOTE_OF_DAYS_COUNT(state, data) {
      state.quote_of_daysCount = data
    },
    QUOTE_OF_DAY_SAVE(state, data) {
      state.quote_of_daySave = data
    },
    QUOTE_OF_DAY_RESET(state, data) {
      if (data.status) {
        state.quote_of_day = {
          id: null,
          name: null,
          content: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    quote_of_daysList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/quote_of_days', data)
        .then(response => {
          commit('QUOTE_OF_DAYS_LIST', response.data.data)
          commit('QUOTE_OF_DAYS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    quote_of_dayView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_quote_of_days.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/quote_of_days', data)
        .then(response => {
          commit('QUOTE_OF_DAY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    quote_of_daySave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/portal/quote_of_days/saveData', data)
        .then(response => {
          commit('QUOTE_OF_DAY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    quote_of_dayReset({ commit }) {
      commit('QUOTE_OF_DAY_RESET', { status: true })
    },
  },
}
