export default [
  {
    path: '/servicetypes',
    name: 'ServiceTypes',
    component: () => import('@/views/Defines/Service_types/Index.vue'),
    meta: {
      pageTitle: 'Servis Tipleri',
      breadcrumb: [
        {
          text: 'Servis Tipleri',
          active: true,
        },
      ],
      resource: 'DEFINES_SERVICE_TYPES',
      action: 'read',
    },
  },
  {
    path: '/servicetypes/add',
    name: 'ServiceTypesAdd',
    component: () => import('@/views/Defines/Service_types/Add.vue'),
    meta: {
      pageTitle: 'Servis Tipi Ekle',
      breadcrumb: [
        {
          text: 'Servis Tipleri',
          to: '/servicetypes',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_SERVICE_TYPES',
      action: 'read',
    },
  },
  {
    path: '/servicetypes/edit/:id',
    name: 'ServiceTypesEdit',
    component: () => import('@/views/Defines/Service_types/Edit.vue'),
    meta: {
      pageTitle: 'Servis Tipi Güncelle',
      breadcrumb: [
        {
          text: 'Servis Tipleri',
          to: '/servicetypes',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_SERVICE_TYPES',
      action: 'read',
    },
  },
]
