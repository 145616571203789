import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    fixedEducationTypes: [],
    fixedEducationTypesCount: 0,
    fixedEducationType: {
      id: null,
      title: null,
      content: null,
      status: null,
      edate: null,
      submitStatus: false,
    },
    fixedEducationTypeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getFixedEducationTypes(state) {
      return state.fixedEducationTypes
    },
    getFixedEducationTypesCount(state) {
      return state.fixedEducationTypesCount
    },
    getFixedEducationType(state) {
      return state.fixedEducationType
    },
    getFixedEducationTypeSaveStatus(state) {
      return state.fixedEducationTypeSave
    },
  },
  mutations: {
    FIXED_EDUCATION_TYPES_LIST(state, data) {
      state.fixedEducationTypes = data
    },
    FIXED_EDUCATION_TYPE_VIEW(state, data) {
      state.fixedEducationType = data
    },
    FIXED_EDUCATION_TYPES_COUNT(state, data) {
      state.fixedEducationTypesCount = data
    },
    FIXED_EDUCATION_TYPE_SAVE(state, data) {
      state.fixedEducationTypeSave = data
    },
    FIXED_EDUCATION_TYPE_RESET(state, data) {
      if (data.status) {
        state.fixedEducationType = {
          id: null,
          title: null,
          content: null,
          status: null,
          edate: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    fixedEducationTypesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/fixed_education_types', data)
        .then(response => {
          commit('FIXED_EDUCATION_TYPES_LIST', response.data.data)
          commit('FIXED_EDUCATION_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    fixedEducationTypeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_fixed_education_types.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/fixed_education_types', data)
        .then(response => {
          commit('FIXED_EDUCATION_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    fixedEducationTypeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/portal/fixed_education_types/saveData', data)
        .then(response => {
          commit('FIXED_EDUCATION_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    fixedEducationTypeReset({ commit }) {
      commit('FIXED_EDUCATION_TYPE_RESET', { status: true })
    },
  },
}
