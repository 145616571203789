export default [
  {
    path: '/tiresizes',
    name: 'TireSizes',
    component: () => import('@/views/Defines/Tire_sizes/Index.vue'),
    meta: {
      pageTitle: 'Lastik Boyutları',
      breadcrumb: [
        {
          text: 'Lastik Boyutları',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_SIZES',
      action: 'read',
    },
  },
  {
    path: '/tiresizes/add',
    name: 'TireSizesAdd',
    component: () => import('@/views/Defines/Tire_sizes/Add.vue'),
    meta: {
      pageTitle: 'Lastik Boyutu Ekle',
      breadcrumb: [
        {
          text: 'Lastik Boyutları',
          to: '/tiresizes',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_SIZES',
      action: 'read',
    },
  },
  {
    path: '/tiresizes/edit/:id',
    name: 'TireSizesEdit',
    component: () => import('@/views/Defines/Tire_sizes/Edit.vue'),
    meta: {
      pageTitle: 'Lastik Boyutu Güncelle',
      breadcrumb: [
        {
          text: 'Lastik Boyutları',
          to: '/tiresizes',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_TIRE_SIZES',
      action: 'read',
    },
  },
]
