import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    gears: [],
    gearsCount: 0,
    gear: {},
    gearSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getGears(state) {
      return state.gears
    },
    getGearsCount(state) {
      return state.gearsCount
    },
    getGear(state) {
      return state.gear
    },
    getGearSaveStatus(state) {
      return state.gearSave
    },
  },
  mutations: {
    GEARS_LIST(state, data) {
      state.gears = data
    },
    GEAR_VIEW(state, data) {
      state.gear = data
    },
    GEARS_COUNT(state, data) {
      state.gearsCount = data
    },
    GEAR_SAVE(state, data) {
      state.gearSave = data
    },
  },
  actions: {
    gearsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/gears', data)
        .then(response => {
          commit('GEARS_LIST', response.data.data)
          commit('GEARS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    gearView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_gear.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/gears', data)
        .then(response => {
          commit('GEAR_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    gearSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/gears/saveData', data)
        .then(response => {
          commit('GEAR_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    gearDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/gears/deleteData', data)
        .then(response => {
          commit('GEAR_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
