export default [
  {
    path: '/portal/documents',
    name: 'PortalDocuments',
    component: () => import('@/views/Portal/Documents/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Doküman Arşivi',
          active: true,
        },
      ],
      resource: 'PORTAL_DOCUMENTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/documents/add',
    name: 'PortalDocumentAdd',
    component: () => import('@/views/Portal/Documents/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Doküman Arşivi',
          to: '/portal/documents',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_DOCUMENTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/documents/edit/:id',
    name: 'PortalDocumentEdit',
    component: () => import('@/views/Portal/Documents/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Doküman Arşivi',
          to: '/portal/documents',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_DOCUMENTS_EDIT',
      action: 'read',
    },
  },
]
