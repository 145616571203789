import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      decline_text: null,
      request: null,
      id_com_sale_orderform: null,
      status: null,
      id_com_user: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('data/sale_orderform_cancel_pending', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_sale_orderform_delete_pending.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('data/sale_orderform_cancel_pending', data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data.id
        })
        .catch(error => console.error(error))
    },
    getDataItemForm({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_sale_orderform_delete_pending.id_com_sale_orderform': id,
          'com_sale_orderform_delete_pending.status !=': 3,
        },
        array: false,
      })
      return axiosIns
        .post('data/sale_orderform_cancel_pending', data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    sendRequest({ commit }, data) {
      return axiosIns
        .post('data/sale_orderform_cancel_pending/sendRequest', data)
        .then(response => response.data)
        .catch(error => console.error(error))
    },
    acceptRequest({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('data/sale_orderform_cancel_pending/acceptRequest', data)
        .then(response => response.data)
        .catch(error => console.error(error))
    },
    declineRequest({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('data/sale_orderform_cancel_pending/declineRequest', data)
        .then(response => response.data)
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('data/sale_orderform_cancel_pending/saveData', data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post('data/sale_orderform_cancel_pending/deleteData', { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
