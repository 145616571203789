import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    Sales: [],
    SalesCount: 0,
    Sale: {},
    SaleSave: {
      message: null,
      status: null,
      id: null,
    },
    modalData: {
      status: false,
    },
    CarCard: [],
  },
  getters: {
    getSales(state) {
      return state.Sales
    },
    getSalesCount(state) {
      return state.SalesCount
    },
    getSale(state) {
      return state.Sale
    },
    getSaleSaveStatus(state) {
      return state.SaleSave
    },
    getCarCard(state) {
      return state.CarCard
    },
    getCardInterview(state) {
      return state.interview
    },
    getModalStatus(state) {
      return state.modalData
    },
  },
  mutations: {
    SET_SALES(state, data) {
      state.Sales = data
    },
    SET_SALE(state, data) {
      state.Sale = data
    },
    SET_SALES_COUNT(state, data) {
      state.SalesCount = data
    },
    SET_SALE_SAVE(state, data) {
      state.SaleSave = data
    },
    SET_CARCARD(state, data) {
      state.CarCard = data
    },
    SET_CARINTERVIEW(state, data) {
      state.interview = data
    },
  },
  actions: {
    Sales({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/SaleSurvey', data)
        .then(response => {
          commit('SET_SALES', response.data.data)
          commit('SET_SALES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    Sale({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_sale_orderform.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/SaleSurvey', data)
        .then(response => {
          commit('SET_SALE', response.data.data)
        })
        .catch(error => console.error(error))
    },
    SaleSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/SaleSurvey/saveData', data)
        .then(response => {
          commit('SET_SALE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    setCarCards({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/data/SaleSurvey/setCarCard', data)
        .then(response => {
          commit('SET_CARCARD', response.data.customer)
          commit('SET_CARINTERVIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
  },
}
