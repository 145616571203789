import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: null,
    dataItem: {
      id: null,
      title: null,
      id_com_user: null,
    },
    saveData: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    getData(state) {
      return state.dataItem
    },
    saveDataStatus(state) {
      return state.saveData
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_ITEM_DATA(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SAVE_DATA_STATUS(state, data) {
      state.saveData = data
    },
    SET_DATA_RESET(state) {
      state.dataItem = {
        id: null,
        title: null,
        id_com_user: null,
      }
    },
  },
  actions: {
    dataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/permit_request_type', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    dataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_permit_request_type.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/permit_request_type', data)
        .then(response => {
          commit('SET_ITEM_DATA', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/permit_request_type/saveData', data)
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post('/defines/permit_request_type/deleteData', { id })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
