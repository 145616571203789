export default [
  {
    path: '/banks',
    name: 'Banks',
    component: () => import('@/views/Defines/Banks/Index.vue'),
    meta: {
      pageTitle: 'Bankalar',
      breadcrumb: [
        {
          text: 'Bankalar',
          active: true,
        },
      ],
      resource: 'DEFINES_BANKS',
      action: 'read',
    },
  },
  {
    path: '/banks/add',
    name: 'BanksAdd',
    component: () => import('@/views/Defines/Banks/Add.vue'),
    meta: {
      pageTitle: 'Banka Ekle',
      breadcrumb: [
        {
          text: 'Bankalar',
          to: '/banks',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_BANKS',
      action: 'read',
    },
  },
  {
    path: '/banks/edit/:id',
    name: 'BanksEdit',
    component: () => import('@/views/Defines/Banks/Edit.vue'),
    meta: {
      pageTitle: 'Banka Güncelle',
      breadcrumb: [
        {
          text: 'Bankalar',
          to: '/banks',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_BANKS',
      action: 'read',
    },
  },
]
