import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    hr_notification_types: [],
    hr_notification_typesCount: 0,
    hr_notification_type: {},
    hr_notification_typeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getHr_notification_types(state) {
      return state.hr_notification_types
    },
    getHr_notification_typesCount(state) {
      return state.hr_notification_typesCount
    },
    getHr_notification_type(state) {
      return state.hr_notification_type
    },
    getHr_notification_typeSaveStatus(state) {
      return state.hr_notification_typeSave
    },
  },
  mutations: {
    HR_NOTIFICATION_TYPES_LIST(state, data) {
      state.hr_notification_types = data
    },
    HR_NOTIFICATION_TYPE_VIEW(state, data) {
      state.hr_notification_type = data
    },
    HR_NOTIFICATION_TYPES_COUNT(state, data) {
      state.hr_notification_typesCount = data
    },
    HR_NOTIFICATION_TYPE_SAVE(state, data) {
      state.hr_notification_typeSave = data
    },
  },
  actions: {
    hr_notification_typesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/hr_notification_types', data)
        .then(response => {
          commit('HR_NOTIFICATION_TYPES_LIST', response.data.data)
          commit('HR_NOTIFICATION_TYPES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    hr_notification_typeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_hr_notification_types.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/hr_notification_types', data)
        .then(response => {
          commit('HR_NOTIFICATION_TYPE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    hr_notification_typeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/hr_notification_types/saveData', data)
        .then(response => {
          commit('HR_NOTIFICATION_TYPE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
