import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    departments: [],
    departmentsCount: 0,
    department: {},
    departmentSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDepartments(state) {
      return state.departments
    },
    getDepartmentsCount(state) {
      return state.departmentsCount
    },
    getDepartment(state) {
      return state.department
    },
    getDepartmentSaveStatus(state) {
      return state.departmentSave
    },
  },
  mutations: {
    DEPARTMENTS_LIST(state, data) {
      state.departments = data
    },
    DEPARTMENT_VIEW(state, data) {
      state.department = data
    },
    DEPARTMENTS_COUNT(state, data) {
      state.departmentsCount = data
    },
    DEPARTMENT_SAVE(state, data) {
      state.departmentSave = data
    },
  },
  actions: {
    departmentsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/departments', data)
        .then(response => {
          commit('DEPARTMENTS_LIST', response.data.data)
          commit('DEPARTMENTS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    departmentView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_department.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/departments', data)
        .then(response => {
          commit('DEPARTMENT_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    departmentSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/departments/saveData', data)
        .then(response => {
          commit('DEPARTMENT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    departmentDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/departments/deleteData', data)
        .then(response => {
          commit('DEPARTMENT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
