import axiosIns from '@/libs/axios'

const ROOT_URL = '/dashboard/common/customers'
export default {
  namespaced: true,
  state: {
    dashboard: {
      label: 'Müşteriler',
      icon: '',
      variant: 'light-primary',
      users: null,
      totals: null,
    },
    loading: false,
  },
  getters: {
    dashboard(state) {
      return state.dashboard
    },
    loading(state) {
      return state.loading
    },
  },
  mutations: {
    SET_DASHBOARD(state, data) {
      state.dashboard = data
    },
    SET_LOADING(state, data) {
      state.loading = data
    },
    RESET_DASHBOARD(state) {
      state.dashboard = {
        label: null,
        icon: null,
        variant: null,
        users: null,
        totals: null,
      }
    },
  },
  actions: {
    getData({ commit }, params) {
      commit('SET_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DASHBOARD', response.data)
          commit('SET_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
