import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    meets: [],
    meetsCount: 0,
    meet: {},
    meetSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMeets(state) {
      return state.meets
    },
    getMeetsCount(state) {
      return state.meetsCount
    },
    getMeet(state) {
      return state.meet
    },
    getMeetSaveStatus(state) {
      return state.meetSave
    },
  },
  mutations: {
    MEETS_LIST(state, data) {
      state.meets = data
    },
    MEET_VIEW(state, data) {
      state.meet = data
    },
    MEETS_COUNT(state, data) {
      state.meetsCount = data
    },
    MEET_SAVE(state, data) {
      state.meetSave = data
    },
  },
  actions: {
    meetsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/meets', data)
        .then(response => {
          commit('MEETS_LIST', response.data.data)
          commit('MEETS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    meetView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_meet.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/meets', data)
        .then(response => {
          commit('MEET_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    meetSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/meets/saveData', data)
        .then(response => {
          commit('MEET_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    meetDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/meets/deleteData', data)
        .then(response => {
          commit('MEET_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
