export default [
  {
    path: '/manager-tools/customer-deduplication',
    name: 'CustomerDeduplication',
    component: () => import('@/views/ManagerTools/CustomerDeduplication.vue'),
    meta: {
      pageTitle: 'Yönetici Araçları',
      breadcrumb: [
        {
          text: 'Yönetici Araçları',
        },
        {
          text: 'Müşteri Tekilleştirme / Aktarım',
          active: true,
        },
      ],
      resource: 'MANAGER_CUSTOMER_DEDUPLICATION',
      action: 'read',
    },
  },
  {
    path: '/manager-tools/user-operations',
    name: 'UserOperations',
    component: () => import('@/views/ManagerTools/UserOperations.vue'),
    meta: {
      pageTitle: 'Yönetici Araçları',
      breadcrumb: [
        {
          text: 'Yönetici Araçları',
        },
        {
          text: 'Kullanıcı İşlemleri',
          active: true,
        },
      ],
      resource: 'MANAGER_USER_OPERATIONS',
      action: 'read',
    },
  },
]
