import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tireShelfs: [],
    tireShelfsCount: 0,
    tireShelf: {},
    tireShelfSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getTireShelfs(state) {
      return state.tireShelfs
    },
    getTireShelfsCount(state) {
      return state.tireShelfsCount
    },
    getTireShelf(state) {
      return state.tireShelf
    },
    getTireShelfSaveStatus(state) {
      return state.tireShelfSave
    },
  },
  mutations: {
    TIRE_SHELFS_LIST(state, data) {
      state.tireShelfs = data
    },
    TIRE_SHELF_VIEW(state, data) {
      state.tireShelf = data
    },
    TIRE_SHELFS_COUNT(state, data) {
      state.tireShelfsCount = data
    },
    TIRE_SHELF_SAVE(state, data) {
      state.tireShelfSave = data
    },
  },
  actions: {
    tireShelfsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/tire_shelfs', data)
        .then(response => {
          commit('TIRE_SHELFS_LIST', response.data.data)
          commit('TIRE_SHELFS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    tireShelfView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_tire_shelf.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/tire_shelfs', data)
        .then(response => {
          commit('TIRE_SHELF_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    tireShelfSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/tire_shelfs/saveData', data)
        .then(response => {
          commit('TIRE_SHELF_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    tireShelfDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/tire_shelfs/deleteData', data)
        .then(response => {
          commit('TIRE_SHELF_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
