import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    purchasing_orders: [],
    purchasing_order: {},
    purchasing_orders_count: 0,
    purchasing_order_save: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getPurchasingOrders(state) {
      return state.purchasing_orders
    },
    getPurchasingOrder(state) {
      return state.purchasing_order
    },
    getPurchasingOrdersCount(state) {
      return state.purchasing_orders_count
    },
    getPurchasingOrderSave(state) {
      return state.purchasing_order_save
    },
  },
  mutations: {
    PURCHASING_ORDERS_LIST(state, data) {
      state.purchasing_orders = data
    },
    PURCHASING_ORDERS_COUNT(state, data) {
      state.purchasing_orders_count = data
    },
    PURCHASING_ORDER_VIEW(state, data) {
      state.purchasing_order = data
    },
    PURCHASING_ORDER_SAVE(state, data) {
      state.purchasing_order_save = data
    },
  },
  actions: {
    purchasingOrders({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/purchasing/orders', data)
        .then(response => {
          commit('PURCHASING_ORDERS_LIST', response.data.data)
          commit('PURCHASING_ORDERS_COUNT', response.data.count)
        })
    },
    purchasingOrderView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_purchasing_orders.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/purchasing/orders', data)
        .then(response => {
          commit('PURCHASING_ORDER_VIEW', response.data.data)
        })
    },
    purchasingOrdersSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/purchasing/orders/saveData', data)
        .then(response => {
          commit('PURCHASING_ORDER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
