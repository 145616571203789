export default [
  {
    path: '/webforms/digital-response',
    name: 'DigitalResponse',
    component: () => import('@/views/Webforms/DigitalResponse/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'Dijital Dönüş Süreleri',
          active: true,
        },
      ],
      resource: 'WEBFORM_CONTACT',
      action: 'read',
    },
  },
]
