import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    filterParams: {
      sdate: `${moment().format('YYYY-MM')}-01`,
      edate: moment().format('YYYY-MM-DD'),
    },
    sales: [],
    accessories: [],
    testdrives: [],
    insurances: [],
    swaps: [],
    interviews: [],
    userSalesDetail: [],
  },
  getters: {
    filterParams(state) {
      return state.filterParams
    },
    getSales(state) {
      return state.sales
    },
    getUserSalesDetail(state) {
      return state.userSalesDetail
    },
    getAccessories(state) {
      return state.accessories
    },
    getTestDrives(state) {
      return state.testdrives
    },
    getInsurances(state) {
      return state.insurances
    },
    getSwaps(state) {
      return state.swaps
    },
    getInterviews(state) {
      return state.interviews
    },
  },
  mutations: {
    SALES(state, data) {
      state.sales = data
    },
    USER_SALES_DETAIL(state, data) {
      state.userSalesDetail = data
    },
    ACCESSORIES(state, data) {
      state.accessories = data
    },
    TEST_DRIVES(state, data) {
      state.testdrives = data
    },
    INSURANCES(state, data) {
      state.insurances = data
    },
    SWAPS(state, data) {
      state.swaps = data
    },
    INTERVIEWS(state, data) {
      state.interviews = data
    },
  },
  actions: {
    sales({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/sales/sales_gpr', data)
        .then(response => {
          commit('SALES', response.data.sales)
        })
        .catch(error => console.error(error))
    },
    userSalesDetail({ commit }, params) {
      return axiosIns
        .post('/reports/sales/sales_gpr/getUserSales', params)
        .then(response => {
          commit('USER_SALES_DETAIL', response.data.users)
          return response.data.users
        })
        .catch(error => console.error(error))
    },
    accessories({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/sales/sales_gpr/accessories', data)
        .then(response => {
          commit('ACCESSORIES', response.data.accessories)
        })
        .catch(error => console.error(error))
    },
    testDrives({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/sales/sales_gpr/testDrives', data)
        .then(response => {
          commit('TEST_DRIVES', response.data.testDrives)
        })
        .catch(error => console.error(error))
    },
    insurances({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/sales/sales_gpr/insurances', data)
        .then(response => {
          commit('INSURANCES', response.data.insurances)
        })
        .catch(error => console.error(error))
    },
    swaps({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/sales/sales_gpr/swaps', data)
        .then(response => {
          commit('SWAPS', response.data.swaps)
        })
        .catch(error => console.error(error))
    },
    interviews({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/sales/sales_gpr/interviews', data)
        .then(response => {
          commit('INTERVIEWS', response.data.interviews)
        })
        .catch(error => console.error(error))
    },
  },
}
