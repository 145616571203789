import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    realty_sale_numbers: [],
    realty_sale_available_numbers: [],
    realty_sale_numbersCount: 0,
    realty_sale_number: {
      id: null,
      number: null,
      id_com_realty_block: null,
      id_com_realty_type: null,
      id_com_realty_project: null,
      price: null,
      floor: null,
    },
    realty_sale_numberSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getRealty_sale_numbers(state) {
      return state.realty_sale_numbers
    },
    getRealty_sale_available_numbers(state) {
      return state.realty_sale_available_numbers
    },
    getRealty_sale_numbersCount(state) {
      return state.realty_sale_numbersCount
    },
    getRealty_sale_number(state) {
      return state.realty_sale_number
    },
    getRealty_sale_numberSaveStatus(state) {
      return state.realty_sale_numberSave
    },
  },
  mutations: {
    SET_REALTY_SALE_NUMBERS(state, data) {
      state.realty_sale_numbers = data
    },
    SET_REALTY_SALE_AVAILABLE_NUMBERS(state, data) {
      state.realty_sale_available_numbers = data
    },
    SET_REALTY_SALE_NUMBER(state, data) {
      state.realty_sale_number = data
    },
    SET_REALTY_SALE_NUMBERS_COUNT(state, data) {
      state.realty_sale_numbersCount = data
    },
    SET_REALTY_SALE_NUMBER_SAVE(state, data) {
      state.realty_sale_numberSave = data
    },
  },
  actions: {
    realty_sale_numbersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/realty_numbers', data)
        .then(response => {
          commit('SET_REALTY_SALE_NUMBERS', response.data.data)
          commit('SET_REALTY_SALE_NUMBERS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    realty_sale_available_numbersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/realty_numbers/availableNumbers', data)
        .then(response => {
          commit('SET_REALTY_SALE_AVAILABLE_NUMBERS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_numberView({ commit }, id) {
      const data = JSON.stringify({
        select: [
          'com_realty_number.id AS id',
          'com_realty_number.number AS number',
          'com_realty_number.id_com_realty_block AS id_com_realty_block',
          'com_realty_number.id_com_realty_type AS id_com_realty_type',
          'com_realty_number.id_com_realty_project AS id_com_realty_project',
          'com_realty_number.price AS price',
          'com_realty_number.floor AS floor',
        ],
        where: {
          'com_realty_number.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/realty_numbers', data)
        .then(response => {
          commit('SET_REALTY_SALE_NUMBER', response.data.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_numberSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/realty_numbers/saveData', data)
        .then(response => {
          commit('SET_REALTY_SALE_NUMBER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_numberMultiple({ commit }, postData) {
      // const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/realty_numbers/multipleSave', postData)
        .then(response => {
          commit('SET_REALTY_SALE_NUMBER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_numberDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/data/realty_numbers/deleteData', data)
        .then(response => {
          commit('SET_REALTY_SALE_NUMBER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
