export default [
  {
    path: '/smtp',
    name: 'SMTP',
    component: () => import('@/views/Core/Smtp/Index.vue'),
    meta: {
      pageTitle: 'SMTP Ayarları',
      breadcrumb: [
        {
          text: 'SMTP Ayarları',
          active: true,
        },
      ],
      resource: 'SMTP_CONFIG',
      action: 'read',
    },
  },
]
