import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    realty_sale_statuses: [],
    realty_sale_statusesCount: 0,
    realty_sale_status: {},
    realty_sale_statusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getRealty_sale_statuses(state) {
      return state.realty_sale_statuses
    },
    getRealty_sale_statusesCount(state) {
      return state.realty_sale_statusesCount
    },
    getRealty_sale_status(state) {
      return state.realty_sale_status
    },
    getRealty_sale_statusSaveStatus(state) {
      return state.realty_sale_statusSave
    },
  },
  mutations: {
    SET_REALTY_SALE_STATUSES(state, data) {
      state.realty_sale_statuses = data
    },
    SET_REALTY_SALE_STATUS(state, data) {
      state.realty_sale_status = data
    },
    SET_REALTY_SALE_STATUSES_COUNT(state, data) {
      state.realty_sale_statusesCount = data
    },
    SET_REALTY_SALE_STATUS_SAVE(state, data) {
      state.realty_sale_statusSave = data
    },
  },
  actions: {
    realty_sale_statusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/realty_sale_statuses', data)
        .then(response => {
          commit('SET_REALTY_SALE_STATUSES', response.data.data)
          commit('SET_REALTY_SALE_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    realty_sale_statusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_realty_sale_status.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/realty_sale_statuses', data)
        .then(response => {
          commit('SET_REALTY_SALE_STATUS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    realty_sale_statusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/realty_sale_statuses/saveData', data)
        .then(response => {
          commit('SET_REALTY_SALE_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
