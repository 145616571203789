import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    offerLines: [],
    offerLinesCount: 0,
    offerLine: {
      id: null,
      model: null,
      image: null,
      myear: null,
      car: null,
      priceCar: null,
      exterior_color: null,
      interior_color: null,
      listed_price: null,
      offer_price: null,
      price_text: null,
      id_com_offer: null,
      id_com_brand: null,
      id_com_model: null,
      id_com_user: null,
      price_items: [],
      submitStatus: false,
    },
    offerLineSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getOfferLines(state) {
      return state.offerLines
    },
    getOfferLinesCount(state) {
      return state.offerLinesCount
    },
    getOfferLine(state) {
      return state.offerLine
    },
    getOfferLineSaveStatus(state) {
      return state.offerLineSave
    },
  },
  mutations: {
    OFFER_LINES_LIST(state, data) {
      state.offerLines = data
    },
    OFFER_LINE_VIEW(state, data) {
      state.offerLine = data
    },
    OFFER_LINES_COUNT(state, data) {
      state.offerLinesCount = data
    },
    OFFER_LINE_SAVE(state, data) {
      state.offerLineSave = data
    },
    RESET_OFFER_LINES_LIST(state) {
      state.offerLines = []
    },
    OFFER_LINE_RESET(state) {
      state.offerLine = {
        id: null,
        model: null,
        image: null,
        myear: null,
        car: null,
        priceCar: null,
        exterior_color: null,
        interior_color: null,
        listed_price: null,
        offer_price: null,
        price_text: null,
        id_com_offer: null,
        id_com_brand: null,
        id_com_model: null,
        id_com_user: null,
        price_items: [],
        submitStatus: false,
      }
    },
  },
  actions: {
    offerLinesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/offer_lines', data)
        .then(response => {
          commit('OFFER_LINES_LIST', response.data.data)
          commit('OFFER_LINES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    offerLineView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_offer_lines.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/offer_lines', data)
        .then(response => {
          commit('OFFER_LINE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    offerLineSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/offer_lines/saveData', data)
        .then(response => {
          commit('OFFER_LINE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    offerLineReset({ commit }) {
      commit('OFFER_LINE_RESET')
    },
  },
}
