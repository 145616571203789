export default [
  {
    path: '/rental/vehicle-park',
    name: 'RentalVehiclePark',
    component: () => import('@/views/Rental/VehiclePark/Index.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Müşteri Araç Parkı',
          active: true,
        },
      ],
      resource: 'RENTAL_VEHICLE_PARK',
      action: 'read',
    },
  },
  {
    path: '/rental/vehicle-park/search',
    name: 'RentalVehicleParkSearch',
    component: () => import('@/views/Rental/VehiclePark/Search.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Müşteri Araç Parkı',
          to: '/rental/vehicle-park',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          active: true,
        },
      ],
      resource: 'RENTAL_VEHICLE_PARK',
      action: 'read',
    },
  },
  {
    path: '/rental/vehicle-park/add/:id',
    name: 'RentalVehicleParkAdd',
    component: () => import('@/views/Rental/VehiclePark/Add.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Müşteri Araç Parkı',
          to: '/rental/vehicle-park',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          to: '/rental/vehicle-park/search',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'RENTAL_VEHICLE_PARK',
      action: 'read',
    },
  },
  {
    path: '/rental/vehicle-park/edit/:id',
    name: 'RentalVehicleParkEdit',
    component: () => import('@/views/Rental/VehiclePark/Edit.vue'),
    meta: {
      pageTitle: 'Filo Kiralama',
      breadcrumb: [
        {
          text: 'Müşteri Araç Parkı',
          to: '/rental/vehicle-park',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'RENTAL_VEHICLE_PARK',
      action: 'read',
    },
  },
]
