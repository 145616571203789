import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    hr_employees: [],
    hr_employeesCount: 0,
    hr_employee: {
      id: null,
      tckn: null,
      title: null,
      name: null,
      surname: null,
      image: null,
      summary_resume: null,
      resume: null,
      birthday: null,
      sdate: null,
      edate: null,
      management: null,
      upload_image: null,
      submitStatus: false,
    },
    hr_employeeSave: {
      message: null,
      status: null,
      id: null,
    },
    quickSearch: {
      keyword: '',
    },
  },
  getters: {
    getHr_employees(state) {
      return state.hr_employees
    },
    getHr_employeesCount(state) {
      return state.hr_employeesCount
    },
    getHr_employee(state) {
      return state.hr_employee
    },
    getHr_employeeSaveStatus(state) {
      return state.hr_employeeSave
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
  },
  mutations: {
    HR_EMPLOYEES_LIST(state, data) {
      state.hr_employees = data
    },
    HR_EMPLOYEE_VIEW(state, data) {
      state.hr_employee = data
    },
    HR_EMPLOYEES_COUNT(state, data) {
      state.hr_employeesCount = data
    },
    HR_EMPLOYEE_SAVE(state, data) {
      state.hr_employeeSave = data
    },
    HR_EMPLOYEE_RESET(state, data) {
      if (data.status) {
        state.hr_employee = {
          id: null,
          tckn: null,
          title: null,
          name: null,
          surname: null,
          image: null,
          summary_resume: null,
          resume: null,
          birthday: null,
          management: null,
          sdate: null,
          edate: null,
          upload_image: null,
          submitStatus: false,
        }
      }
    },
    SET_QUICK_SEARCH(state, data) {
      state.quickSearch = data
    },
  },
  actions: {
    hr_employeesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/hr/hr_employees', data)
        .then(response => {
          commit('HR_EMPLOYEES_LIST', response.data.data)
          commit('HR_EMPLOYEES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    hr_employeeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_hr_employees.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/hr/hr_employees', data)
        .then(response => {
          commit('HR_EMPLOYEE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    hr_employeeSave({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('tckn', postData.tckn)
      formData.append('title', postData.title)
      formData.append('name', postData.name)
      formData.append('surname', postData.surname)
      formData.append('old_image', postData.image)
      formData.append('image', postData.upload_image)
      formData.append('summary_resume', postData.summary_resume)
      formData.append('resume', postData.resume)
      formData.append('birthday', postData.birthday)
      formData.append('sdate', postData.sdate)
      formData.append('edate', postData.edate)
      formData.append('management', postData.management)
      axiosIns
        .post('/hr/hr_employees/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('HR_EMPLOYEE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    hr_employeeReset({ commit }) {
      commit('HR_EMPLOYEE_RESET', { status: true })
    },
  },
}
