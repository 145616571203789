import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    g2Sales: [],
    g2SalesCount: 0,
    g2Sale: {},
    g2SaleSave: {
      message: null,
      status: null,
      id: null,
    },
    quickSearch: {
      keyword: '',
    },
  },
  getters: {
    getG2Sales(state) {
      return state.g2Sales
    },
    getG2SalesCount(state) {
      return state.g2SalesCount
    },
    getG2Sale(state) {
      return state.g2Sale
    },
    getG2SaleSaveStatus(state) {
      return state.g2SaleSave
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
  },
  mutations: {
    SET_G2SALES(state, data) {
      state.g2Sales = data
    },
    SET_G2SALE(state, data) {
      state.g2Sale = data
    },
    SET_G2SALES_COUNT(state, data) {
      state.g2SalesCount = data
    },
    SET_G2SALE_SAVE(state, data) {
      state.g2SaleSave = data
    },
  },
  actions: {
    g2Sales({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/g2sales', data)
        .then(response => {
          commit('SET_G2SALES', response.data.data)
          commit('SET_G2SALES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    g2Sale({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_g2sale.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/g2sales', data)
        .then(response => {
          commit('SET_G2SALE', response.data.data)
        })
        .catch(error => console.error(error))
    },
    g2SaleSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/g2sales/saveData', data)
        .then(response => {
          commit('SET_G2SALE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
