export default [
  {
    path: '/districts',
    name: 'Districts',
    component: () => import('@/views/Defines/Districts/Index.vue'),
    meta: {
      pageTitle: 'İlçe',
      breadcrumb: [
        {
          text: 'İlçe',
          active: true,
        },
      ],
      resource: 'DEFINES_DISTRICTS',
      action: 'read',
    },
  },
  {
    path: '/districts/add',
    name: 'DistrictsAdd',
    component: () => import('@/views/Defines/Districts/Add.vue'),
    meta: {
      pageTitle: 'İlçe Ekle',
      breadcrumb: [
        {
          text: 'İlçe',
          to: '/districts',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_DISTRICTS',
      action: 'read',
    },
  },
  {
    path: '/districts/edit/:id',
    name: 'DistrictsEdit',
    component: () => import('@/views/Defines/Districts/Edit.vue'),
    meta: {
      pageTitle: 'İlçe Güncelle',
      breadcrumb: [
        {
          text: 'İlçe',
          to: '/districts',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_DISTRICTS',
      action: 'read',
    },
  },
]
