import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    purchasing_statuses: [],
    purchasing_status: {},
    purchasing_statuses_count: 0,
    purchasing_status_save: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getPurchasingStatuses(state) {
      return state.purchasing_statuses
    },
    getPurchasingStatus(state) {
      return state.purchasing_status
    },
    getPurchasingStatusesCount(state) {
      return state.purchasing_statuses_count
    },
    getPurchasingStatusSave(state) {
      return state.purchasing_status_save
    },
  },
  mutations: {
    PURCHASING_STATUSES_LIST(state, data) {
      state.purchasing_statuses = data
    },
    PURCHASING_STATUSES_COUNT(state, data) {
      state.purchasing_statuses_count = data
    },
    PURCHASING_STATUS_VIEW(state, data) {
      state.purchasing_status = data
    },
    PURCHASING_STATUS_SAVE(state, data) {
      state.purchasing_status_save = data
    },
  },
  actions: {
    purchasingStatuses({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/purchasing_statuses', data)
        .then(response => {
          commit('PURCHASING_STATUSES_LIST', response.data.data)
          commit('PURCHASING_STATUSES_COUNT', response.data.count)
        })
    },
    purchasingStatusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_purchasing_statuses.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/purchasing_statuses', data)
        .then(response => {
          commit('PURCHASING_STATUS_VIEW', response.data.data)
        })
    },
    purchasingStatusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/purchasing_statuses/saveData', data)
        .then(response => {
          commit('PURCHASING_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
