export default [
  {
    path: '/portal/quote_of_days',
    name: 'PortalQuoteOfDays',
    component: () => import('@/views/Portal/QuoteOfDays/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Günün Sözleri',
          active: true,
        },
      ],
      resource: 'PORTAL_QUOTE_OF_DAYS_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/quote_of_days/add',
    name: 'PortalNoticeAdd',
    component: () => import('@/views/Portal/QuoteOfDays/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Günün Sözleri',
          to: '/portal/quote_of_days',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_QUOTE_OF_DAYS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/quote_of_days/edit/:id',
    name: 'PortalNoticeEdit',
    component: () => import('@/views/Portal/QuoteOfDays/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Günün Sözleri',
          to: '/portal/quote_of_days',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_QUOTE_OF_DAYS_EDIT',
      action: 'read',
    },
  },
]
