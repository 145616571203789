export default [
  {
    path: '/meetgroups',
    name: 'MeetGroups',
    component: () => import('@/views/Defines/Meet_groups/Index.vue'),
    meta: {
      pageTitle: 'Temas Grupları',
      breadcrumb: [
        {
          text: 'Temas Grupları',
          active: true,
        },
      ],
      resource: 'DEFINES_MEET_GROUPS',
      action: 'read',
    },
  },
  {
    path: '/meetgroups/add',
    name: 'MeetGroupsAdd',
    component: () => import('@/views/Defines/Meet_groups/Add.vue'),
    meta: {
      pageTitle: 'Temas Grubu Ekle',
      breadcrumb: [
        {
          text: 'Temas Grubu',
          to: '/meetgroups',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_MEET_GROUPS',
      action: 'read',
    },
  },
  {
    path: '/meetgroups/edit/:id',
    name: 'MeetGroupsEdit',
    component: () => import('@/views/Defines/Meet_groups/Edit.vue'),
    meta: {
      pageTitle: 'Temas Grubu Güncelle',
      breadcrumb: [
        {
          text: 'Temas',
          to: '/meetgroups',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_MEET_GROUPS',
      action: 'read',
    },
  },
]
