import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: [],
    reportUsers: [],
    filterData: {
      sdate: null,
      edate: null,
      username: null,
    },
    loadingReport: null,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getUsers(state) {
      return state.reportUsers
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_USERS_DATA(state, data) {
      state.reportUsers = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
  },
  actions: {
    users({ commit }) {
      axiosIns
        .post('/reports/insurance/production/getUsers')
        .then(response => {
          commit('SET_USERS_DATA', response.data.data)
        })
        .catch(error => console.log(error))
    },
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/insurance/production', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
