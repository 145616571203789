export default [
  {
    path: '/offers',
    name: 'Offers',
    component: () => import('@/views/Offers/Index.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          active: true,
        },
      ],
      resource: 'OFFER_LIST',
      action: 'read',
    },
  },
  {
    path: '/offers/customer-search',
    name: 'OfferCustomerSearch',
    component: () => import('@/views/Offers/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/offers',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/offers/add/:id_customer',
    name: 'offerAdd',
    component: () => import('@/views/Offers/Add.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/offers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/offers/edit/:id',
    name: 'offerEdit',
    component: () => import('@/views/Offers/Edit.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/offers',
          active: false,
        },
        {
          text: 'Düzenle',
          active: true,
        },
      ],
      resource: 'OFFER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/offers/view/:id',
    name: 'offerEdit',
    component: () => import('@/views/Offers/View.vue'),
    meta: {
      pageTitle: 'Teklifler',
      breadcrumb: [
        {
          text: 'Tüm Teklifler',
          to: '/offers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'OFFER_VIEW',
      action: 'read',
    },
  },
]
