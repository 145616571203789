import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    serviceStatuses: [],
    serviceStatusesCount: 0,
    serviceStatus: {},
    serviceStatusSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getServiceStatuses(state) {
      return state.serviceStatuses
    },
    getServiceStatusesCount(state) {
      return state.serviceStatusesCount
    },
    getServiceStatus(state) {
      return state.serviceStatus
    },
    getServiceStatusSaveStatus(state) {
      return state.serviceStatusSave
    },
  },
  mutations: {
    SERVICE_STATUSES_LIST(state, data) {
      state.serviceStatuses = data
    },
    SERVICE_STATUS_VIEW(state, data) {
      state.serviceStatus = data
    },
    SERVICE_STATUSES_COUNT(state, data) {
      state.serviceStatusesCount = data
    },
    SERVICE_STATUS_SAVE(state, data) {
      state.serviceStatusSave = data
    },
  },
  actions: {
    serviceStatusesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/service_statuses', data)
        .then(response => {
          commit('SERVICE_STATUSES_LIST', response.data.data)
          commit('SERVICE_STATUSES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    serviceStatusView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_service_status.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/service_statuses', data)
        .then(response => {
          commit('SERVICE_STATUS_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    serviceStatusSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/service_statuses/saveData', data)
        .then(response => {
          commit('SERVICE_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    serviceStatusDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/service_statuses/deleteData', data)
        .then(response => {
          commit('SERVICE_STATUS_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
