import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: [],
    filterItems: {
      brands: [],
      locations: [],
      departments: [],
      years: [],
    },
    filterData: {
      id_com_brand: null,
      id_com_location: null,
      id_com_department: null,
      year: null,
    },
    loadingReport: null,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getFilterItems(state) {
      return state.filterItems
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_FILTER_DATA(state, data) {
      state.filterItems = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
  },
  actions: {
    filterItems({ commit }) {
      axiosIns
        .post('/reports/csi/csi_users/getFilterItems')
        .then(response => {
          commit('SET_FILTER_DATA', response.data)
        })
        .catch(error => console.log(error))
    },
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/csi/csi_users', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
