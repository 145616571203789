export default [
  {
    path: '/webforms/G2buyform',
    name: 'G2buyform',
    component: () => import('@/views/Webforms/G2buyform/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Almak İstiyorum',
          active: true,
        },
      ],
      resource: 'WEBFORM_G2BUYFORM',
      action: 'read',
    },
  },
  {
    path: '/webforms/G2buyform/view/:id',
    name: 'G2buyformView',
    component: () => import('@/views/Webforms/G2buyform/View.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Almak İstiyorum',
          to: '/webforms/G2buyform',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'WEBFORM_G2BUYFORM',
      action: 'read',
    },
  },
]
