import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tireSizes: [],
    tireSizesCount: 0,
    tireSize: {},
    tireSizeSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getTireSizes(state) {
      return state.tireSizes
    },
    getTireSizesCount(state) {
      return state.tireSizesCount
    },
    getTireSize(state) {
      return state.tireSize
    },
    getTireSizeSaveStatus(state) {
      return state.tireSizeSave
    },
  },
  mutations: {
    TIRE_SIZES_LIST(state, data) {
      state.tireSizes = data
    },
    TIRE_SIZE_VIEW(state, data) {
      state.tireSize = data
    },
    TIRE_SIZES_COUNT(state, data) {
      state.tireSizesCount = data
    },
    TIRE_SIZE_SAVE(state, data) {
      state.tireSizeSave = data
    },
  },
  actions: {
    tireSizesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/tire_sizes', data)
        .then(response => {
          commit('TIRE_SIZES_LIST', response.data.data)
          commit('TIRE_SIZES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    tireSizeView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_tire_size.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/tire_sizes', data)
        .then(response => {
          commit('TIRE_SIZE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    tireSizeSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/tire_sizes/saveData', data)
        .then(response => {
          commit('TIRE_SIZE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    tireSizeDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/tire_sizes/deleteData', data)
        .then(response => {
          commit('TIRE_SIZE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
