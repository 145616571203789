import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    polls: [],
    pollsCount: 0,
    poll: {
      id: null,
      question: null,
      status: null,
      options: [
        {
          id: null,
          title: null,
          id_com_portal_polls: null,
        },
        {
          id: null,
          title: null,
          id_com_portal_polls: null,
        },
      ],
      submitStatus: false,
    },
    pollSave: {
      message: null,
      status: null,
      id: null,
    },
    pollResult: [],
  },
  getters: {
    getPolls(state) {
      return state.polls
    },
    getPollsCount(state) {
      return state.pollsCount
    },
    getPoll(state) {
      return state.poll
    },
    getPollResult(state) {
      return state.pollResult
    },
    getPollSaveStatus(state) {
      return state.pollSave
    },
  },
  mutations: {
    POLLS_LIST(state, data) {
      state.polls = data
    },
    POLL_VIEW(state, data) {
      state.poll = data
    },
    POLL_RESULT(state, data) {
      state.pollResult = data
    },
    POLLS_COUNT(state, data) {
      state.pollsCount = data
    },
    POLL_SAVE(state, data) {
      state.pollSave = data
    },
    POLL_RESET(state, data) {
      if (data.status) {
        state.poll = {
          id: null,
          question: null,
          status: null,
          options: [
            {
              id: null,
              title: null,
              id_com_portal_polls: null,
            },
            {
              id: null,
              title: null,
              id_com_portal_polls: null,
            },
          ],
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    pollsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/polls', data)
        .then(response => {
          commit('POLLS_LIST', response.data.data)
          commit('POLLS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    pollView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_polls.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/polls', data)
        .then(response => {
          commit('POLL_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pollResult({ commit }, id) {
      axiosIns
        .post('/portal/polls/getPollResultDetail', { id_com_portal_polls: id })
        .then(response => {
          commit('POLL_RESULT', response.data.data)
        })
        .catch(error => console.error(error))
    },
    pollSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/portal/polls/saveData', data)
        .then(response => {
          commit('POLL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    pollReset({ commit }) {
      commit('POLL_RESET', { status: true })
    },
    pollDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/polls/deleteData', data)
        .then(response => {
          commit('POLL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
