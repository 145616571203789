export default [
  {
    path: '/marketing/ad_platforms',
    name: 'MarketingAdPlatforms',
    component: () => import('@/views/Marketing/Ad_platforms/Index.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Platformları',
          active: true,
        },
      ],
      resource: 'MARKETING_ADPLATFORMS_LIST',
      action: 'read',
    },
  },
  {
    path: '/marketing/ad_platforms/add',
    name: 'MarketingAdPlatformAdd',
    component: () => import('@/views/Marketing/Ad_platforms/Add.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Platformları',
          to: '/marketing/ad_platforms',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'MARKETING_ADPLATFORMS_ADD',
      action: 'read',
    },
  },
  {
    path: '/marketing/ad_platforms/edit/:id',
    name: 'MarketingAdPlatformEdit',
    component: () => import('@/views/Marketing/Ad_platforms/Edit.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Platformları',
          to: '/marketing/ad_platforms',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MARKETING_ADPLATFORMS_EDIT',
      action: 'read',
    },
  },
]
