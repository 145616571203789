import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    alerts: [],
    alertsCount: 0,
    alert: {
      id: null,
      alert_date: null,
      alert_notes: null,
      alert_status: 0,
      interview_number: null,
      order_number: null,
      id_com_customer: null,
      submitStatus: false,
    },
    alertSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getAlerts(state) {
      return state.alerts
    },
    getAlertsCount(state) {
      return state.alertsCount
    },
    getAlert(state) {
      return state.alert
    },
    getAlertSaveStatus(state) {
      return state.alertSave
    },
  },
  mutations: {
    ALERTS_LIST(state, data) {
      state.alerts = data
    },
    ALERT_VIEW(state, data) {
      state.alert = data
    },
    ALERTS_COUNT(state, data) {
      state.alertsCount = data
    },
    ALERT_SAVE(state, data) {
      state.alertSave = data
    },
    ALERT_RESET(state, data) {
      if (data.status) {
        state.alert = {
          id: null,
          alert_date: null,
          alert_notes: null,
          alert_status: 0,
          interview_number: null,
          order_number: null,
          id_com_customer: null,
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    alertsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/alerts', data)
        .then(response => {
          commit('ALERTS_LIST', response.data.data)
          commit('ALERTS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    alertView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_alert.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/alerts', data)
        .then(response => {
          commit('ALERT_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    alertSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/alerts/saveData', data)
        .then(response => {
          commit('ALERT_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    alertReset({ commit }) {
      commit('ALERT_RESET', { status: true })
    },
  },
}
