export default [
  {
    path: '/marketing/ad_forms',
    name: 'MarketingAdForms',
    component: () => import('@/views/Marketing/Ad_forms/Index.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Formları',
          active: true,
        },
      ],
      resource: 'MARKETING_ADFORMS_LIST',
      action: 'read',
    },
  },
  {
    path: '/marketing/ad_forms/add',
    name: 'MarketingAdFormAdd',
    component: () => import('@/views/Marketing/Ad_forms/Add.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Formları',
          to: '/marketing/ad_forms',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'MARKETING_ADFORMS_ADD',
      action: 'read',
    },
  },
  {
    path: '/marketing/ad_forms/edit/:id',
    name: 'MarketingAdFormEdit',
    component: () => import('@/views/Marketing/Ad_forms/Edit.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Reklam Formları',
          to: '/marketing/ad_forms',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'MARKETING_ADFORMS_EDIT',
      action: 'read',
    },
  },
]
