import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    brands: [],
    brandsCount: 0,
    brand: {},
    brandSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getBrands(state) {
      return state.brands
    },
    getBrandsCount(state) {
      return state.brandsCount
    },
    getBrand(state) {
      return state.brand
    },
    getBrandSaveStatus(state) {
      return state.brandSave
    },
  },
  mutations: {
    BRANDS_LIST(state, data) {
      state.brands = data
    },
    BRAND_VIEW(state, data) {
      state.brand = data
    },
    BRANDS_COUNT(state, data) {
      state.brandsCount = data
    },
    BRAND_SAVE(state, data) {
      state.brandSave = data
    },
  },
  actions: {
    brandsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/brands', data)
        .then(response => {
          commit('BRANDS_LIST', response.data.data)
          commit('BRANDS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    brandView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_brand.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/brands', data)
        .then(response => {
          commit('BRAND_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    brandSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/brands/saveData', data)
        .then(response => {
          commit('BRAND_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    brandDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/brands/deleteData', data)
        .then(response => {
          commit('BRAND_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
