export default [
  {
    path: '/servicesmstypes',
    name: 'ServiceSmsTypes',
    component: () => import('@/views/Defines/Service_sms_types/Index.vue'),
    meta: {
      pageTitle: 'Servis SMS Tipleri',
      breadcrumb: [
        {
          text: 'Servis SMS Tipleri',
          active: true,
        },
      ],
      resource: 'DEFINES_SERVICE_SMS_TYPES',
      action: 'read',
    },
  },
  {
    path: '/servicesmstypes/add',
    name: 'ServiceSmsTypesAdd',
    component: () => import('@/views/Defines/Service_sms_types/Add.vue'),
    meta: {
      pageTitle: 'Servis SMS Tipi Ekle',
      breadcrumb: [
        {
          text: 'Servis SMS Tipleri',
          to: '/servicesmstypes',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_SERVICE_SMS_TYPES',
      action: 'read',
    },
  },
  {
    path: '/servicesmstypes/edit/:id',
    name: 'ServiceSmsTypesEdit',
    component: () => import('@/views/Defines/Service_sms_types/Edit.vue'),
    meta: {
      pageTitle: 'Servis SMS Tipi Güncelle',
      breadcrumb: [
        {
          text: 'Servis SMS Tipleri',
          to: '/servicesmstypes',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_SERVICE_SMS_TYPES',
      action: 'read',
    },
  },
]
