export default [
  {
    path: '/purchasing_requests',
    name: 'purchasing_requests',
    component: () => import('@/views/Purchasing/Requests/Index.vue'),
    meta: {
      pageTitle: 'Satın Alma Talepleri',
      breadcrumb: [
        {
          text: 'Satın Alma Talepleri',
          active: true,
        },
      ],
      resource: 'PURCHASING_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_requests/add',
    name: 'PurchasingRequestsAdd',
    component: () => import('@/views/Purchasing/Requests/Add.vue'),
    meta: {
      pageTitle: 'Satın Alma Talepleri',
      breadcrumb: [
        {
          text: 'Satın Alma Talepleri',
          to: '/purchasing_requests',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PURCHASING_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_requests/edit/:id',
    name: 'PurchasingRequestsEdit',
    component: () => import('@/views/Purchasing/Requests/Edit.vue'),
    meta: {
      pageTitle: 'Satın Alma Talepleri',
      breadcrumb: [
        {
          text: 'Satın Alma Talepleri',
          to: '/purchasing_requests',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PURCHASING_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_requests/view/:id',
    name: 'PurchasingRequestsView',
    component: () => import('@/views/Purchasing/Requests/View.vue'),
    meta: {
      pageTitle: 'Satın Alma Talepleri',
      breadcrumb: [
        {
          text: 'Satın Alma Talepleri',
          to: '/purchasing_requests',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'PURCHASING_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_requests/waiting',
    name: 'WaitingPurchasingRequests',
    component: () => import('@/views/Purchasing/Requests/WaitingRequests.vue'),
    meta: {
      pageTitle: 'Satın Alma Talepleri',
      breadcrumb: [
        {
          text: 'Satın Alma Talepleri',
          to: '/purchasing_requests',
          active: false,
        },
        {
          text: 'Bekleyen',
          active: true,
        },
      ],
      resource: 'PURCHASING_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_requests/canceled',
    name: 'CanceledPurchasingRequests',
    component: () => import('@/views/Purchasing/Requests/CanceledRequests.vue'),
    meta: {
      pageTitle: 'Satın Alma Talepleri',
      breadcrumb: [
        {
          text: 'Satın Alma Talepleri',
          to: '/purchasing_requests',
          active: false,
        },
        {
          text: 'İptal Edilen',
          active: true,
        },
      ],
      resource: 'PURCHASING_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/purchasing_requests/completed',
    name: 'CompletedPurchasingRequests',
    component: () => import('@/views/Purchasing/Requests/CompletedRequests.vue'),
    meta: {
      pageTitle: 'Satın Alma Talepleri',
      breadcrumb: [
        {
          text: 'Satın Alma Talepleri',
          to: '/purchasing_requests',
          active: false,
        },
        {
          text: 'Tamamlanan',
          active: true,
        },
      ],
      resource: 'PURCHASING_REQUESTS_LIST',
      action: 'read',
    },
  },
]
