import axiosIns from '@/libs/axios'

const ROOT_URL = '/reports/rental/document_report'
export default {
  namespaced: true,
  state: {
    dataList: [],
    fields: [],
    dataCounts: 0,
    dataLoading: true,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataFields(state) {
      return state.fields
    },
    dataCounts(state) {
      return state.dataCounts
    },
    getReportLoading(state) {
      return state.dataLoading
    },
    dataItem(state) {
      return state.dataItem
    },
    uploading(state) {
      return state.uploading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_FIELDS(state, data) {
      state.fields = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        sdate: null,
        edate: null,
        upload_file: null,
        id_com_rental_stocks: null,
        id_com_rental_document_types: null,
      }
    },
    SET_UPLOADING(state, data) {
      state.uploading = data
    },
  },
  actions: {
    getReportData({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      axiosIns
        .post(ROOT_URL, params)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_FIELDS', response.data.fields)
          commit('SET_DATA_COUNTS', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
