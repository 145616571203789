export default [
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('@/views/Defines/Jobs/Index.vue'),
    meta: {
      pageTitle: 'Meslekler',
      breadcrumb: [
        {
          text: 'Meslekler',
          active: true,
        },
      ],
      resource: 'DEFINES_JOBS',
      action: 'read',
    },
  },
  {
    path: '/jobs/add',
    name: 'JobsAdd',
    component: () => import('@/views/Defines/Jobs/Add.vue'),
    meta: {
      pageTitle: 'Meslek Ekle',
      breadcrumb: [
        {
          text: 'Meslekler',
          to: '/jobs',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_JOBS',
      action: 'read',
    },
  },
  {
    path: '/jobs/edit/:id',
    name: 'JobsEdit',
    component: () => import('@/views/Defines/Jobs/Edit.vue'),
    meta: {
      pageTitle: 'Meslek Güncelle',
      breadcrumb: [
        {
          text: 'Meslekler',
          to: '/jobs',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_JOBS',
      action: 'read',
    },
  },
]
