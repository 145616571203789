export default [
  {
    path: '/models',
    name: 'Models',
    component: () => import('@/views/Defines/Models/Index.vue'),
    meta: {
      pageTitle: 'İnallar Modelleri',
      breadcrumb: [
        {
          text: 'İnallar Modelleri',
          active: true,
        },
      ],
      resource: 'DEFINES_MODELS',
      action: 'read',
    },
  },
  {
    path: '/models/add',
    name: 'ModelsAdd',
    component: () => import('@/views/Defines/Models/Add.vue'),
    meta: {
      pageTitle: 'İnallar Modeli Ekle',
      breadcrumb: [
        {
          text: 'İnallar Modelleri',
          to: '/models',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_MODELS',
      action: 'read',
    },
  },
  {
    path: '/models/edit/:id',
    name: 'ModelsEdit',
    component: () => import('@/views/Defines/Models/Edit.vue'),
    meta: {
      pageTitle: 'İnallar Modeli Güncelle',
      breadcrumb: [
        {
          text: 'İnallar Modelleri',
          to: '/models',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_MODELS',
      action: 'read',
    },
  },
]
