import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    crm_applysources: [],
    crm_applysourcesCount: 0,
    crm_applysource: {},
    crm_applysourceSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCrm_applysources(state) {
      return state.crm_applysources
    },
    getCrm_applysourcesCount(state) {
      return state.crm_applysourcesCount
    },
    getCrm_applysource(state) {
      return state.crm_applysource
    },
    getCrm_applysourceSaveStatus(state) {
      return state.crm_applysourceSave
    },
  },
  mutations: {
    CRM_APPLYSOURCES_LIST(state, data) {
      state.crm_applysources = data
    },
    CRM_APPLYSOURCE_VIEW(state, data) {
      state.crm_applysource = data
    },
    CRM_APPLYSOURCES_COUNT(state, data) {
      state.crm_applysourcesCount = data
    },
    CRM_APPLYSOURCE_SAVE(state, data) {
      state.crm_applysourceSave = data
    },
  },
  actions: {
    crm_applysourcesList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/crm_applysources', data)
        .then(response => {
          commit('CRM_APPLYSOURCES_LIST', response.data.data)
          commit('CRM_APPLYSOURCES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    crm_applysourceView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_crm_applysource.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/crm_applysources', data)
        .then(response => {
          commit('CRM_APPLYSOURCE_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    crm_applysourceSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/crm_applysources/saveData', data)
        .then(response => {
          commit('CRM_APPLYSOURCE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    crm_applysourceDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/crm_applysources/deleteData', data)
        .then(response => {
          commit('CRM_APPLYSOURCE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
