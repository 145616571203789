import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    smsText: {},
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    sending: false,
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    getSmsText(state) {
      return state.smsText
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    sending(state) {
      return state.sending
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_SMS_TEXT(state, data) {
      state.smsText = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_SENDING(state, data) {
      state.sending = data
    },
  },
  actions: {
    dataList({ commit }) {
      return axiosIns
        .post('/data/birthday_sms_list')
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          return response.data.data
        })
        .catch(error => console.log(error))
    },
    smsText({ commit }) {
      axiosIns
        .post('/data/birthday_sms_list/smstext')
        .then(response => {
          commit('SET_SMS_TEXT', response.data.data)
        })
        .catch(error => console.log(error))
    },
    saveData({ commit }, postData) {
      return axiosIns
        .post('/data/birthday_sms_list/saveData', postData)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    batchSendSms({ commit }, postData) {
      commit('SET_SENDING', true)
      return axiosIns
        .post('/data/birthday_sms_list/batchSendSms', postData)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          commit('SET_SENDING', false)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
  },
}
